import { ApiService } from '../../lib/http.service';
import { env } from "../../definitions/global.vars";
export var Api = new ApiService();
export var ContactUsApi = (function () {
    return {
        createContactUs: function (request) {
            return Api.start('post', env.misc_api + '/support/api/create-support-ticket/', request, true);
        },
        createContactUsUser: function (request) {
            return Api.start('post', env.misc_api + '/support/api/create-contact-us-ticket/', request, true);
        }
    };
})();
