import { ApiService } from '../../../../lib/http.service';
import { env } from "../../../../definitions/global.vars";
export var Api = new ApiService();
export var AccountTransactionApi = (function () {
    return {
        changePassword: function (request) {
            return Api.start('post', env.auth_api + '/member/api/update-security-info/', request, true);
        },
        accountTransactions: function (request) {
            return Api.start('post', env.list_api + '/accounting/api/transactions/', request, true);
        },
        checkAouth: function (request) {
            return Api.start('post', env.auth_api + '/member/api/check-auth/', request, true);
        },
        updateMember: function (request) {
            return Api.start('post', env.auth_api + '/member/api/update-info/', request, true);
        },
        updateEmailMember: function (request) {
            return Api.start('post', env.misc_api + '/member/api/send_validation_mail/', request, true);
        },
        updateMobileMember: function (request) {
            return Api.start('post', env.misc_api + '/member/api/validation_sms/', request, true);
        },
        codeMobileMember: function (request) {
            return Api.start('post', env.misc_api + '/member/api/validation_sms/', request, true);
        },
        uptadeSosyoleyMember: function (request) {
            return Api.start('post', env.misc_api + '/member/api/update-sosyoley-info/', request, true);
        },
        teamList: function (request) {
            return Api.start('post', env.misc_api + '/misc/api/team/list/', request, true);
        },
        updateAvatar: function (request) {
            return Api.start('post', env.misc_api + '/member/api/update-avatar/', request, true);
        },
    };
})();
