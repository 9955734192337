var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function Licence() {
    return (_jsxs("div", __assign({ className: "signature" }, { children: [_jsx("span", __assign({ className: "plus18" }, { children: "+18" }), void 0), " Spor Toto'nun yasal bayisi olan Oley.com' a  18 ya\u015F\u0131ndan b\u00FCy\u00FCkler \u00FCye olabilir.", _jsx("br", {}, void 0), "T\u00FCm haklar\u0131 sakl\u0131d\u0131r.", _jsx("p", { children: _jsx("a", __assign({ href: "https://eski.oley.com/" }, { children: _jsx("b", { children: "Eski Site" }, void 0) }), void 0) }, void 0), _jsx("p", { children: _jsx("br", {}, void 0) }, void 0), _jsx("p", { children: _jsx("br", {}, void 0) }, void 0), _jsx("p", { children: _jsx("br", {}, void 0) }, void 0)] }), void 0));
}
export default Licence;
