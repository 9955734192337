var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { getCurrentScores, getSportotoDraws, getSportotoLatestResult } from "../../../services/sportoto.services";
import { dateFormat, dateTimeFormat } from "../../../lib/misc.functions";
var latestDraw;
var latestStatistic;
// let latestStatistics:any;
export default function SportotoResult() {
    var _a = useState(null), draws = _a[0], setDraws = _a[1];
    var _b = useState(null), latestStatistics = _b[0], setLatestStatistics = _b[1];
    var _c = useState(null), result = _c[0], setResult = _c[1];
    useEffect(function () {
        getSportotoLatestResult().then(function (response) {
            latestStatistic = response;
            if (response.hasOwnProperty('length') && response.length > 0) {
                var draw_1 = response[0];
                setLatestStatistics(response[0]);
                var eventIdList = draw_1.events.map(function (el) {
                    return el.event_id;
                });
                getCurrentScores(eventIdList).then(function (res) {
                    var _loop_1 = function (eventIndex) {
                        var eventResult = 0;
                        var spEvent = res.find(function (scoreEvent) { return Number(scoreEvent.eventId) === draw_1.events[eventIndex].event_id; });
                        if (spEvent && spEvent.currentScore) {
                            draw_1.events[eventIndex]['result'] = spEvent.currentScore;
                            if (spEvent.currentScore.split(':')[0] > spEvent.currentScore.split(':')[1]) {
                                eventResult = 1;
                            }
                            else if (spEvent.currentScore.split(':')[0] < spEvent.currentScore.split(':')[1]) {
                                eventResult = 2;
                            }
                            draw_1.events[eventIndex]['winner'] = eventResult;
                        }
                    };
                    for (var eventIndex in draw_1.events) {
                        _loop_1(eventIndex);
                    }
                    setResult(draw_1);
                });
            }
        });
        getSportotoDraws().then(function (response) {
            setDraws(response);
        });
    }, []);
    if (!result || !result.events) {
        return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "lds-roller" }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }, void 0);
    }
    else {
        if (!latestDraw) {
            latestDraw = result;
        }
    }
    var chengeResult = function (e) {
        setLatestStatistics(latestStatistic.find(function (x) { return x.draw_id === Number(e); }));
        if (latestDraw && latestDraw.draw_id === Number(e)) {
            setResult(latestDraw);
        }
        else {
            setResult(draws.find(function (el) { return Number(el.drawId) === Number(e); }));
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-lg-8 col-sm-12" }, { children: _jsxs("table", __assign({ className: "table" }, { children: [_jsxs("thead", { children: [_jsx("tr", { children: _jsx("th", __assign({ colSpan: 5, className: "text-left" }, { children: draws ? _jsx("select", __assign({ style: { border: "1px solid var(--color-primary)", width: 'min-content', paddingRight: '2rem' }, onChange: function (e) { chengeResult(e.target.value); } }, { children: draws.map(function (el, key) {
                                                    return (_jsxs("option", __assign({ value: el.drawId }, { children: [dateFormat(el.date), " tarihli Sportoto Sonu\u00E7lar\u0131"] }), key));
                                                }) }), void 0) : _jsx(_Fragment, {}, void 0) }), void 0) }, void 0), _jsxs("tr", { children: [_jsx("th", { children: "#" }, void 0), _jsx("th", { children: "Tarih" }, void 0), _jsx("th", __assign({ className: "text-left" }, { children: "Ev Sahibi Tak\u0131m - Konuk Tak\u0131m" }), void 0), _jsx("th", { children: "Skor" }, void 0), _jsx("th", { children: "Sonu\u00E7" }, void 0)] }, void 0)] }, void 0), _jsx("tbody", { children: result.events.map(function (el, key) {
                                    return (_jsxs("tr", { children: [_jsx("td", { children: key + 1 }, void 0), _jsx("td", { children: dateTimeFormat(el.date || el.event_date) }, void 0), _jsx("td", __assign({ className: "text-left fw-bold" }, { children: el.name }), void 0), _jsx("td", { children: el.result }, void 0), _jsx("td", { children: el.winner }, void 0)] }, key));
                                }) }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "col-lg-4 col-sm-12" }, { children: _jsx("div", __assign({ style: { border: "1px solid #ccc", padding: 15 } }, { children: _jsx("div", __assign({ className: "payouts" }, { children: draws && latestStatistics && latestStatistics.statistics && draws.find(function (el) { return el.drawId === latestStatistics.draw_id; }) ? _jsxs(_Fragment, { children: [_jsxs("h3", __assign({ className: "mb-4" }, { children: [dateFormat(draws.find(function (el) { return el.drawId === latestStatistics.draw_id; }).date), " Sportoto \u0130kramiyeleri"] }), void 0), latestStatistics.statistics.length > 0 ? latestStatistics.statistics.map(function (stat, key) {
                                        return _jsxs("div", __assign({ className: "mb-3" }, { children: [stat.winners == 0 ? (_jsxs("div", __assign({ className: "fw-bold" }, { children: [stat.type, " ", _jsx("span", __assign({ className: "warning" }, { children: "DEVRETT\u0130" }), void 0)] }), void 0)) : (_jsxs("div", __assign({ className: "fw-bold" }, { children: [stat.type, " ", stat.winners, " Ki\u015Fi"] }), void 0)), _jsxs("div", __assign({ className: "st-payout" }, { children: [stat.payout_amount.replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(",", "!").replaceAll(".", ",").replaceAll("!", "."), "\u20BA"] }), void 0)] }), key);
                                    }) : (_jsx("div", { children: "Se\u00E7ilen haftan\u0131n ikramiyeleri hen\u00FCz a\u00E7\u0131klanmam\u0131\u015Ft\u0131r." }, void 0))] }, void 0) : _jsx(_Fragment, { children: "Se\u00E7ilen haftan\u0131n ikramiyeleri hen\u00FCz a\u00E7\u0131klanmam\u0131\u015Ft\u0131r." }, void 0) }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0));
}
