var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Subject } from "rxjs";
export var DailyRaces = [];
export var tjkRunActionSubject = new Subject();
export var tjkBetSlipActionSubject = new Subject();
export var tjkBetSlipVisibleSubject = new Subject();
export var availableBettingSubTypes = [];
export var availableHippodromes = [];
export var availableTjkBettingTypes = [];
export var availableRuns = [];
export var tjkBetSlipOptions = {
    betSlipMultiply: 1,
    bettingType: null,
    bettingSubType: null,
    hippodrome: null,
    selectedRun: null,
    usePointBalance: false,
    inProgress: false,
    errorMessage: null,
    complete: false,
    maxAmount: 1,
    system: false,
};
export var tjkComments = {};
export var courseColors = {
    'Çim': 'var(--color-success)',
    'Kum': '#996633',
    'Sentetik': '#d39b1e',
    'TAPETA': '#d39b1e',
};
export var jewelryMapping = {
    'KG': "Kapal\u0131 G\u00F6zl\u00FCk tak\u0131laca\u011F\u0131n\u0131 ifade eder.",
    'YP': "Yanak Pelu\u015Fu tak\u0131laca\u011F\u0131n\u0131 ifade eder.",
    'ÖG': "Start makinas\u0131na \u00F6nden giri\u015F yapaca\u011F\u0131n\u0131 ifade eder.",
    'KUL': "Kulakl\u0131k tak\u0131laca\u011F\u0131n\u0131 ifade eder.",
    'K': "Kulakl\u0131k tak\u0131laca\u011F\u0131n\u0131 ifade eder.",
    'DB': "Dilinin Ba\u011Flanaca\u011F\u0131n\u0131 ifade eder.",
    'SKG': "Ring mahalinden Start'a Kadar Kapal\u0131 G\u00F6zl\u00FCk ile gelece\u011Fini ifade eder.",
    'SK': "Ring mahalinden Start'a Kadar Kapal\u0131 G\u00F6zl\u00FCk ile gelece\u011Fini ifade eder.",
    'SKUL': "Ring mahalinden Start'a Kadar Kulakl\u0131k ile gelece\u011Fini ifade eder.",
    'SGK': "Ring mahalinden Start'a Kadar G\u00F6zleri Kapal\u0131 gelece\u011Fini ifade eder.",
    'MBH': "Bir at\u0131n ko\u015Fuya m\u00FC\u015Fterek bahis harici olarak kat\u0131ld\u0131\u011F\u0131n\u0131 ifade eder.",
    'DS': "Komiserler Karar\u0131 ile at\u0131n D\u0131\u015F Start kullan\u0131laca\u011F\u0131n\u0131 ifade eder.",
    'BB': "Burun Band\u0131 tak\u0131laca\u011F\u0131n\u0131 ifade eder.",
    'GKR': "G\u00F6z Koruyucu tak\u0131laca\u011F\u0131n\u0131 ifade eder.",
    'AP': "Ata aprantinin (jokey yama\u011F\u0131) binece\u011Fini ifade eder.",
    'EKÜRİ': "Ah\u0131rda\u015F oldu\u011Fu \u00F6nceden ilan edilen ve ayn\u0131 ko\u015Fuya kat\u0131lan atlar\u0131n jokeyleri ayn\u0131 tip forma giyerler numaras\u0131 b\u00FCy\u00FCk olan jokeyi bant takar.",
    'KGS': "Ko\u015Fmad\u0131\u011F\u0131 g\u00FCn say\u0131s\u0131n\u0131 ifade eder.",
    'G.ÇIK.': "Ge\u00E7 \u00E7\u0131k\u0131\u015F\u0131 ifade eder.",
    'EİD': "En iyi derece alan\u0131 o safkan\u0131n ayn\u0131 t\u00FCr pistte ve ayn\u0131 mesafede yapt\u0131\u011F\u0131 en iyi dereceyi g\u00F6sterir. Safkan daha \u00F6nce ayn\u0131 t\u00FCr pistte ve ayn\u0131 mesafede ko\u015Fmam\u0131\u015Fsa bu alanda veri olmaz. \u00D6rne\u011Fin; safkan\u0131n ko\u015Faca\u011F\u0131 programdaki ko\u015Fu 1.500 metre \u00E7im ko\u015Fusu ise, en iyi derece alan\u0131 o g\u00FCne kadar o at\u0131n 1.500 m. \u00E7im pist ko\u015Fusunda yapt\u0131\u011F\u0131 en iyi dereceyi g\u00F6sterir. Safkan o g\u00FCne kadar 1500 m \u00E7im pistte hi\u00E7 ko\u015Fmam\u0131\u015Fsa bu alan bo\u015F olur.",
    'S20': "Yar\u0131\u015F hayat\u0131 boyunca son ko\u015Ftu\u011Fu 20 ko\u015Fuda; En az bir birincilik, Veya en az iki ikincilik, Ya da d\u00F6rt adet tabela(2.lik, 3.l\u00FCk, 4.l\u00FCk ),Yapamam\u0131\u015F olan atlar hi\u00E7bir hipodromdaki ko\u015Fulara i\u015Ftirak edemezler (Mahalli ko\u015Fular hari\u00E7).Bu atlar\u0131n ah\u0131r tahsisi iptal edilir.(31.12.2009 tarihi itibar\u0131 ile 2009 Y\u0131l\u0131 At Yar\u0131\u015Flar\u0131 Genel H\u00FCk\u00FCmleri 13/b maddesine g\u00F6re ko\u015Fulara kat\u0131lma hakk\u0131n\u0131 kaybeden atlar bu h\u00FCk\u00FCmden yararlanamazlar.)Ancak yar\u0131\u015F hayat\u0131 boyunca; Bir G1,veya iki G2, veya d\u00F6rt G3, veya bir G2 ve iki G3, Kazanan atlar i\u00E7in bu kural uygulanmaz. (KV 10 veya A3 ko\u015Fusu kazanan atlar G3 ko\u015Fusu kazanm\u0131\u015F gibi, KV 11 veya A2 ko\u015Fusu kazanan atlar G2 ko\u015Fusu kazanm\u0131\u015F gibi i\u015Flem g\u00F6r\u00FCrler.)"
};
export var tjkOrderedBetTypes = [8, 9, 10, 47, 51, 52, 61, 62];
export var tjkSortedBetTypes = [50, 53, 56, 63];
export var tjkSingleLegBetTypes = [11, 48];
export var tjkComplateBettingTypes = [8, 50, 51, 53, 54, 56, 61, 63];
export var tjkBettingTypes = [
    { id: 1, available: true, column: 1, columnLabel: 'Koşu', name: 'Ganyan' },
    { id: 2, available: true, column: 1, columnLabel: 'Koşu', name: 'Plase' },
    { id: 4, available: true, column: 1, columnLabel: 'Koşu', name: 'Ganyan & Plase' },
    { id: 8, available: true, column: 2, columnLabel: 'At', name: 'Sıralı ikili Bahis' },
    // {id: 9, available: true, column:2, columnLabel:'At', name: 'Sıralı ikili Bahis (K)'},
    { id: 50, available: true, column: 0, columnLabel: 'At', horseCount: 2, name: 'Sıralı ikili (Virgüllü)' },
    { id: 10, available: true, column: 2, columnLabel: 'At', name: 'İkili Bahis' },
    { id: 11, available: true, column: 1, columnLabel: 'At', name: 'İkili Bahis (Tek Kolon)' },
    { id: 14, available: true, column: 2, columnLabel: 'Koşu', name: 'Çifte Bahis' },
    { id: 15, available: true, column: 3, columnLabel: 'Ayak', name: '3\'lü Ganyan' },
    { id: 16, available: true, column: 4, columnLabel: 'Ayak', name: '4\'lü Ganyan' },
    { id: 17, available: true, column: 5, columnLabel: 'Ayak', name: '5\'li Ganyan' },
    { id: 18, available: true, column: 6, columnLabel: 'Ayak', name: '6\'lı Ganyan' },
    { id: 19, available: true, column: 7, columnLabel: 'Ayak', name: '7\'li Plase' },
    { id: 47, available: true, column: 2, columnLabel: 'At', name: 'Plase İkili' },
    { id: 48, available: true, column: 1, columnLabel: 'At', name: 'Plase İkili (Tek Kolon)' },
    { id: 51, available: true, column: 3, columnLabel: 'At', name: 'Sıralı Üçlü Bahis' },
    // {id: 52, available: true, column:3, columnLabel:'At', name: 'Sıralı Üçlü Bahis (K)'},
    { id: 53, available: true, column: 0, columnLabel: 'At', horseCount: 3, name: 'Sıralı Üçlü (Virgüllü)' },
    { id: 54, available: true, column: 4, columnLabel: 'At', name: 'Tabela Bahis' },
    // {id: 55, available: true, column:4, columnLabel:'At', name: 'Tabela Bahis (K)'},
    { id: 56, available: true, column: 0, columnLabel: 'At', horseCount: 4, name: 'Tabela Bahis (Virgüllü)' },
    { id: 61, available: true, column: 5, columnLabel: 'At', name: 'Sıralı Beşli' },
    // {id: 62, available: true, column:5, columnLabel:'At', name: 'Sıralı Beşli (K)'},
    { id: 63, available: true, column: 0, columnLabel: 'At', horseCount: 5, name: 'Sıralı Beşli (Virgüllü)' },
];
export var tjkBettingTypesMap = Object.assign.apply(Object, __spreadArray([{}], tjkBettingTypes.map(function (x) {
    var _a;
    return (_a = {}, _a[x.id] = x, _a);
}), false));
export var agfCondBets = [14, 15, 16, 17, 18, 19];
var tjkBettingTypes2 = [
    { id: 1, available: true, column: 1, columnLabel: 'Koşu', name: 'Ganyan' },
    { id: 2, available: true, column: 1, columnLabel: 'Koşu', name: 'Plase' },
    { id: 4, available: true, column: 1, columnLabel: 'Koşu', name: 'Ganyan & Plase' },
    { id: 8, available: true, column: 2, columnLabel: 'At', name: 'Sıralı ikili Bahis' },
    { id: 9, available: true, column: 2, columnLabel: 'At', name: 'Sıralı ikili Bahis (Komple)' },
    { id: 50, available: true, column: 0, columnLabel: 'At', horseCount: 2, name: 'Sıralı ikili (Virgüllü)' },
    { id: 10, available: true, column: 2, columnLabel: 'At', name: 'İkili Bahis' },
    { id: 11, available: true, column: 1, columnLabel: 'At', name: 'İkili Bahis (Tek Kolon)' },
    { id: 14, available: true, column: 2, columnLabel: 'Koşu', name: 'Çifte Bahis' },
    { id: 15, available: true, column: 3, columnLabel: 'Ayak', name: '3\'lü Ganyan' },
    { id: 16, available: true, column: 4, columnLabel: 'Ayak', name: '4\'lü Ganyan' },
    { id: 17, available: true, column: 5, columnLabel: 'Ayak', name: '5\'li Ganyan' },
    { id: 18, available: true, column: 6, columnLabel: 'Ayak', name: '6\'lı Ganyan' },
    { id: 19, available: true, column: 7, columnLabel: 'Ayak', name: '7\'li Plase' },
    { id: 47, available: true, column: 2, columnLabel: 'At', name: 'Plase İkili' },
    { id: 48, available: true, column: 1, columnLabel: 'At', name: 'Plase İkili (Tek Kolon)' },
    { id: 51, available: true, column: 3, columnLabel: 'At', name: 'Sıralı Üçlü Bahis' },
    { id: 52, available: true, column: 3, columnLabel: 'At', name: 'Sıralı Üçlü Bahis (Komple)' },
    { id: 53, available: true, column: 0, columnLabel: 'At', horseCount: 3, name: 'Sıralı Üçlü (Virgüllü)' },
    { id: 54, available: true, column: 4, columnLabel: 'At', name: 'Tabela Bahis' },
    { id: 55, available: true, column: 4, columnLabel: 'At', name: 'Tabela Bahis (Komple)' },
    { id: 56, available: true, column: 0, columnLabel: 'At', horseCount: 4, name: 'Tabela Bahis (Virgüllü)' },
    { id: 61, available: true, column: 5, columnLabel: 'At', name: 'Sıralı Beşli' },
    { id: 62, available: true, column: 5, columnLabel: 'At', name: 'Sıralı Beşli (Komple)' },
    { id: 63, available: true, column: 0, columnLabel: 'At', horseCount: 5, name: 'Sıralı Beşli (Virgüllü)' },
];
export var tjkBettingTypesMap2 = Object.assign.apply(Object, __spreadArray([{}], tjkBettingTypes2.map(function (x) {
    var _a;
    return (_a = {}, _a[x.id] = x, _a);
}), false));
export var tjkHippodroms = [{
        "KEY": "ADANA",
        "HIPODROM": "Adana Ye\u015filoba Hipodromu",
        "YER": "Adana",
        "YABANCI": false
    }, {
        "KEY": "IZMIR",
        "HIPODROM": "\u0130zmir \u015eirinyer Hipodromu",
        "YER": "\u0130zmir",
        "YABANCI": false
    }, {
        "KEY": "ISTANBUL",
        "HIPODROM": "\u0130stanbul Veliefendi Hipodromu",
        "YER": "\u0130stanbul",
        "YABANCI": false
    }, { "KEY": "BURSA", "HIPODROM": "Bursa Osmangazi Hipodromu", "YER": "Bursa", "YABANCI": false }, {
        "KEY": "ANKARA",
        "HIPODROM": "Ankara 75. Y\u0131l Hipodromu",
        "YER": "Ankara",
        "YABANCI": false
    }, {
        "KEY": "SANLIURFA",
        "HIPODROM": "\u015eanl\u0131urfa Hipodromu",
        "YER": "\u015eanl\u0131urfa",
        "YABANCI": false
    }, {
        "KEY": "ELAZIG",
        "HIPODROM": "Elaz\u0131\u011f Hipodromu",
        "YER": "Elaz\u0131\u011f",
        "YABANCI": false
    }, {
        "KEY": "DIYARBAKIR",
        "HIPODROM": "Diyarbak\u0131r Hipodromu",
        "YER": "Diyarbak\u0131r",
        "YABANCI": false
    }, { "KEY": "KOCAELI", "HIPODROM": "Kocaeli Kartepe Hipodromu", "YER": "Kocaeli", "YABANCI": false }, {
        "KEY": "ANTALYA",
        "HIPODROM": "Antalya Hipodromu",
        "YER": "Antalya",
        "YABANCI": false
    }, { "KEY": "KARMA", "HIPODROM": "Karma", "YER": "Karma", "YABANCI": true }, {
        "KEY": "MAHMUDIYE",
        "HIPODROM": "Mahmudiye \u0130dman Pisti",
        "YER": "Mahmudiye",
        "YABANCI": false
    }, {
        "KEY": "IZMITIDMAN",
        "HIPODROM": "\u0130zmit \u0130dman Merkezi",
        "YER": "\u0130zmit",
        "YABANCI": false
    }, { "KEY": "DUBAI", "HIPODROM": "Meydan", "YER": "Meydan Dubai", "YABANCI": true }, {
        "KEY": "RUSYA",
        "HIPODROM": "Rusya",
        "YER": "Rusya",
        "YABANCI": true
    }, {
        "KEY": "NEWMARKET",
        "HIPODROM": "Newmarket",
        "YER": "Newmarket Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "EPSOM",
        "HIPODROM": "Epsom Downs",
        "YER": "Epsom Downs Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "CURRAGH", "HIPODROM": "Curragh", "YER": "Curragh \u0130rlanda", "YABANCI": true }, {
        "KEY": "GOODWOOD",
        "HIPODROM": "Goodwood",
        "YER": "Goodwood Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "DEAUVILLE", "HIPODROM": "Deauville", "YER": "Deauville Fransa", "YABANCI": true }, {
        "KEY": "ASCOT",
        "HIPODROM": "Ascot",
        "YER": "Ascot Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "LEOPARDS", "HIPODROM": "Leopardstown", "YER": "Leopardstown \u0130rlanda", "YABANCI": true }, {
        "KEY": "YORK",
        "HIPODROM": "York",
        "YER": "York Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "DONCASTER",
        "HIPODROM": "Doncaster",
        "YER": "Doncaster Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "KEMPTON",
        "HIPODROM": "Kempton Park",
        "YER": "Kempton Park Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "SANTAANITA",
        "HIPODROM": "Santa Anita Park",
        "YER": "Santa Anita Park ABD",
        "YABANCI": true
    }, {
        "KEY": "WHAMPTON",
        "HIPODROM": "Wolverhampton",
        "YER": "Wolverhampton Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "LEIGHS",
        "HIPODROM": "Great Leighs",
        "YER": "Great Leighs Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "LINGFIELD",
        "HIPODROM": "Lingfield Park",
        "YER": "Lingfield Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "SOUTHWELL",
        "HIPODROM": "Southwell",
        "YER": "Southwell Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "NEWBURY",
        "HIPODROM": "Newbury",
        "YER": "Newbury Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "SANDOWN",
        "HIPODROM": "Sandown Park",
        "YER": "Sandown Park Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "HAYDOCK",
        "HIPODROM": "Haydock Park",
        "YER": "Haydock Park Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "NOTTINGHAM",
        "HIPODROM": "Nottingham",
        "YER": "Nottingham Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "CHESTER",
        "HIPODROM": "Chester",
        "YER": "Chester Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "WINDSOR",
        "HIPODROM": "Windsor",
        "YER": "Windsor Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "LEICESTER",
        "HIPODROM": "Leicester",
        "YER": "Leicester Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "FOLKESTONE",
        "HIPODROM": "Folkestone",
        "YER": "Folkestone Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "HAMILTON",
        "HIPODROM": "Hamilton",
        "YER": "Hamilton Park Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "LIONDANGER", "HIPODROM": "Liondanger", "YER": "Liondanger Fransa", "YABANCI": true }, {
        "KEY": "TESTEDEBUC",
        "HIPODROM": "La Teste de Buch",
        "YER": "La Teste de Buch Fransa",
        "YABANCI": true
    }, {
        "KEY": "CARLISLE",
        "HIPODROM": "Carlisle",
        "YER": "Carlisle Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "RIPON",
        "HIPODROM": "Ripon",
        "YER": "Ripon Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "BEVERLEY",
        "HIPODROM": "Beverley",
        "YER": "Beverley Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "THIRSK",
        "HIPODROM": "Thirsk",
        "YER": "Thirsk Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "CHANTILLY", "HIPODROM": "Chantilly", "YER": "Chantilly Fransa", "YABANCI": true }, {
        "KEY": "GALWAY",
        "HIPODROM": "Galway",
        "YER": "Galway \u0130rlanda",
        "YABANCI": true
    }, {
        "KEY": "WARWICK",
        "HIPODROM": "Warwick",
        "YER": "Warwick Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "PONTEFRACT",
        "HIPODROM": "Pontefract",
        "YER": "Pontefract Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "YARMOUTH",
        "HIPODROM": "Yarmouth",
        "YER": "Yarmouth Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "PHILADELPH", "HIPODROM": "Philadelphia", "YER": "Philadelphia ABD", "YABANCI": true }, {
        "KEY": "TURFFONTEI",
        "HIPODROM": "Turffontein",
        "YER": "Turffontein Guney Afrika",
        "YABANCI": true
    }, { "KEY": "CAPEVERDE", "HIPODROM": "Cape Verde", "YER": "Cape Verde", "YABANCI": true }, {
        "KEY": "GREYVILLE",
        "HIPODROM": "Greyville",
        "YER": "Greyville Guney Afrika",
        "YABANCI": true
    }, { "KEY": "ABD", "HIPODROM": "ABD", "YER": "ABD", "YABANCI": true }, {
        "KEY": "VAAL",
        "HIPODROM": "Vaal",
        "YER": "Vaal Guney Afrika",
        "YABANCI": true
    }, { "KEY": "FAIRVIEW", "HIPODROM": "Fairview", "YER": "Fairview Guney Afrika", "YABANCI": true }, {
        "KEY": "ARLINGTON",
        "HIPODROM": "Arlington",
        "YER": "Arlington G\u00fcney Afrika",
        "YABANCI": true
    }, {
        "KEY": "CLAIRWOOD",
        "HIPODROM": "Clairwood",
        "YER": "Clairwood G\u00fcney Afrika",
        "YABANCI": true
    }, {
        "KEY": "BRIGHTON",
        "HIPODROM": "Brighton",
        "YER": "Brighton Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "BRIDGE",
        "HIPODROM": "Catterick Bridge",
        "YER": "Catterick Bridge Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "WOODBINE", "HIPODROM": "Woodbine", "YER": "Woodbine Kanada", "YABANCI": true }, {
        "KEY": "CHURCHILL",
        "HIPODROM": "Churchill Downs",
        "YER": "Churchill Downs ABD",
        "YABANCI": true
    }, { "KEY": "TAMPABAY", "HIPODROM": "Tampa Bay Downs", "YER": "Tampa Bay Downs ABD", "YABANCI": true }, {
        "KEY": "TURFWAY",
        "HIPODROM": "Turfway Park",
        "YER": "Turfway Park ABD",
        "YABANCI": true
    }, { "KEY": "FINGER", "HIPODROM": "Finger Lakes", "YER": "Finger Lakes ABD", "YABANCI": true }, {
        "KEY": "MARSEILLE",
        "HIPODROM": "Pont De Vivaux",
        "YER": "Pont De Vivaux Fransa",
        "YABANCI": true
    }, { "KEY": "MONMOUNTH", "HIPODROM": "Monmouth Park", "YER": "Monmouth Park ABD", "YABANCI": true }, {
        "KEY": "FORTERIE",
        "HIPODROM": "Fort Erie",
        "YER": "Fort Erie Kanada",
        "YABANCI": true
    }, {
        "KEY": "FFOSLAS",
        "HIPODROM": "Ffos Las",
        "YER": "Ffos Las Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "SUFFOLK", "HIPODROM": "Suffolk Downs", "YER": "Suffolk Downs ABD", "YABANCI": true }, {
        "KEY": "CHARLES",
        "HIPODROM": "Charles Town",
        "YER": "Charles Town ABD",
        "YABANCI": true
    }, { "KEY": "DELAWARE", "HIPODROM": "Delaware Park", "YER": "Delaware Park ABD", "YABANCI": true }, {
        "KEY": "KEENELAND",
        "HIPODROM": "Keeneland",
        "YER": "Keeneland ABD",
        "YABANCI": true
    }, { "KEY": "AQUEDUCT", "HIPODROM": "Aqueduct", "YER": "Aqueduct ABD", "YABANCI": true }, {
        "KEY": "PORNICHET",
        "HIPODROM": "Pornichet La Baule ",
        "YER": "Pornichet La Baule Fransa",
        "YABANCI": true
    }, {
        "KEY": "GULFSTREAM",
        "HIPODROM": "Gulfstream Park ",
        "YER": "Gulfstream Park ABD",
        "YABANCI": true
    }, {
        "KEY": "SANTIAGO",
        "HIPODROM": "ClubHipico Santiago",
        "YER": "ClubHipico Santiago \u015eili",
        "YABANCI": true
    }, {
        "KEY": "NEWCASTLE",
        "HIPODROM": "Newcastle",
        "YER": "Newcastle Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "BELMONT", "HIPODROM": "Belmont Park", "YER": "Belmont Park ABD", "YABANCI": true }, {
        "KEY": "BATH",
        "HIPODROM": "Bath",
        "YER": "Bath Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "FAIRMOUNT", "HIPODROM": "Fairmount Park", "YER": "Fairmount Park ABD", "YABANCI": true }, {
        "KEY": "CHEPSTOW",
        "HIPODROM": "Chepstow ",
        "YER": "Chepstow Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "SARATOGA", "HIPODROM": "Saratoga", "YER": "Saratoga ABD", "YABANCI": true }, {
        "KEY": "SALISBURY",
        "HIPODROM": "Salisbury",
        "YER": "Salisbury Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "MUSSELBURG",
        "HIPODROM": "Musselburgh",
        "YER": "Musselburgh Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "AYR", "HIPODROM": "Ayr", "YER": "Ayr Birle\u015fik Krall\u0131k", "YABANCI": true }, {
        "KEY": "WEST",
        "HIPODROM": "Gulfstream Park West",
        "YER": "Gulfstream Park West ABD",
        "YABANCI": true
    }, {
        "KEY": "VALPARAISO",
        "HIPODROM": "Valparaiso Sporting",
        "YER": "Valparaiso \u015eili",
        "YABANCI": true
    }, {
        "KEY": "CHILE",
        "HIPODROM": "Chile Hipodromo ",
        "YER": "Chile Hipodromo \u015eili",
        "YABANCI": true
    }, { "KEY": "BAKI", "HIPODROM": "Baki", "YER": "Baki Azerbaycan", "YABANCI": true }, {
        "KEY": "CHELMSFORD",
        "HIPODROM": "Chelmsford City",
        "YER": "Chelmsford City Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "PIMLICO", "HIPODROM": "Pimlico", "YER": "Pimlico ABD", "YABANCI": true }, {
        "KEY": "WETHERBY",
        "HIPODROM": "Wetherby",
        "YER": "Wetherby Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, { "KEY": "LAUREL", "HIPODROM": "Laurel Park", "YER": "Laurel Park ABD", "YABANCI": true }, {
        "KEY": "LONGCHAMP",
        "HIPODROM": "PARISLONGCHAMP",
        "YER": "Longchamp Fransa",
        "YABANCI": true
    }, {
        "KEY": "MAHONING",
        "HIPODROM": "Mahoning Valley",
        "YER": "Mahoning Valley ABD",
        "YABANCI": true
    }, {
        "KEY": "CONCEPCION",
        "HIPODROM": "Club Hipico de Concepcion ",
        "YER": "Club Hipico de Concepcion \u015eili",
        "YABANCI": true
    }, { "KEY": "LYON", "HIPODROM": "Lyon La Soie", "YER": "Lyon la Soie Fransa", "YABANCI": true }, {
        "KEY": "VICHY",
        "HIPODROM": "Vichy",
        "YER": "Vichy Fransa",
        "YABANCI": true
    }, { "KEY": "DELMAR", "HIPODROM": "Del Mar", "YER": "Del Mar ABD", "YABANCI": true }, {
        "KEY": "ALAMITOS",
        "HIPODROM": "Los Alamitos",
        "YER": "Los Alamitos ABD",
        "YABANCI": true
    }, { "KEY": "ELLISPARK", "HIPODROM": "Ellis Park", "YER": "Ellis Park ABD", "YABANCI": true }, {
        "KEY": "FAIRGROUND",
        "HIPODROM": "Fair Grounds",
        "YER": "Fair Grounds ABD",
        "YABANCI": true
    }, { "KEY": "SANISIDRO", "HIPODROM": "San Isidro", "YER": "San Isidro Arjantin", "YABANCI": true }, {
        "KEY": "MARONAS",
        "HIPODROM": "Maronas",
        "YER": "Maronas Uruguay",
        "YABANCI": true
    }, { "KEY": "SHEBA", "HIPODROM": "Nad Al Sheba ", "YER": "Nad Al Sheba Dubai", "YABANCI": true }, {
        "KEY": "KENILWORTH",
        "HIPODROM": "Kenilworth",
        "YER": "Kenilworth G\u00fcney Afrika",
        "YABANCI": true
    }, {
        "KEY": "FLAMINGO",
        "HIPODROM": "Flamingo Park",
        "YER": "Flamingo Park  G\u00fcney Afrika",
        "YABANCI": true
    }, {
        "KEY": "SCOTTSVILL",
        "HIPODROM": "Scottsville",
        "YER": "Scottsville G\u00fcney Afrika",
        "YABANCI": true
    }, {
        "KEY": "DURBANVILL",
        "HIPODROM": "Durbanville",
        "YER": "Durbanville G\u00fcney Afrika",
        "YABANCI": true
    }, { "KEY": "KRANJI", "HIPODROM": "Kranji Singapur", "YER": "Kranji Singapur", "YABANCI": true }, {
        "KEY": "HAPPYVALLE",
        "HIPODROM": "Happy Valley",
        "YER": "Happy Valley Hong Kong",
        "YABANCI": true
    }, { "KEY": "CAGNESSUR", "HIPODROM": "Cagnes Sur Mer", "YER": "Cagnes Sur Mer Fransa", "YABANCI": true }, {
        "KEY": "RIYAD",
        "HIPODROM": "Riyad",
        "YER": "Riyad Suudi Arabistan",
        "YABANCI": true
    }, { "KEY": "SAINTCLOUD", "HIPODROM": "Saint Cloud", "YER": "Saint Cloud Fransa", "YABANCI": true }, {
        "KEY": "ROGERSDOWN",
        "HIPODROM": "Will Rogers Downs",
        "YER": "Will Rogers Downs ABD",
        "YABANCI": true
    }, { "KEY": "NAAS", "HIPODROM": "Naas", "YER": "Naas \u0130rlanda", "YABANCI": true }, {
        "KEY": "DUNDALK",
        "HIPODROM": "Dundalk",
        "YER": "Dundalk \u0130rlanda",
        "YABANCI": true
    }, { "KEY": "SHATIN", "HIPODROM": "Sha Tin", "YER": "Sha Tin Hong Kong", "YABANCI": true }, {
        "KEY": "CRANBOURNE",
        "HIPODROM": "Cranbourne",
        "YER": "Cranbourne Avustralya",
        "YABANCI": true
    }, { "KEY": "PAKENHAM", "HIPODROM": "Pakenham", "YER": "Pakenham Avustralya", "YABANCI": true }, {
        "KEY": "PERTHASCOT",
        "HIPODROM": "Perth Ascot",
        "YER": "Perth Ascot Avustralya",
        "YABANCI": true
    }, { "KEY": "GAVEA", "HIPODROM": "Gavea", "YER": "Gavea Brezilya", "YABANCI": true }, {
        "KEY": "KOLN",
        "HIPODROM": "K\u00f6ln",
        "YER": "Koln Almanya",
        "YABANCI": true
    }, { "KEY": "HANNOVER", "HIPODROM": "Hannover", "YER": "Hannover Almanya", "YABANCI": true }, {
        "KEY": "DAGLFING",
        "HIPODROM": "M\u00fcnchen-Daglfing",
        "YER": "M\u00fcnchen-Daglfing Almanya",
        "YABANCI": true
    }, { "KEY": "MULHEIM", "HIPODROM": "M\u00fclheim", "YER": "M\u00fclheim Almanya", "YABANCI": true }, {
        "KEY": "HOPPEGARTE",
        "HIPODROM": "Berlin Hoppegarten",
        "YER": "Berlin Hoppegarten Almanya",
        "YABANCI": true
    }, { "KEY": "MANNHEIM", "HIPODROM": "Mannheim", "YER": "Mannheim Almanya", "YABANCI": true }, {
        "KEY": "SUNSHINE",
        "HIPODROM": "Sunshine Coast",
        "YER": "Sunshine Coast Avustralya",
        "YABANCI": true
    }, { "KEY": "PARILLY", "HIPODROM": "Lyon Parilly", "YER": "Lyon Parilly Fransa", "YABANCI": true }, {
        "KEY": "RIEM",
        "HIPODROM": "M\u00fcnchen-Riem",
        "YER": "M\u00fcnchen-Riem Almanya",
        "YABANCI": true
    }, {
        "KEY": "SALONDEPRO",
        "HIPODROM": "Salon de Provence",
        "YER": "Salon de Provence Fransa",
        "YABANCI": true
    }, {
        "KEY": "BORELY",
        "HIPODROM": "Marseille Borely",
        "YER": "Marseille Borely Fransa",
        "YABANCI": true
    }, {
        "KEY": "DUSSELDORF",
        "HIPODROM": "D\u00fcsseldorf",
        "YER": "D\u00fcsseldorf Almanya",
        "YABANCI": true
    }, { "KEY": "DRESDEN", "HIPODROM": "Dresden", "YER": "Dresden Almanya", "YABANCI": true }, {
        "KEY": "LEBOUSCAT",
        "HIPODROM": "Bordeaux Le Bouscat",
        "YER": "Bordeaux Le Bouscat Fransa",
        "YABANCI": true
    }, { "KEY": "BADENBADEN", "HIPODROM": "Baden-Baden", "YER": "Baden-Baden Almanya", "YABANCI": true }, {
        "KEY": "DIEPPE",
        "HIPODROM": "Dieppe Fransa",
        "YER": "Dieppe Fransa",
        "YABANCI": true
    }, {
        "KEY": "CLAIREFONT",
        "HIPODROM": "Clairefontaine",
        "YER": "Clairefontaine Fransa",
        "YABANCI": true
    }, { "KEY": "MOULINS", "HIPODROM": "Moulins", "YER": "Moulins Fransa", "YABANCI": true }, {
        "KEY": "DORTMUND",
        "HIPODROM": "Dortmund",
        "YER": "Dortmund Almanya",
        "YABANCI": true
    }, { "KEY": "HAMBURG", "HIPODROM": "Hamburg", "YER": "Hamburg Almanya", "YABANCI": true }, {
        "KEY": "LAUNCESTON",
        "HIPODROM": "Launceston",
        "YER": "Launceston Avustralya",
        "YABANCI": true
    }, { "KEY": "PAU", "HIPODROM": "Pau", "YER": "Pau Fransa", "YABANCI": true }, {
        "KEY": "ANGERS",
        "HIPODROM": "Angers",
        "YER": "Angers Fransa",
        "YABANCI": true
    }, { "KEY": "TOULOUSE", "HIPODROM": "Toulouse", "YER": "Toulouse Fransa", "YABANCI": true }, {
        "KEY": "MOONEEVALL",
        "HIPODROM": "Moonee Valley",
        "YER": "Moonee Valley Avustralya",
        "YABANCI": true
    }, {
        "KEY": "COLONIAL",
        "HIPODROM": "Colonial Downs",
        "YER": "Colonial Downs ABD",
        "YABANCI": true
    }, {
        "KEY": "KENTUCKYDW",
        "HIPODROM": "Kentucky Downs",
        "YER": "Kentucky Downs ABD",
        "YABANCI": true
    }, { "KEY": "FONTAINE", "HIPODROM": "Fontainebleau", "YER": "Fontainebleau Fransa", "YABANCI": true }, {
        "KEY": "REDCAR",
        "HIPODROM": "Redcar",
        "YER": "Redcar Birle\u015fik Krall\u0131k",
        "YABANCI": true
    }, {
        "KEY": "MONTDEMARS",
        "HIPODROM": "Mont De Marsan",
        "YER": "Mont De Marsan Fransa",
        "YABANCI": true
    }, {
        "KEY": "LAROCHE",
        "HIPODROM": "Le Croise-Laroche",
        "YER": "Le Croise-Laroche Fransa",
        "YABANCI": true
    }, {
        "KEY": "BADHARZBUR",
        "HIPODROM": "Bad Harzburg",
        "YER": "Bad Harzburg Almanya",
        "YABANCI": true
    }, { "KEY": "TOOWOOMBA", "HIPODROM": "Toowoomba", "YER": "Toowoomba Avustralya", "YABANCI": true }, {
        "KEY": "JEBELALI",
        "HIPODROM": "Jebel Ali ",
        "YER": "Jebel Ali BAE",
        "YABANCI": true
    }, {
        "KEY": "AVUBELMONT",
        "HIPODROM": "Belmont Avustralya",
        "YER": "Belmont Avustralya",
        "YABANCI": true
    }, {
        "KEY": "PINJARRA",
        "HIPODROM": "Pinjarra Park",
        "YER": "Pinjarra Park Avustralya",
        "YABANCI": true
    }, { "KEY": "COMPIEGNE", "HIPODROM": "Compiegne", "YER": "Compiegne Fransa", "YABANCI": true }, {
        "KEY": "NAVAN",
        "HIPODROM": "Navan",
        "YER": "Navan \u0130rlanda",
        "YABANCI": true
    }, { "KEY": "CORK", "HIPODROM": "Cork", "YER": "Cork \u0130rlanda", "YABANCI": true }, {
        "KEY": "ABUDHABI",
        "HIPODROM": "Abu Dhabi",
        "YER": "Abu Dhabi Birle\u015fik Arap Emirlikleri",
        "YABANCI": true
    }, { "KEY": "AMIENS", "HIPODROM": "Amiens", "YER": "Amiens Fransa", "YABANCI": true }, {
        "KEY": "KILLARNEY",
        "HIPODROM": "Killarney",
        "YER": "Killarney \u0130rlanda",
        "YABANCI": true
    }, { "KEY": "BALLINROBE", "HIPODROM": "Ballinrobe", "YER": "Ballinrobe \u0130rlanda", "YABANCI": true }, {
        "KEY": "GOWRAN",
        "HIPODROM": "Gowran Park",
        "YER": "Gowran Park \u0130rlanda",
        "YABANCI": true
    }, {
        "KEY": "BELMONTBIG",
        "HIPODROM": "Belmont At The Big A",
        "YER": "Belmont At The Big A (Aqueduct) ABD",
        "YABANCI": true
    }, { "KEY": "FAIRYHOUSE", "HIPODROM": "Fairyhouse", "YER": "Fairyhouse \u0130rlanda", "YABANCI": true }, {
        "KEY": "EVREUX",
        "HIPODROM": "Evreux",
        "YER": "Evreux Fransa",
        "YABANCI": true
    }, {
        "KEY": "INDIANAPOL",
        "HIPODROM": "Horseshoe Indianapolis",
        "YER": "Horseshoe Indianapolis ABD",
        "YABANCI": true
    }, {
        "KEY": "DOWNROYAL",
        "HIPODROM": "Down Royal",
        "YER": "Down Royal \u0130rlanda",
        "YABANCI": true
    }, { "KEY": "SELANGOR", "HIPODROM": "Selangor", "YER": "Selangor Malezya", "YABANCI": true }, {
        "KEY": "GOLDENGATE",
        "HIPODROM": "Golden Gate",
        "YER": "Golden Gate ABD",
        "YABANCI": true
    }, { "KEY": "PARADISE", "HIPODROM": "Turf Paradise", "YER": "Turf Paradise ABD", "YABANCI": true }, {
        "KEY": "ESPERANCE",
        "HIPODROM": "Esperance Avustralya",
        "YER": "Esperance Avustralya",
        "YABANCI": true
    }, { "KEY": "PISA", "HIPODROM": "Pisa", "YER": "Pisa \u0130talya", "YABANCI": true }, {
        "KEY": "AGNANO",
        "HIPODROM": "Agnano",
        "YER": "Agnano \u0130talya",
        "YABANCI": true
    }, { "KEY": "GERALDTON", "HIPODROM": "Geraldton", "YER": "Geraldton Avustralya", "YABANCI": true }, {
        "KEY": "ALBANY",
        "HIPODROM": "Albany",
        "YER": "Albany Avustralya",
        "YABANCI": true
    }, { "KEY": "AVUYORK", "HIPODROM": "York Avustralya", "YER": "York Avustralya", "YABANCI": true }, {
        "KEY": "ROMA",
        "HIPODROM": "Roma \u0130talya",
        "YER": "Roma \u0130talya",
        "YABANCI": true
    }, { "KEY": "LEMANS", "HIPODROM": "Le Mans", "YER": "Le Mans Fransa", "YABANCI": true }, {
        "KEY": "KARMAADANA",
        "HIPODROM": "Karma Adana",
        "YER": "Karma Adana",
        "YABANCI": true
    }, {
        "KEY": "KARMAIZMIR",
        "HIPODROM": "Karma \u0130zmir",
        "YER": "Karma \u0130zmir",
        "YABANCI": true
    }, {
        "KEY": "KARMAISTAN",
        "HIPODROM": "Karma \u0130stanbul",
        "YER": "Karma \u0130stanbul",
        "YABANCI": true
    }, { "KEY": "KARMABURSA", "HIPODROM": "Karma Bursa", "YER": "Karma Bursa", "YABANCI": true }, {
        "KEY": "KARMAANKAR",
        "HIPODROM": "Karma Ankara",
        "YER": "Karma Ankara",
        "YABANCI": true
    }, {
        "KEY": "KARMASANLI",
        "HIPODROM": "Karma \u015eanl\u0131urfa",
        "YER": "Karma \u015eanl\u0131urfa",
        "YABANCI": true
    }, {
        "KEY": "KARMAELAZI",
        "HIPODROM": "Karma Elaz\u0131\u011f",
        "YER": "Karma Elaz\u0131\u011f",
        "YABANCI": true
    }, {
        "KEY": "KARMADIYAR",
        "HIPODROM": "Karma Diyarbak\u0131r",
        "YER": "Karma Diyarbak\u0131r",
        "YABANCI": true
    }, { "KEY": "KARMAKOCAE", "HIPODROM": "Karma Kocaeli", "YER": "Karma Kocaeli", "YABANCI": true },
    {
        "KEY": "KARMAANTAL",
        "HIPODROM": "Karma Antalya",
        "YER": "Karma Antalya",
        "YABANCI": true
    }, {
        "KEY": "ROSCOMMON",
        "HIPODROM": "Roscommon",
        "YER": "Roscommon İrlanda",
        "YABANCI": true
    },];
export var tjkHippodromsMap = Object.assign.apply(Object, __spreadArray([{}], tjkHippodroms.map(function (x) {
    var _a;
    return (_a = {}, _a[String(x.KEY).toLowerCase()] = x, _a);
}), false));
export var tjkEditor = {
    12276803: {
        "id": 12276803,
        "first_name": "Oytun Yağız",
        "last_name": "Göksel",
        "nick_name": null,
        "photo": "https://cdn.oley.com/yazar/Oytun.jpg"
    },
    12276848: {
        "id": 12276848,
        "first_name": "Eray",
        "last_name": "Balcı",
        "nick_name": null,
        "photo": "https://cdn.oley.com/yazar/Eray.jpeg"
    },
    12276849: {
        "id": 12276849,
        "first_name": "Hakan",
        "last_name": "Cantınaz",
        "nick_name": null,
        "photo": "https://cdn.oley.com/yazar/Hakan.jpeg"
    },
    12276852: {
        "id": 12276852,
        "first_name": "Tolgahan",
        "last_name": "İmal",
        "nick_name": null,
        "photo": "https://cdn.oley.com/yazar/Tolgahan.jpeg"
    },
    12276853: {
        "id": 12276853,
        "first_name": "Berkay",
        "last_name": "Kurt",
        "nick_name": null,
        "photo": "https://cdn.oley.com/yazar/Berkay.jpg"
    },
};
