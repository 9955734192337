import iconWait from "../../assets/img/baslamadi.png";
import iconContinue from "../../assets/img/devam_ediyor.png";
import iconUnOfficialWin from "../../assets/img/resmi_degil_kazandi.png";
import iconWillWin from "../../assets/img/kazaniyor.png";
import iconUnOfficialLose from "../../assets/img/resmi_degil_kaybetti.png";
import iconWillLose from "../../assets/img/kaybediyor.png";
import iconCanceled from "../../assets/img/iptal.png";
import iconOfficialWin from "../../assets/img/resmi_kazandi.png";
import iconOfficialLose from "../../assets/img/resmi_kaybetti.png";
export var statusIcon = {
    0: iconWait,
    1: iconContinue,
    2: iconUnOfficialWin,
    3: iconWillWin,
    4: iconUnOfficialLose,
    5: iconWillLose,
    6: iconContinue,
    7: iconCanceled,
    8: iconOfficialWin,
    9: iconOfficialLose,
};
