var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getDateHumanize, useForceUpdate } from "../../../lib/misc.functions";
import { LiveScoreStatusType } from "../../../definitions/constants/event-status-type.constant";
import Play from "../../../assets/feather/play.svg";
import { betSlipActionSubject } from "../../../store/betslip.store";
import { eventUpdateSubject } from "../../../store/sportsbook.store";
var updateTimer;
export function LongTermEvent(props) {
    var navigate = useNavigate();
    // fixme: re-render edilmesede bu kısım sürekli çalıştırılıyor gibi kontrol et
    var eventType = props.eventType;
    var event = props.event;
    var forceUpdate = useForceUpdate();
    if (event.liveScoreStatus && event.matchPlayTime && event.currentScore) {
        var time_statuses = ["520", "521", "522", "523", "700"];
        if (time_statuses.includes(event.liveScoreStatus.toString())) {
            event.minute = event.matchPlayTime + '.dk';
        }
        else {
            event.minute = LiveScoreStatusType[Number(event.liveScoreStatus)];
        }
    }
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            if (typeof events === 'object' && event) {
                if (['toggle', 'remove'].includes(events.action)) {
                    if (events.betSlip.sportEvent) {
                        if (events.betSlip.sportEvent.eventId === event.eventId) {
                            forceUpdate();
                        }
                    }
                }
                else if (events.action === 'clear') {
                    if (events.betSlip.includes(event.eventId)) {
                        forceUpdate();
                    }
                }
            }
        });
        var eventUpdateSubject_subscription = eventUpdateSubject.subscribe(function (events) {
            if (events.includes(event.eventId)) {
                clearTimeout(updateTimer);
                updateTimer = setTimeout(function () {
                    forceUpdate();
                }, 300);
            }
        });
        return function () {
            clearTimeout(updateTimer);
            betSlipActionSubject_subscription.unsubscribe();
            eventUpdateSubject_subscription.unsubscribe();
        };
    }, []);
    if (event.isRemoved) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "event-date w-100" }, { children: [_jsx("span", __assign({ className: "date mt-0" }, { children: getDateHumanize(event.eventDate) }), void 0), _jsx("span", { children: event.eventDate.split("T")[1].substring(0, 5) }, void 0), Number(event.bettingStatus) === 1 && Number(event.isLiveEvent) === 1 && Number(event.bettingPhase) === 0 ? _jsx("div", __assign({ className: "is-live" }, { children: "Canl\u0131" }), void 0) : null, Number(event.bettingStatus) === 1 && Number(event.isLiveEvent) === 1 && Number(event.bettingPhase) !== 0 ? _jsx("div", __assign({ className: "minute minute-blink" }, { children: "Canl\u0131" }), void 0) : null] }), void 0), _jsxs("div", __assign({ onClick: function () {
                    navigate('/iddaa/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                }, className: "participants-labels longterm" }, { children: [_jsxs("div", __assign({ className: "competition nowrap" }, { children: [event.liveStreamAvailable > 0 ? _jsx("div", __assign({ className: "liveStreamBadge " + (event.liveStreamAvailable === 2 ? 'blink' : '') }, { children: _jsx("img", { src: Play }, void 0) }), void 0) : '', event.competitionGroupName, " ", event.competitionName.replace(event.competitionGroupName, '')] }), void 0), _jsx("div", __assign({ className: "participants" }, { children: _jsx("div", __assign({ className: "labels" }, { children: event.eventName }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "more-odds longterm float-right" }, { children: _jsx("button", __assign({ className: "btn added-".concat(event.isAdded), onClick: function () {
                        navigate('/iddaa/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/' + event.eventId);
                        // setShowEventDetail(event);
                    } }, { children: _jsxs("div", __assign({ className: "outcome-name" }, { children: ["+", event.marketOutcomeCount] }), void 0) }), void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0));
}
