import paths from './paths';
export default [
    {
        path: "/",
        title: "Canlı İddaa | Spor Toto | Canlı Bahis - Oley.com",
        description: "Kazanmanın sesi, bahsin adresi Oley.com. Canlı İddaa, Canlı Skor, Spor Toto, Milli Piyango ve diğer şans oyunları  ile kazanmanın her dilde adı Oley.com'a hemen üye ol, oyna ve kazan!",
        keywords: ""
    },
    {
        path: paths.TJK,
        title: "TJK | At Yarışı Bülteni | TJK Sonuçları - Oley.com",
        description: "At yarışına dair her şey Oley.com'da. TJK at yarışı bültenini günlük olarak görebilir, tjk at yarışı sonuçlarını güncel olarak takip edebilirsiniz.",
        keywords: ""
    },
];
