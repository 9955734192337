var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FinancalTransactionsApi } from "../../subpages/financialTransactions/financial.transactions.api";
import { Storage } from "../../../../lib/localstorege.service";
import { modalServiceSubject } from "../../../../components/modal/modal";
var Title = function () {
    return (_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "d-sm-table-cell d-none text-center" }, { children: "Banka Ad\u0131" }), void 0), _jsx("th", __assign({ className: "d-sm-table-cell d-none text-center" }, { children: "IBAN" }), void 0), _jsx("th", __assign({ className: "d-sm-none d-table-cell text-center" }, { children: "Banka Ad\u0131 - IBAN" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Sil" }), void 0)] }, void 0) }, void 0));
};
var BankAccountTableWidget = function (props) {
    var _a = useState([]), myBankAccountData = _a[0], setMyBankAccountData = _a[1];
    var token = Storage.get('token');
    var data = {
        token: token
    };
    useEffect(function () {
        FinancalTransactionsApi.myBankAccounts(data).then(function (result) {
            if (result.status) {
                setMyBankAccountData(result.data);
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    }, [props.isAdded]);
    var deleteAccount = function (id) {
        var token = Storage.get('token');
        var deleteData = {
            token: token,
            id: id
        };
        modalServiceSubject.next({
            title: 'Emin misiniz?',
            content: 'Bu hesabını silmek istediğinizden emin misiniz?',
            confirm: {
                sure: {
                    label: 'Sil',
                    action: function () {
                        FinancalTransactionsApi.deleteMyBankAccounts(deleteData).then(function (result) {
                            if (result.status) {
                                modalServiceSubject.next({
                                    title: "Silindi!",
                                    content: "Kayıt başarıyla silindi.",
                                    confirm: {
                                        cancel: { label: 'Tamam' }
                                    },
                                    timeout: 0
                                });
                                var element = document.getElementById(id);
                                element.style.display = "none";
                            }
                            else {
                                modalServiceSubject.next({
                                    title: "Uyarı!",
                                    content: result.message,
                                    confirm: {
                                        cancel: { label: 'Tamam' }
                                    },
                                    timeout: 0
                                });
                            }
                        });
                    }
                },
                cancel: {
                    label: 'Vazgeç',
                }
            },
            timeout: 0,
        });
    };
    return (_jsxs("div", { children: [_jsxs("table", __assign({ className: "table" }, { children: [_jsx(Title, {}, void 0), _jsx("tbody", { children: myBankAccountData.map(function (data, key) {
                            return (_jsxs("tr", __assign({ id: data.id }, { children: [_jsx("td", __assign({ className: "hidden-sm font-weight-light" }, { children: data.bank__name }), void 0), _jsx("td", __assign({ className: "hidden-sm text-center" }, { children: data.iban }), void 0), _jsxs("td", __assign({ className: "visible-sm visible-sm text-left" }, { children: [_jsx("span", __assign({ className: "font-weight-bold" }, { children: data.bank__name }), void 0), _jsx("br", {}, void 0), data.iban] }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: _jsx("button", __assign({ className: "btn btn-small btn-light ", onClick: function () { return deleteAccount(data.id); } }, { children: "Sil" }), void 0) }), void 0)] }), "accountMyBank" + key));
                        }) }, void 0)] }), void 0), myBankAccountData.length === 0 ? _jsx(_Fragment, { children: _jsx("p", __assign({ className: "text-center py-5" }, { children: "Listelenecek banka hesab\u0131n\u0131z yoktur." }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }, void 0));
};
export default BankAccountTableWidget;
