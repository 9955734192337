var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { initialLeftMenu, ServerDate, sportsMenu, useForceUpdate } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import "./tjk.css";
import { TjkMenu } from './components/tjk.top.menu';
import { ApiService } from "../../lib/http.service";
import { Races } from "./races";
import { TjkBetSlip } from "./components/tjk.betslip";
import { env } from "../../definitions/global.vars";
import { TjkResults } from "./results";
import { TjkMuhtemeller } from "./tjk.muhtemeller";
import { TjkAgf } from "./tjk.agf";
import { TayTv } from "./tay.tv";
import { TjkTv } from "./tjk.tv";
function getSubPage() {
    var pathname = useLocation().pathname;
    var parentPath = String(pathname);
    return parentPath.split('/')[2];
}
var years = [];
var day = [];
var today = ServerDate();
var yy = today.getFullYear();
for (var i = yy; i >= (yy - 4); i--) {
    years.push(i);
}
for (var j = 1; j < 32; j++) {
    day.push(j < 10 ? ("0" + j) : j.toString());
}
var Tjk = function (props) {
    console.log(props.params);
    var subPage = getSubPage();
    var api = new ApiService();
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible, subPage]);
    console.log(env.tjk && subPage === 'bahis-yap');
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "page tjk tjkbody" }, { children: [_jsx("div", __assign({ className: "".concat(env.tjk && subPage === 'bulten' ? '' : 'w-100', " right-column float-left") }, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("div", __assign({ className: "float-left" }, { children: _jsx(TjkMenu, { subPage: subPage }, void 0) }), void 0) }), void 0), _jsxs("div", __assign({ className: "card-body pb-2 pb-sm-0" }, { children: [subPage === 'bulten' ? _jsx(Races, {}, void 0) : null, subPage === 'sonuclar' ? _jsx(TjkResults, {}, void 0) : null, subPage === 'muhtemeller' ? _jsx(TjkMuhtemeller, {}, void 0) : null, subPage === 'agf' ? _jsx(TjkAgf, {}, void 0) : null, subPage === 'tay-tv' ? _jsx(TayTv, {}, void 0) : null, subPage === 'tjk-tv-yurtdisi' ? _jsx(TjkTv, {}, void 0) : null] }), void 0)] }), void 0) }), void 0), env.tjk && subPage === 'bulten' ? _jsx(TjkBetSlip, {}, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", { className: "clearfix" }, void 0)] }), void 0), _jsx("div", __assign({ id: "tjkLoading" }, { children: _jsxs("div", __assign({ className: "lds-roller" }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }), void 0)] }, void 0));
};
export default Tjk;
