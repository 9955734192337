var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import videojs from "video.js";
var playerC = null;
var Video = function (props) {
    var videoNode = useRef(null);
    var _a = useState(null), player = _a[0], setPlayer = _a[1];
    useEffect(function () {
        if (videoNode && videoNode.current) {
            var _player = videojs(videoNode.current, props);
            setPlayer(_player);
        }
        return function () {
            if (playerC !== null) {
                playerC.dispose();
            }
        };
    }, []);
    if (player !== null) {
        playerC = player;
    }
    return (_jsx("div", __assign({ "data-vjs-player": true }, { children: _jsx("video", { ref: videoNode, className: "video-js" }, void 0) }), void 0));
};
export function TayTv() {
    var width = window.innerWidth;
    var isMobile = width < 986 ? true : false;
    var play = {
        fill: true,
        fluid: true,
        autoplay: false,
        controls: true,
        preload: "metadata",
        sources: [
            {
                src: isMobile ? "https://tjktv-live.tjk.org/tjktv_720p.m3u8" : 'https://tjktv-live.tjk.org/tjktv_1080p.m3u8',
                type: "application/x-mpegURL"
            }
        ]
    };
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ id: "id_tjk-tv", className: "mt-3 mb-3 " }, { children: _jsx("iframe", { style: { width: '100%', height: '100%', minHeight: '400px' }, src: "https://www.youtube.com/embed/kDvc4MSsnyI?si=Mk4X6zTAZRTsYKMt", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }, void 0) }), void 0) }, void 0));
}
