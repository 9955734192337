var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
var Contact = function () {
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "\u0130leti\u015Fim Bilgileri" }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsxs("p", { children: [_jsx("strong", { children: "\u00DCnvan:" }, void 0), " E Elektronik Bahis Oyunlar\u0131 A.\u015E."] }, void 0), _jsxs("p", { children: [_jsx("strong", { children: "Adres:" }, void 0), " B\u00FCy\u00FCkdere Caddesi Noramin \u0130\u015F Merkezi 237/409-410 Maslak \u2013 Sar\u0131yer / \u0130stanbul"] }, void 0), _jsxs("p", { children: [_jsx("strong", { children: "M\u00FC\u015Fteri Hizmetleri Telefon:" }, void 0), " 0850 532 56 55"] }, void 0), _jsxs("p", { children: [_jsx("strong", { children: "M\u00FC\u015Fteri Hizmetleri Faks:" }, void 0), " 0212 276 17 05"] }, void 0), _jsxs("p", { children: [_jsx("strong", { children: "M\u00FC\u015Fteri Hizmetleri E-Posta:" }, void 0), " destek@oley.com"] }, void 0), _jsxs("p", { children: [_jsx("strong", { children: "Reklam ve \u0130\u015Fbirli\u011Fi E-Posta:" }, void 0), " sosyal.medya@oley.com"] }, void 0), _jsxs("p", { children: [_jsx("strong", { children: "Yetkili denetleme mercileri:" }, void 0), " Spor Toto Te\u015Fkilat Ba\u015Fkanl\u0131\u011F\u0131 ve Milli Piyango \u0130daresi Genel M\u00FCd\u00FCrl\u00FC\u011F\u00FC"] }, void 0), _jsxs("p", { children: [_jsx("strong", { children: "Ticaret Sicil No:" }, void 0), " 695311"] }, void 0), _jsxs("p", { children: [_jsx("strong", { children: "Mersis No:" }, void 0), " 0323048129100014"] }, void 0)] }), void 0)] }), void 0) }, void 0));
};
export default Contact;
