var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
export function TjkTv() {
    var width = window.innerWidth;
    var isMobile = width < 986 ? true : false;
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ id: "id_tjk-tv", className: "mt-3 mb-3 " }, { children: _jsx("iframe", { width: "560", height: "315", src: "https://www.youtube.com/embed/kDvc4MSsnyI", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }, void 0) }), void 0) }, void 0));
}
