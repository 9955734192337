var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import './live.scores.css';
var LiveScoresDetail = function () {
    var params = useParams();
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
        // clickedBg()
    }, []);
    var clickedBg = function () {
        var lmtAI = document.getElementById('lmt-container');
        if (lmtAI) {
            var iframeLMT_1 = document.createElement("iframe");
            iframeLMT_1.width = '100%';
            iframeLMT_1.height = '100%';
            // iframeLMT.documentElement.outerHTML.style.background = 'red !important'
            iframeLMT_1.src = "https://widgets.thesports01.com/tr/3D/tennis?profile=lzmpyfj7vsdukm6&uuid=" + params.matchId;
            lmtAI.append(iframeLMT_1);
            setInterval(function () {
                iframeLMT_1.load = function () {
                    if (iframeLMT_1.body.parentNode) {
                        iframeLMT_1.body.parentNode.style.background = 'red !important';
                        // console.log(element,'element')
                        console.log(iframeLMT_1.querySelector("html"), 'ififififiifi');
                    }
                };
            }, 1000);
        }
    };
    var handleFileLoad = function () {
        console.log("ddd");
        var iframeLMT = document.getElementById("tennis-lmt");
        if (iframeLMT) {
            // iframeLMT.style.maxWidth = '500px';
            // iframeLMT.style.maxHeight = '500px !important';
        }
    };
    return (_jsxs("div", __assign({ className: 'card', onClick: function () { return clickedBg(); } }, { children: [_jsxs("div", __assign({ className: "card-title d-flex justify-content-between" }, { children: [_jsx("h2", __assign({ className: 'my-auto' }, { children: "Canl\u0131 Skor Detaylar\u0131" }), void 0), _jsx(Link, __assign({ to: '/canli-skorlar', className: 'btn btn-outline-primary btn-small', style: { margin: "auto 0" } }, { children: "Canl\u0131 Skorlar'a D\u00F6n" }), void 0)] }), void 0), _jsx("div", __assign({ className: "card-body", style: { width: "100%", overflowX: "hidden" } }, { children: params.sportId === '5' ? (_jsx("div", __assign({ className: 'd-flex justify-content-center m-auto', style: { height: '500px', width: window.innerWidth < 768 ? '350px' : '800px' } }, { children: _jsx("iframe", { onLoad: handleFileLoad, className: "invert-color tennislmt", id: 'tennis-lmt', name: "iframe1", style: { height: window.innerWidth < 768 ? '268px' : '496px', resize: 'none',
                            maxWidth: window.innerWidth < 768 ? '100%' : '800px' }, src: "https://widgets.thesports01.com/tr/3D/tennis?profile=lzmpyfj7vsdukm6&uuid=" + params.matchId, width: "100%" }, void 0) }), void 0)
                // <div id={'lmt-container'}></div>
                ) : (_jsx("iframe", { className: "invert-color", style: { height: "100vh", maxWidth: '100% !important', border: 0, background: 'transparent' }, src: '/assets/new-widgets/live-scores-detail/preview.html' + '?matchId=' + params.matchId, width: "100%" }, void 0)) }), void 0)] }), void 0));
};
export default LiveScoresDetail;
