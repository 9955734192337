var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { postCommentStyles } from "../../../definitions/constants/modal.constant";
import Close from "../../../assets/feather/x-circle.svg";
import { env } from "../../../definitions/global.vars";
import DefaultAvatar from "../../../assets/img/avatar.png";
import { dateTimeFormat, linkify } from "../../../lib/misc.functions";
import { member } from "../../../store/login.store";
import HeartFill from "../../../assets/feather/heart-fill.svg";
import Heart from "../../../assets/feather/heart.svg";
import { askToReport, commentUpdateSubject, deleteComment, getPostComment, likeComment, sendComment } from "../../../services/sosyoley.services";
import Flag from "../../../assets/feather/flag.svg";
import Trash from "../../../assets/feather/trash-2-red.svg";
import Modal from "react-modal";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { inProgress } from "../../../app";
import { modalServiceSubject } from "../../../components/modal/modal";
import { useNavigate } from "react-router";
var postId;
var page;
export function CommentWidget(props) {
    var navigate = useNavigate();
    var forceUpdate = props.forceUpdate;
    var _a = props.modalState, postCommentModal = _a.postCommentModal, setPostCommentModal = _a.setPostCommentModal;
    useEffect(function () {
        page = 0;
    }, []);
    useEffect(function () {
        if (postCommentModal && postCommentModal.post_id && postCommentModal.post_id !== postId) {
            openPostCommentModal(postCommentModal);
        }
    }, [postCommentModal]);
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, reset = _b.reset;
    var closeCommentModal = function () {
        setPostCommentModal(false);
    };
    var openPostCommentModal = function (post) {
        if (!inProgress.state) {
            inProgress.state = true;
            getPostComment(post.post_id).then(function (comments) {
                inProgress.state = false;
                setPostCommentModal({ post: post, postComments: comments, });
            });
            inProgress.state = false;
        }
    };
    var onSubmit = function (data) {
        sendComment(postCommentModal.post.post_id, data.comment).then(function (response) {
            if (response.status === true && response.message === "Yorumunuz kaydedildi.") {
                if (response.data.post && response.data.post.comment_count) {
                    postCommentModal.post.comment_count = response.data.post.comment_count;
                    response.data.comment['member'] = {
                        member_id: member.id,
                        photo: member.photo,
                        nick_name: member.nick_name,
                    };
                    postCommentModal.postComments.unshift(response.data.comment);
                    commentUpdateSubject.next({ action: 'comment_count', postId: response.data.post.post_id, count: response.data.post.comment_count });
                    reset({ comment: "" });
                }
            }
            else {
                //fixme hata bilgisi ekrana basilmali
                modalServiceSubject.next({
                    title: 'Uyarı !',
                    content: response.message,
                    confirm: {
                        cancel: {
                            label: 'Tamam'
                        }
                    }
                });
                reset({ comment: "" });
            }
        });
    };
    var deleteMyComment = function (post, comment) {
        modalServiceSubject.next({
            title: 'Emin misin?',
            content: 'Yapmış olduğunuz yorum silmek istediğinizden emin misiniz?',
            confirm: {
                sure: {
                    label: 'Sil',
                    action: function () {
                        deleteComment(comment.comment_id).then(function (response) {
                            if (response.status) {
                                post.comment_count -= 1;
                                var index = postCommentModal.postComments.indexOf(comment);
                                postCommentModal.postComments.splice(index, 1);
                                forceUpdate();
                            }
                        });
                    }
                },
                cancel: {
                    label: 'Vazgeç'
                }
            }
        });
    };
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ isOpen: postCommentModal !== false, onRequestClose: closeCommentModal, style: postCommentStyles }, { children: [_jsxs("div", __assign({ className: "modal-header" }, { children: [_jsx("h2", { children: "Yorumunu Yap" }, void 0), _jsx("div", __assign({ className: "close-detail invert-color" }, { children: _jsx("img", { src: Close, width: 24, onClick: function () {
                                    setPostCommentModal(false);
                                } }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "modal-body eventComments" }, { children: _jsxs("div", __assign({ className: "entries" }, { children: [postCommentModal && postCommentModal.postComments && postCommentModal.postComments.length > 0 ? postCommentModal.postComments.map(function (el, key) {
                                return (_jsxs("div", __assign({ className: "post" }, { children: [_jsx("img", { className: "avatar", src: el.member.photo ? env.sosyoley_api + el.member.photo : DefaultAvatar }, void 0), _jsxs("div", __assign({ onClick: function () { setPostCommentModal(false); navigate('/sosyoley/profil/' + el.member_id + '/kuponlarim/'); }, className: "nickname d-flex align-items-center cursor-pointer", style: { height: "100%" } }, { children: ["@", el.member.nick_name] }), void 0), _jsx("div", { className: "comment mb-2", dangerouslySetInnerHTML: { __html: linkify(el.comment) } }, void 0), _jsx("div", __assign({ className: "create-date" }, { children: dateTimeFormat(el.create_date) }), void 0), _jsxs("div", __assign({ className: "comment-actions", style: { minWidth: (Number(el.member_id) === Number(member.id) ? '180px' : '120px') } }, { children: [_jsxs("div", __assign({ className: "col" }, { children: [_jsx("img", { className: "invert-color cursor-pointer", src: el.is_liked && el.is_liked[0] === 1 ? HeartFill : Heart, width: 16, onClick: function () {
                                                                likeComment(el).then(function () {
                                                                    forceUpdate();
                                                                });
                                                            } }, void 0), _jsx("i", { children: el.like_count }, void 0)] }), void 0), _jsx("div", __assign({ className: "col" }, { children: _jsx("img", { className: "invert-color cursor-pointer", src: Flag, width: 16, onClick: function () {
                                                            askToReport(el);
                                                        } }, void 0) }), void 0), Number(el.member_id) === Number(member.id) ?
                                                    _jsx("div", __assign({ className: "col" }, { children: _jsx("img", { src: Trash, width: 16, onClick: function () {
                                                                deleteMyComment(postCommentModal.post, el);
                                                            } }, void 0) }), void 0) : _jsx(_Fragment, {}, void 0)] }), void 0), _jsx("div", { className: "clearfix mb-2" }, void 0)] }), key));
                            }) : _jsx(_Fragment, { children: _jsx("p", __assign({ className: "px-3 py-5 text-center" }, { children: "Hen\u00FCz hi\u00E7 yorum yok!" }), void 0) }, void 0), postCommentModal.postComments && postCommentModal.postComments.length > 10 ? _jsx(_Fragment, { children: _jsx("a", __assign({ className: "d-block py-3 text-center fw-bold cursor-pointer", onClick: function () {
                                        page++;
                                        getPostComment(postCommentModal.post.post_id, page).then(function (comments) {
                                            inProgress.state = false;
                                            setPostCommentModal({ post: postCommentModal.post, postComments: postCommentModal.postComments.concat(comments) });
                                        });
                                    } }, { children: "Daha Fazlas\u0131n\u0131 Oku" }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0) }), void 0), postCommentModal && postCommentModal.post && postCommentModal.post.comment_perm ? _jsx(_Fragment, { children: _jsx("div", __assign({ className: "modal-footer eventComments" }, { children: _jsxs("form", __assign({ className: "w-100", onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "writeNow" }, { children: [_jsx("textarea", __assign({ id: "id_txtComment", placeholder: "Yorumun" }, register('comment', {
                                            required: true,
                                            minLength: 4,
                                            maxLength: 1500,
                                        })), void 0), _jsx("button", __assign({ className: "btn btn-warning" }, { children: "Payla\u015F" }), void 0)] }), void 0), _jsx("div", __assign({ className: "info" }, { children: "En az: 4 karakter, en fazla: 1500 karakter" }), void 0)] }), void 0) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0) }, void 0));
}
