var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { env } from "../../../definitions/global.vars";
import { ApiService } from "../../../lib/http.service";
import { Storage } from "../../../lib/localstorege.service";
import DefaultAvatar from "../../../assets/img/avatar.png";
import { useNavigate } from "react-router";
var Api = new ApiService();
var token = Storage.get('token');
export function SearchMember(props) {
    var navigate = useNavigate();
    var _a = useState([]), searchMember = _a[0], setSearchMember = _a[1];
    var _b = useState(''), keyword = _b[0], setKeyword = _b[1];
    var searchTimer;
    var setKeywordFilter = function (keyword) {
        setKeyword(keyword);
        clearTimeout(searchTimer);
        if (keyword.length > 1) {
            searchTimer = setTimeout(function () {
                var data = {
                    token: token,
                    q: keyword
                };
                Api.start('post', env.core_api + '/misc/api/get-member-info/', data, true).then(function (result) {
                    setSearchMember(result.data);
                });
            }, 500);
        }
        else {
            setSearchMember([]);
        }
    };
    var gotoProfile = function (member_id) {
        navigate("/sosyoley/profil/" + member_id + "/kuponlarim/");
        setSearchMember([]);
        setKeyword('');
    };
    var mobileStyleSearch = {
        position: "absolute",
        right: "35px", width: "30%"
    };
    var mobileStyleTab = {
        position: "absolute",
        right: "20px", top: "100px"
    };
    var webSearch = {};
    var webTab = {};
    var elem = document.getElementById('SosyoSearch');
    return (_jsxs(_Fragment, { children: [_jsx("input", { style: props.type == "mobile" ? mobileStyleSearch : webSearch, id: "SosyoSearch", value: keyword, placeholder: "Sosyoley'de ara", type: "text", autoComplete: "off", className: props.type == "mobile" ? "btn btn-small btn-outline-white text-left fw-normal mb-2 visible-sm" : "btn btn-small btn-outline-white text-left fw-normal mb-2 hidden-sm w-100", onChange: function (e) { return setKeywordFilter(e.target.value); } }, void 0), searchMember.length > 0 ? (_jsx("ul", __assign({ className: "SosyoSearch", id: "SearchList", style: props.type == "mobile" ? mobileStyleTab : webTab }, { children: searchMember.map(function (obj, key) {
                    return (_jsxs("li", __assign({ className: 'm-0' }, { children: [obj.photo ?
                                _jsx("img", { className: "avatar", src: "".concat(env.core_api).concat(obj.photo), width: 45 }, void 0) :
                                _jsx("img", { className: "avatar", src: DefaultAvatar, width: 45 }, void 0), _jsxs("a", __assign({ onClick: function () { gotoProfile(obj.member_id); }, className: "nickname" }, { children: ["@", obj.nick_name] }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }), key));
                }) }), void 0)) : null] }, void 0));
}
