export var env = {
    accounting_api: 'https://api.oley.com',
    list_api: 'https://api.oley.com',
    accounting_wss: 'wss://feed.oley.com/stream',
    core_api: 'https://api.oley.com',
    misc_api: 'https://api.oley.com',
    auth_api: 'https://api.oley.com',
    sosyoley_api: 'https://api.oley.com',
    sportsbook_api: 'https://sportsbook.oley.com',
    currentbook_api: 'https://currentbook.oley.com',
    sportsbook_wss: 'wss://sportsbook-ws.oley.com/stream/bulletin-v3/',
    stream_url: 'https://stream.oley.com',
    website_url: 'https://oley.com',
    affiliate_url: 'https://affiliate.oley.com',
    cdn_url: 'https://cdn.oley.com',
    scores: 'https://scores.oley.com',
    tjk_api: 'https://tjk.oley.com',
    tjk: false,
};
export var months = [
    { val: '01', name: 'Ocak' },
    { val: '02', name: 'Şubat' },
    { val: '03', name: 'Mart' },
    { val: '04', name: 'Nisan' },
    { val: '05', name: 'Mayıs' },
    { val: '06', name: 'Haziran' },
    { val: '07', name: 'Temmuz' },
    { val: '08', name: 'Ağustos' },
    { val: '09', name: 'Eylül' },
    { val: '10', name: 'Ekim' },
    { val: '11', name: 'Kasım' },
    { val: '12', name: 'Aralık' }
];
export var UnitLabel = {
    0: 'TL',
    1: 'OP',
};
export var TransactionTypeLabel = {
    0: 'Devir',
    1: 'Para yatırma',
    2: 'Para yatırma (İadesi)',
    3: 'Para çekme',
    4: 'Para çekme (iadesi)',
    5: 'İddaa Kupon oynama',
    6: 'İddaa Kupon iptali',
    7: 'İddaa Kupon kazanç',
    8: 'Spor Toto Kupon oynama',
    9: 'Spor Toto Kupon iptali',
    10: 'Spor Toto Kupon kazanç',
    11: 'Milli Piyango Satışı',
    12: 'Milli Piyango kazanç',
    13: 'Vergi Kesintisi',
    14: 'Çekim komisyonu',
    15: 'Banka işlem bedeli',
    16: 'Şans Girişim Ödemesi',
    17: 'İşletme Giderleri',
    18: 'Hesaplar Arası Virman',
    19: 'OleyPuan',
    20: 'Düzeltme',
    21: 'Süper Sayısal Loto oynama',
    22: 'Süper Şans Topu oynama',
    23: 'Süper On Numara oynama',
    24: 'Para Loto oynama',
    25: 'Süper Sayısal Loto iadesi',
    26: 'Süper Şans Topu iadesi',
    27: 'Süper On Numara iadesi',
    28: 'Para Loto iadesi',
    29: 'Süper Sayısal Loto kazanç',
    30: 'Süper Şans Topu kazanç',
    31: 'Süper On Numara kazanç',
    32: 'Para Loto kazanç',
    33: 'Süper Şans Oyunu kazanç',
    37: 'Eşya Piyangosu Oynama'
};
export var longDays = {
    0: 'Pazar',
    1: 'Pazartesi',
    2: 'Salı',
    3: 'Çarşamba',
    4: 'Perşembe',
    5: 'Cuma',
    6: 'Cumartesi',
};
export var shortDays = {
    0: 'Pzr',
    1: 'Pts',
    2: 'Sal',
    3: 'Çar',
    4: 'Per',
    5: 'Cum',
    6: 'Cmt',
};
export var warninLogStyle = 'color: black; background: yellow; font-size: 12px; font-weight:bold';
export var successLogStyle = 'color: white; background: green; font-size: 12px; font-weight:bold';
export var errorLogStyle = 'color: white; background: red; font-size: 12px; font-weight:bold';
