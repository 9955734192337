var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Storage } from "../../../../lib/localstorege.service";
import { GameOperationsApi } from "./game.operations.api";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TableWidget from "../../widgets/millipiyango/table.widget";
var page = 0;
var MilliPiyango = function (props) {
    var token = Storage.get('token');
    var _a = useState([]), iddiaData = _a[0], setIddiaData = _a[1];
    var _b = useState(1), iddiaStatus = _b[0], setIddiaStatus = _b[1];
    function setStatus(status) {
        if (iddiaStatus != status) {
            page = 0;
        }
        var data = {
            token: token,
            status: status,
            page: page,
            lottery_type: props.lottery_type
        };
        GameOperationsApi.milliPiyango(data).then(function (result) {
            if (result.status) {
                var data_1 = [];
                if (iddiaStatus != status) {
                    data_1 = [];
                }
                else {
                    data_1 = iddiaData;
                }
                setIddiaStatus(status);
                setIddiaData(data_1.concat(result.data));
            }
            else {
                alert(result.message);
            }
        });
    }
    useEffect(function () {
        setStatus(20);
    }, []);
    return (_jsxs("div", { children: [_jsx("hr", {}, void 0), _jsx("div", { children: _jsxs(Tabs, { children: [_jsxs(TabList, { children: [_jsx(Tab, __assign({ onClick: function () { return setStatus(20); } }, { children: "Devam Edenler" }), void 0), _jsx(Tab, __assign({ onClick: function () { return setStatus(23); } }, { children: "Kazananlar" }), void 0), _jsx(Tab, __assign({ onClick: function () { return setStatus(22); } }, { children: "Kaybedenler" }), void 0)] }, void 0), _jsx(TabPanel, { children: iddiaStatus == 20 ?
                                _jsx(TableWidget, { iddiaData: iddiaData }, void 0)
                                : null }, void 0), _jsx(TabPanel, { children: iddiaStatus == 23 ?
                                _jsx(TableWidget, { iddiaData: iddiaData }, void 0)
                                : null }, void 0), _jsx(TabPanel, { children: iddiaStatus == 22 ?
                                _jsx(TableWidget, { iddiaData: iddiaData }, void 0)
                                : null }, void 0)] }, void 0) }, void 0)] }, void 0));
};
export default MilliPiyango;
