var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { LoginApi } from "../login.api";
import Modal from 'react-modal';
import phoneIcon from "../../assets/feather/phone-call.svg";
import mailIcon from "../../assets/feather/mail.svg";
import { ServerDate } from "../../lib/misc.functions";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import EyeIcon from "../../assets/feather/eye.svg";
import ExitIcon from "../../assets/feather/x-circle.svg";
import { modalServiceSubject } from "../../components/modal/modal";
var years = [];
var days = [];
var months = [
    { val: '01', name: 'Ocak' },
    { val: '02', name: 'Şubat' },
    { val: '03', name: 'Mart' },
    { val: '04', name: 'Nisan' },
    { val: '05', name: 'Mayıs' },
    { val: '06', name: 'Haziran' },
    { val: '07', name: 'Temmuz' },
    { val: '08', name: 'Ağustos' },
    { val: '09', name: 'Eylül' },
    { val: '10', name: 'Ekim' },
    { val: '11', name: 'Kasım' },
    { val: '12', name: 'Aralık' }
];
var today = ServerDate();
var yy = today.getFullYear() - 18;
for (var i = yy; i >= (yy - 70); i--) {
    years.push(i);
}
for (var j = 1; j < 32; j++) {
    days.push(j < 10 ? ("0" + j) : j.toString());
}
var bDay = '';
export default function ForgetPassword(props) {
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var _b = useState(0), step = _b[0], setStep = _b[1];
    var _c = useState(null), memberData = _c[0], setMemberData = _c[1];
    var _d = useState(""), selectedType = _d[0], setSelectedType = _d[1];
    var _e = useState(""), verifyCode = _e[0], setVerifyCode = _e[1];
    var _f = useForm(), register = _f.register, handleSubmit = _f.handleSubmit, control = _f.control, getValues = _f.getValues, errors = _f.formState.errors;
    var onSubmit = function (data) {
        callChangePasswordApi(data);
    };
    var onSubmitNewPassword = function (data) {
        var payload = {
            'tc_id': memberData.tc_id,
            'verify_code': verifyCode,
            'type': 'set_password',
            'password': data.new_pswd,
            'password2': data.new_pswd_repeat
        };
        LoginApi.passwordReminder(payload).then(function (result) {
            if (result.status) {
                closeModal();
                modalServiceSubject.next({
                    title: "Başarılı!",
                    content: "Yeni şifreniz başarıyla tanımlandı. Güvenliğiniz için şifrenizi kimse ile paylaşmayınız.",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    var onCodeSubmit = function (data) {
        var payload = {
            'tc_id': memberData.tc_id,
            'type': 'validate',
            'verify_code': data.code,
            'email': selectedType
        };
        LoginApi.passwordReminder(payload).then(function (result) {
            if (result.status) {
                setStep(3);
                setVerifyCode(data.code);
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    var togglePassword = function (id) {
        var element = document.getElementById(id);
        var elementInput = document.getElementById(id + "Input");
        if (element.src.includes("eye-off")) {
            element.src = EyeIcon;
            elementInput.type = "text";
        }
        else {
            element.src = EyeOffIcon;
            elementInput.type = "password";
        }
    };
    var callChangePasswordApi = function (data) {
        var payload = {};
        bDay = data.birthday_year + "-" + data.birthday_month + "-" + data.birthday_day;
        payload.birthday = bDay;
        payload.tc_id = data.username;
        payload.type = "get_member_data";
        LoginApi.passwordReminder(payload).then(function (result) {
            if (result.status) {
                setStep(1);
                setMemberData(result.data);
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
            // if (result.status) {
            //     modalServiceSubject.next({
            //         title: "Başarılı!",
            //         content: "Parolanızı sıfırlama linki kayıtlı e-posta adresinize ve telefon numaranıza gönderilmiştir.",
            //         confirm: {
            //             cancel: {label: 'Tamam'}
            //         },
            //         timeout: 0
            //     })
            // } else {
            //     modalServiceSubject.next({
            //         title: "Uyarı!",
            //         content: "Kullanıcı kaydı bulunamadı.",
            //         confirm: {
            //             cancel: {label: 'Tamam'}
            //         },
            //         timeout: 0
            //     })
            // }
        });
    };
    var sendCode = function (type) {
        var payload = {
            'tc_id': memberData.tc_id,
            'selected_type': type,
            'birthday': bDay,
            'type': 'check_data'
        };
        LoginApi.passwordReminder(payload).then(function (result) {
            if (result.status) {
                setStep(2);
                setSelectedType(type);
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    function closeModal() {
        setIsModalOpen(false);
    }
    function openModal() {
        setIsModalOpen(true);
    }
    return (_jsxs("div", __assign({ style: { marginTop: props.page == "footer" ? '0' : '-55px' }, id: 'forgotPassword' }, { children: [props.page == "footer" ?
                (_jsx("li", __assign({ className: "link", onClick: openModal, style: { cursor: "pointer" } }, { children: _jsx("a", __assign({ href: "#" }, { children: "\u015Eifremi Unuttum" }), void 0) }), void 0)) : (_jsx("div", __assign({ className: "text-secondary cursor-pointer py-2" }, { children: _jsx("span", __assign({ className: "float-right", onClick: openModal }, { children: "\u015Eifremi unuttum ? " }), void 0) }), void 0)), _jsxs(Modal, __assign({ isOpen: isModalOpen, onRequestClose: closeModal, style: {
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        background: 'var(--color-bg-white)',
                        marginRight: '-50%',
                        width: "450px",
                        maxWidth: ' calc( 100% - 40px )',
                        padding: '15px',
                        transform: 'translate(-50%, -50%)',
                    },
                }, contentLabel: "" }, { children: [_jsx("div", __assign({ className: "modal-header" }, { children: _jsxs("div", __assign({ className: "title" }, { children: [_jsx("div", __assign({ className: "float-right" }, { children: _jsx("img", { src: ExitIcon, width: 24, className: "float-right cursor-p invert-color", onClick: closeModal }, void 0) }), void 0), _jsx("h3", { children: "\u015Eifremi Unuttum" }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "modal-body" }, { children: _jsx("div", { children: _jsxs("div", { children: [_jsxs("label", { children: [step == 0 && "Şifre hatırlatma için lütfen sistem kayıtlı T.C. kimlik numaranızı giriniz.", step == 1 && "Sizin için sunulan bir doğrulama yöntemini seçerek şifre sıfırlama işleminize devam edebilirsiniz.", step == 2 && "Gönderilen doğrulama kodunu giriniz.", step == 3 && "Maddi zarara uğramamak ve kişisel verilerilerinizin başkasının eline geçmemesi için doğum tarihi vb. içermeyen, tahmin edilemez bir şifre seçiniz."] }, void 0), step == 0 && _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", { children: [_jsx("label", { children: "T.C. Kimlik Numaran\u0131z" }, void 0), _jsx("input", __assign({ className: "form-control" }, register('username', {
                                                        required: true,
                                                        minLength: 11,
                                                    })), void 0), errors.username && errors.username.type === "required" &&
                                                        _jsx("span", __assign({ className: "text-validation" }, { children: "TC No gereklidir." }), void 0), errors.username && errors.username.type === "minLength" &&
                                                        _jsx("span", __assign({ className: "text-validation" }, { children: "TC No hatal\u0131" }), void 0)] }, void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "Do\u011Fum Tarihi" }, void 0), _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col" }, { children: [_jsx("label", { children: "G\u00FCn" }, void 0), _jsxs("select", __assign({}, register('birthday_day', { required: true }), { defaultValue: 'DEFAULT' }, { children: [_jsx("option", __assign({ value: 'DEFAULT', disabled: true }, { children: "Se\u00E7" }), void 0), days.map(function (x) {
                                                                                return (_jsx("option", __assign({ value: x }, { children: x }), x));
                                                                            })] }), void 0), errors.birthday_day && errors.birthday_day.type === "required" &&
                                                                        _jsx("span", __assign({ className: "text-validation" }, { children: "Do\u011Fum tarihi gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col" }, { children: [_jsx("label", { children: "Ay" }, void 0), _jsxs("select", __assign({}, register('birthday_month', { required: true }), { defaultValue: 'DEFAULT' }, { children: [_jsx("option", __assign({ value: 'DEFAULT', disabled: true }, { children: "Se\u00E7" }), void 0), months.map(function (x) { return _jsx("option", __assign({ value: x.val }, { children: x.name }), x.val); })] }), void 0), errors.birthday_month && errors.birthday_month.type === "required" &&
                                                                        _jsx("span", __assign({ className: "text-validation" }, { children: "Do\u011Fum tarihi gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col" }, { children: [_jsx("label", { children: "Y\u0131l" }, void 0), _jsxs("select", __assign({}, register('birthday_year', { required: true }), { defaultValue: 'DEFAULT' }, { children: [_jsx("option", __assign({ value: 'DEFAULT', disabled: true }, { children: "Se\u00E7" }), void 0), years.map(function (x) { return _jsx("option", __assign({ value: x }, { children: x }), x); })] }), void 0), errors.birthday_year && errors.birthday_year.type === "required" &&
                                                                        _jsx("span", __assign({ className: "text-validation" }, { children: "Do\u011Fum tarihi gereklidir." }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsx("input", { className: "btn btn-primary float-right mt-3", type: "submit" }, void 0)] }), void 0), step == 1 && _jsxs(_Fragment, { children: [memberData && memberData.mobile && _jsx("div", __assign({ className: "card choice-card", onClick: function () {
                                                    sendCode('mobile');
                                                } }, { children: _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("div", __assign({ className: "choice-icon" }, { children: _jsx("img", { width: 24, src: phoneIcon }, void 0) }), void 0), _jsxs("div", { children: [_jsx("p", __assign({ className: "mb-0" }, { children: "SMS Do\u011Frulama" }), void 0), _jsx("p", __assign({ className: "mb-0" }, { children: "S\u0131f\u0131rlama kodunu SMS ile g\u00F6nder" }), void 0), _jsx("p", __assign({ className: "mb-0 text-info" }, { children: memberData.mobile }), void 0)] }, void 0)] }), void 0) }), void 0) }), void 0), memberData && memberData.email &&
                                                _jsx("div", __assign({ className: "card choice-card mt-2", onClick: function () {
                                                        sendCode('email');
                                                    } }, { children: _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("div", __assign({ className: "choice-icon" }, { children: _jsx("img", { width: 24, src: mailIcon }, void 0) }), void 0), _jsxs("div", { children: [_jsx("p", __assign({ className: "mb-0" }, { children: "Email" }), void 0), _jsx("p", __assign({ className: "mb-0" }, { children: "S\u0131f\u0131rlama ba\u011Flant\u0131s\u0131n\u0131 email g\u00F6nder" }), void 0), _jsx("p", __assign({ className: "mb-0 text-info" }, { children: memberData.email }), void 0)] }, void 0)] }), void 0) }), void 0) }), void 0), _jsx("label", { children: "Sistemde tan\u0131ml\u0131 ve do\u011Frulanm\u0131\u015F bir cep numaras\u0131 yada email adresiniz yoksa i\u015Fleminize devam edemezsiniz. Bu durumda l\u00FCtfen m\u00FC\u015Fteri temsilciniz ile ileti\u015Fime ge\u00E7iniz." }, void 0)] }, void 0), step == 2 && _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "card-body p-0" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onCodeSubmit) }, { children: [_jsxs("div", { children: [_jsx("input", __assign({ placeholder: "Doğrulama Kodu" }, register('code', { required: true }), { className: "form-control" }), void 0), errors.code && errors.code.type === "required" &&
                                                                    _jsx("span", __assign({ className: "text-validation" }, { children: "L\u00FCtfen do\u011Frulama kodu giriniz." }), void 0)] }, void 0), _jsx("input", { className: "btn btn-primary float-right mt-3", type: "submit", value: "DO\u011ERULA" }, void 0)] }), void 0) }), void 0), _jsx("div", { children: _jsx(CountDown, { timer: 240, memberData: memberData, type: selectedType }, void 0) }, void 0)] }, void 0), step == 3 && _jsx(_Fragment, { children: _jsx("div", __assign({ className: "card-body p-0" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmitNewPassword) }, { children: [_jsxs("div", __assign({ className: "mt-1" }, { children: [_jsx("label", { children: "Yeni \u015Eifre" }, void 0), _jsx(Controller, { name: "new_pswd", control: control, rules: {
                                                                    required: "Şifre belirlemelisiniz",
                                                                }, render: function () { return _jsx("input", __assign({ id: "newPasswordInput" }, register('new_pswd', {
                                                                    required: true,
                                                                    minLength: 6,
                                                                }), { name: "new_pswd", type: "password", className: "form-control" }), void 0); } }, void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2", onClick: function () { return togglePassword("newPassword"); } }, { children: _jsx("img", { id: "newPassword", className: "eye-icon", src: EyeOffIcon, height: 16, width: 16 }, void 0) }), void 0), errors.new_pswd && errors.new_pswd.type === "required" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre gereklidir." }), void 0), errors.new_pswd && errors.new_pswd.type === "minLength" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre \u00E7ok k\u0131sa!" }), void 0), errors.new_pswd && _jsx("p", { children: errors.new_pswd.message }, void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-1" }, { children: [_jsx("label", { children: "Yeni \u015Eifre Tekrar\u0131" }, void 0), _jsx(Controller, { name: "new_pswd_repeat", control: control, rules: {
                                                                    required: "Şifre belirlemelisiniz",
                                                                    //@ts-ignore
                                                                    validate: function (value) {
                                                                        if (value === getValues()["new_pswd"]) {
                                                                            return true;
                                                                        }
                                                                        else {
                                                                            return (_jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre tekrar\u0131, \u015Fifre ile e\u015Fle\u015Fmelidir." }), void 0));
                                                                        }
                                                                    }
                                                                }, render: function () { return _jsx("input", __assign({ id: "newPassword2Input" }, register('new_pswd_repeat', { required: true }), { name: "new_pswd_repeat", type: "password", className: "form-control" }), void 0); } }, void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2 position-absolute", onClick: function () { return togglePassword("newPassword2"); } }, { children: _jsx("img", { id: "newPassword2", className: "eye-icon", src: EyeOffIcon, height: 16, width: 16 }, void 0) }), void 0), errors.new_pswd_repeat && errors.new_pswd_repeat.type === "required" &&
                                                                _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre tekrar\u0131 gereklidir." }), void 0), errors.new_pswd_repeat && _jsxs("p", { children: [" ", errors.new_pswd_repeat.message] }, void 0)] }), void 0), _jsxs("ul", __assign({ className: "mt-2" }, { children: [_jsx("li", { children: "\u015Eifreniz En az 6 karakter, rakam ve harf i\u00E7ermelidir" }, void 0), _jsx("li", { children: "Do\u011Fum y\u0131l\u0131n\u0131z\u0131 i\u00E7ermemelidir" }, void 0), _jsx("li", { children: "Ard\u0131\u015F\u0131k 3 say\u0131 veya harf i\u00E7ermemelidir" }, void 0)] }), void 0), _jsx("input", { className: "btn btn-primary float-right mt-3", type: "submit", value: "G\u00FCncelle" }, void 0)] }), void 0) }), void 0) }, void 0)] }, void 0) }, void 0) }), void 0)] }), void 0)] }), void 0));
}
function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    var obj = {
        "h": String(hours).padStart(2, '0'),
        "m": String(minutes).padStart(2, '0'),
        "s": String(seconds).padStart(2, '0')
    };
    return obj;
}
var timerRegisterCountDown;
export function CountDown(props) {
    var timer = props.timer;
    var memberData = props.memberData;
    var type = props.type;
    var _a = useState(timer), timeLeft = _a[0], setTimeLeft = _a[1];
    useEffect(function () {
        if (timeLeft > 0) {
            timerRegisterCountDown = setTimeout(function () {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        }
        return function () {
            clearTimeout(timerRegisterCountDown);
        };
    }, [timeLeft]);
    if (timeLeft <= 0) {
        return (_jsxs(_Fragment, { children: ["S\u00FCre doldu! tekrar do\u011Frulama kodu g\u00F6ndermeniz gerekiyor. ", _jsx("a", __assign({ onClick: function () {
                        LoginApi.passwordReminder({
                            type: 're_send',
                            'email': type,
                            'tc_id': memberData.tc_id,
                        }).then(function () {
                            setTimeLeft(timer);
                        });
                    }, style: { cursor: 'pointer', fontWeight: 'bold', color: 'var(--color-danger)' } }, { children: "Tekrar G\u00F6nder" }), void 0), " "] }, void 0));
    }
    return (_jsx(_Fragment, { children: _jsxs("span", { children: [_jsxs("b", { children: [secondsToTime(timeLeft).m, ":", secondsToTime(timeLeft).s] }, void 0), " saniye sonra yeniden kod g\u00F6nderebilirsiniz."] }, void 0) }, void 0));
}
