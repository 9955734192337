export default {
    HOME: "/",
    LOGIN: "giris-yap",
    SPORTSBOOK: "iddaa",
    RESETPASSWORD: "reset-password",
    REGISTER: "kayit",
    ACCOUNT: "hesabim",
    HELP: "yardim",
    CAMPAIGNS: "kampanyalar",
    SPORTOTO: "spor-toto",
    LIVESCORES: "canli-skorlar",
    LIVESCORES_DETAIL: "canli-skor-detay",
    ABOUTUS: "hakkimizda",
    CONTACT: "iletisim",
    KVKK_AGREEMENT: "kisisel-verilerin-korunmasi",
    ANNOUNCEMENTS: "duyurular",
    MEMBERSHIP_AGREEMENT: "uyelik-sozlesmesi",
    PERSONAL_DATA_REFERENCES: "kisisel-veri-basvurulari",
    SPONSORSHIPS: "sponsorluklarimiz",
    MOBILE_PLATFORM: "mobil-platform",
    CONTACTUS: "bize-yazin",
    SOSYOLEY: "sosyoley",
    NOTIFICATIONS: "bildirimler",
    COOKIES: "cerez-politikamiz",
    TJK: "at-yarisi",
    COMPETITION_TERMS: 'yarisma-sartnamesi',
    KATAR2022: 'katar-2022',
    LOTTERY: 'esya-piyangosu',
    SCRATCH: 'oyna-kazan',
    ADDTOCART: 'addtobetslip',
    RESPONSIBLE_PLAY: 'sorumlu-bahis',
    FLATPAGES: 'cms',
    SUPERWHELL: 'carkifelek'
};
