var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { Link } from "react-router-dom";
var NotFound = function () {
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    window.location.href = '/';
    return (_jsx("main", __assign({ style: { padding: '1rem' }, className: "page d-flex justify-content-center" }, { children: _jsxs("div", __assign({ className: "card w-50" }, { children: [_jsx("div", __assign({ className: "card-title d-flex justify-content-center my-1" }, { children: _jsx("h2", { children: "404 Not Found" }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("div", __assign({ className: "row d-flex justify-content-center my-1" }, { children: "Arad\u0131\u011F\u0131n\u0131z sayfa bulunamad\u0131." }), void 0), _jsx("div", __assign({ className: "row d-flex justify-content-center my-3" }, { children: _jsx("button", __assign({ className: "btn btn-outline-primary" }, { children: _jsx(Link, __assign({ to: "/" }, { children: "Ana Sayfa" }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default NotFound;
