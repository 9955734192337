var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { eventSliderList } from "../../../store/sportsbook.store";
import MatchCard from "../components/match.card";
import { useForceUpdate } from "../../../lib/misc.functions";
import Carousel from "react-elastic-carousel";
import { Subject } from "rxjs";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
export var eventSliderSubject = new Subject();
export default function Slider(props) {
    var sliderType = props.sliderType;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var eventSliderSubject_subscription = eventSliderSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            eventSliderSubject_subscription.unsubscribe();
        };
    }, []);
    var breakPoints = [
        { width: 1, itemsToShow: Number(sliderType) === 0 ? 1 : 2 },
        { width: 520, itemsToShow: Number(sliderType) === 0 ? 1 : 2 },
        { width: 750, itemsToShow: Number(sliderType) === 0 ? 1 : 3 },
        { width: 1200, itemsToShow: Number(sliderType) === 0 ? 1 : 6 },
    ];
    var sliderList = eventSliderList.filter(function (el) { return Number(el.slideType) === Number(sliderType); });
    if (Number(sliderType) === 0) {
        return (_jsx("div", __assign({ className: "slider" }, { children: _jsx(Carousel, __assign({ autoPlaySpeed: 5000, isRTL: false, enableAutoPlay: true, breakPoints: breakPoints, pagination: true, showArrows: false }, { children: sliderList.map(function (item, key) {
                    // let hrefUrl:any = null;
                    // let reg = /link="(.*?)"/g;
                    // let urls = [...item.content.matchAll(reg)];
                    // if(urls.length > 0){
                    //   hrefUrl = urls[0][1];
                    // }
                    var srcUrl = null;
                    if (String(item.content).includes('link')) {
                        srcUrl = item.content.split('link')[2].replace(/"/g, "'").split("'")[1];
                    }
                    if (srcUrl) {
                        return (_jsx("div", { children: _jsx(Link, __assign({ style: { display: "block" }, className: "slide col-sm-12", to: srcUrl }, { children: _jsx("div", { className: "item", dangerouslySetInnerHTML: { __html: item.content } }, void 0) }), void 0) }, key));
                    }
                    else {
                        return (_jsx("div", { children: _jsx("div", __assign({ className: "slide col-sm-12" }, { children: _jsx("div", { className: "item", dangerouslySetInnerHTML: { __html: item.content } }, void 0) }), void 0) }, key));
                    }
                }) }), void 0) }), void 0));
    }
    return (_jsx("div", __assign({ className: "slider" }, { children: _jsxs(Carousel, __assign({ autoPlaySpeed: 7000, isRTL: false, enableAutoPlay: true, breakPoints: breakPoints, pagination: false, showArrows: false }, { children: [_jsx("div", __assign({ className: "slide col-sm-12" }, { children: _jsx("div", __assign({ className: "item cursor-pointer", onClick: function () { navigate('/oyna-kazan'); } }, { children: _jsx("img", { className: 'matchsliderimg', src: "/assets/images/oynakazan-banner.png" }, void 0) }), void 0) }), void 0), sliderList.map(function (item, key) {
                    return (_jsx(MatchCard, { slide: item }, "MatchCard".concat(key)));
                })] }), void 0) }), void 0));
}
