var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Mobil from '../../../assets/img/mobil.webp';
var MobileWeb = function () {
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-9" }, { children: [_jsx("h5", { children: " Mobil Site Nas\u0131l Kullan\u0131l\u0131r?" }, void 0), _jsx("p", { children: "Oley.com'u t\u00FCm ak\u0131ll\u0131 cep telefonlar\u0131yla rahatl\u0131kla kullanabilirsiniz." }, void 0), _jsx("hr", {}, void 0), _jsxs("p", { children: ["1. Cep telefonunuzda kulland\u0131\u011F\u0131n\u0131z internet taray\u0131c\u0131n\u0131za (Chrome, Safari, Firefox, Opera vb.) ", _jsx("strong", { children: "https://oley.com" }, void 0), " yaz\u0131p sayfaya gitti\u011Finizde a\u00E7\u0131lan sayfa oley.com sitesidir ve t\u00FCm mobil cihazlarla uyumlu bir \u015Fekilde \u00E7al\u0131\u015Fmaktad\u0131r."] }, void 0), _jsxs("p", { children: ["2. Cep telefonunuzda mobil siteyi a\u00E7t\u0131ktan sonra ", _jsx("strong", { children: "https://oley.com" }, void 0), "'u diledi\u011Finiz gibi kullan\u0131p, kuponlar\u0131n\u0131z\u0131 yapabilir, yapt\u0131\u011F\u0131n\u0131z kupondaki ma\u00E7lar\u0131 takip edebilir veya ", _jsx("strong", { children: "Sosyoley" }, void 0), "'de kuponlar\u0131n\u0131z\u0131 payla\u015Fabilirsiniz."] }, void 0)] }), void 0), _jsx("div", __assign({ className: "col-3" }, { children: _jsx("img", { className: "w-100", src: Mobil }, void 0) }), void 0), _jsx("hr", {}, void 0)] }), void 0) }, void 0));
};
export default MobileWeb;
