var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { availableHippodromes, availableRuns, tjkBetSlipActionSubject, tjkBetSlipOptions, tjkOrderedBetTypes, tjkSingleLegBetTypes, tjkSortedBetTypes } from "./tjk.store";
import { tjkBetslipStore } from "./components/tjk.betslip";
import { modalServiceSubject } from "../../components/modal/modal";
import { ApiService } from "../../lib/http.service";
import { Storage } from "../../lib/localstorege.service";
import { isAndroid, isIOS, isMacOs, isMobile, isWindows } from "react-device-detect";
import { env } from "../../definitions/global.vars";
import _ from "lodash";
export var updateBetCount = { status: true };
var api = new ApiService();
export function addToTJKBetSlip(column, run, horse, action) {
    if (action === void 0) { action = 'toggle'; }
    console.log(column, run.no, horse.no);
    if (env.tjk === false)
        return;
    updateBetCount.status = true;
    if (tjkBetSlipOptions.inProgress === true) {
        modalServiceSubject.next({
            title: 'Uyarı',
            content: 'Bahisleriniz işleniyor. Lütfen bekleyiniz.',
            timeout: 3000,
            confirm: {
                cancel: {
                    label: 'Tamam',
                }
            }
        });
        return;
    }
    if (horse.run_status === false)
        return;
    var horseIndex = isAdded(column, run, horse);
    var subjectAction = '';
    if (horseIndex === false && (action === 'toggle' || action === 'add')) {
        tjkBetslipStore.push({
            hippodrome: tjkBetSlipOptions.hippodrome,
            bettingType: tjkBetSlipOptions.bettingType,
            bettingSubType: tjkBetSlipOptions.bettingSubType,
            column: column,
            run: run,
            horse: horse,
        });
        subjectAction = 'add';
    }
    else if (horseIndex !== false && (action === 'toggle' || action === 'remove')) {
        subjectAction = 'remove';
        tjkBetslipStore.splice(horseIndex, 1);
    }
    if (action === 'toggle') {
        // console.log(tjkBetslipStore);
        tjkBetSlipActionSubject.next({ action: subjectAction, column: column, run: run, horse: horse });
    }
}
export function isAdded(column, run, horse) {
    // console.log(column, run.no , horse.no)
    var horseIndex = tjkBetslipStore.findIndex(function (el) { return el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
        el.horse.no === horse.no &&
        el.column === column &&
        el.run.no === run.no &&
        el.bettingType === tjkBetSlipOptions.bettingType &&
        el.bettingSubType === tjkBetSlipOptions.bettingSubType; });
    // console.log("horseIndex",horseIndex, horse.no)
    return horseIndex === -1 ? false : horseIndex;
}
export function isAddedAll(column, run) {
    var status = tjkBetslipStore.filter(function (el) {
        return el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
            el.bettingType === tjkBetSlipOptions.bettingType &&
            el.bettingSubType === tjkBetSlipOptions.bettingSubType &&
            el.column === column &&
            el.run.no === run.no;
    }).length === run.horses.filter(function (h) { return h.run_status === true; }).length ? true : false;
    return status;
}
export function setTjkBetSlipMultiply(multiply) {
    // if(multiply!=='') {
    //   let validValue = Number(multiply) > 0 ? Number(multiply) : 1;
    //   const betCount = getbetCount();
    //   const totalAmount = validValue * betCount * tjkBetSlipOptions.bettingType.price;
    //
    //   if (totalAmount > 5000) {
    //     validValue = Math.floor(5000 / betCount)
    //     validValue = validValue > 0 ? validValue : multiply;
    //     tjkBetSlipOptions.errorMessage = 'Toplam bahis tutarı 5000 TL\'yi geçemez.';
    //     tjkBetSlipOptions.betSlipMultiply = validValue
    //   }else{
    //     validValue = multiply;
    //     tjkBetSlipOptions.betSlipMultiply = multiply
    //     tjkBetSlipOptions.errorMessage = null;
    //   }
    //
    // }else{
    //   tjkBetSlipOptions.betSlipMultiply = ''
    // }
    tjkBetSlipOptions.errorMessage = null;
    tjkBetSlipOptions.betSlipMultiply = multiply;
    tjkBetSlipActionSubject.next();
}
export function setTjkBetSlipMaxAmount(amount) {
    updateBetCount.status = true;
    if (amount !== '') {
        tjkBetSlipOptions.maxAmount = Number(amount) > 0 ? Number(amount) : 1;
    }
    else {
        tjkBetSlipOptions.maxAmount = '';
    }
    tjkBetSlipActionSubject.next();
}
export function compareUpdatesAndHorses() {
    // for(let hippodrome of DailyRaces){
    //
    //   for(let run of hippodrome.runs){
    //
    //     for(let horse of run.horses){
    //
    //       tjkBetslipStore.filter()
    //
    //     }
    //
    //   }
    //
    // }
    // closed hippodromes
    var mustRemoveHippodromes = tjkBetslipStore.filter(function (s) { return !availableHippodromes.map(function (h) { return h.id; }).includes(s.hippodrome.id); });
    removeItemsFromCart(mustRemoveHippodromes);
    // closed runs
    var mustRemoveRuns = tjkBetslipStore.filter(function (s) { return s.hippodrome.id === tjkBetSlipOptions.hippodrome.id && !availableRuns.map(function (h) { return h.no; }).includes(s.run.no); });
    removeItemsFromCart(mustRemoveRuns);
    // Kosmaz at
    var mustRemoveHorse = tjkBetslipStore.filter(function (s) {
        return s.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
            s.horse.run_status === false;
    });
    removeItemsFromCart(mustRemoveHorse);
    tjkBetSlipActionSubject.next();
}
function removeItemsFromCart(mustRemoveItem) {
    var _loop_1 = function (item) {
        tjkBetslipStore.splice(tjkBetslipStore.findIndex(function (i) {
            return i.hippodrome.id == item.hippodrome.id &&
                i.bettingType.id == item.bettingType.id &&
                i.bettingSubType == item.bettingSubType &&
                i.column == item.column;
        }), 1);
    };
    for (var _i = 0, mustRemoveItem_1 = mustRemoveItem; _i < mustRemoveItem_1.length; _i++) {
        var item = mustRemoveItem_1[_i];
        _loop_1(item);
    }
}
export function setTjkUsePointBalance(value) {
    if (tjkBetSlipOptions.errorMessage === 'Bakiyeniz yetersiz.') {
        tjkBetSlipOptions.errorMessage = null;
    }
    tjkBetSlipOptions.usePointBalance = value;
    tjkBetSlipActionSubject.next();
}
export function setTjkComplete(value) {
    tjkBetSlipOptions.complete = value;
    updateBetCount.status = true;
    tjkBetSlipActionSubject.next();
}
export function getCouponLegs() {
    // fixme gereksiz iki defa çalışıyor
    var slipItem = _.orderBy(tjkBetslipStore.filter(function (el) {
        if (el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
            el.bettingType.id === tjkBetSlipOptions.bettingType.id &&
            el.bettingSubType === tjkBetSlipOptions.bettingSubType) {
            return el;
        }
    }), ['column']);
    var uniqueColumn = __spreadArray([], Array.from(new Set(slipItem.map(function (i) { return i.column; }))), true);
    var legs = [];
    var _loop_2 = function (c) {
        var cItems = slipItem.filter(function (i) { return i.column === c; }).map(function (el) { return el.horse !== 's' ? el.horse.no : 's'; });
        legs.push(cItems);
    };
    for (var _i = 0, uniqueColumn_1 = uniqueColumn; _i < uniqueColumn_1.length; _i++) {
        var c = uniqueColumn_1[_i];
        _loop_2(c);
    }
    return legs;
}
var Combinatorics = require('js-combinatorics/combinatorics.js');
function getC(ix, sysCols) {
    var cR = 1;
    for (var _i = 0, sysCols_1 = sysCols; _i < sysCols_1.length; _i++) {
        var c = sysCols_1[_i];
        cR = 1;
        for (var _b = 0, _c = _.range(Number(c.s), Number(c.h) + 1); _b < _c.length; _b++) {
            var j = _c[_b];
            var bs = ix * j;
            if ((bs * tjkBetSlipOptions.bettingType.price) <= Number(tjkBetSlipOptions.maxAmount)) {
                // console.log(j, (bs * tjkBetSlipOptions.bettingType.price))
                cR = j;
            }
        }
        ix *= cR;
    }
    return ix;
}
function getCombinations(chars, n) {
    if (n === void 0) { n = 3; }
    var result = [];
    var f = function (prefix, chars) {
        for (var i = 0; i < chars.length; i++) {
            if (prefix.length === (n - 1)) {
                result.push(prefix.concat(chars[i]));
            }
            f(prefix.concat(chars[i]), chars.slice(i + 1));
        }
    };
    f([], chars);
    return result;
}
function choose(arr, k, prefix) {
    if (prefix === void 0) { prefix = []; }
    if (k == 0)
        return [prefix];
    return arr.flatMap(function (v, i) {
        return choose(arr.slice(i + 1), k - 1, __spreadArray(__spreadArray([], prefix, true), [v], false));
    });
}
export function getbetCount2() {
    console.log("CALC BET COUNT");
    var columnCount = 1;
    var slipItem = tjkBetslipStore.filter(function (el) {
        if (el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
            el.bettingType.id === tjkBetSlipOptions.bettingType.id &&
            el.bettingSubType === tjkBetSlipOptions.bettingSubType) {
            return el;
        }
    });
    var uniqueColumn = __spreadArray([], Array.from(new Set(slipItem.map(function (i) { return i.column; }))), true);
    var minBetCount = 1;
    var minAvailableBetCount = 1;
    var maxAvailableBetCount = 1;
    var bankoCols = [];
    var allCols = [];
    var sysCols = [];
    var _loop_3 = function (c) {
        var cItems = slipItem.filter(function (i) { return i.column === c; }).map(function (el) { return (el.horse === 's' ? 's' : el.horse.no); });
        if (cItems.indexOf('s') !== -1) {
            var horseCount = slipItem.filter(function (i) { return i.column === c; })[0].run.horses.filter(function (h) { return h.run_status === true; }).length;
            columnCount *= horseCount;
            minBetCount *= cItems.length;
            allCols.push({ c: c, s: cItems.length - 1 });
            sysCols.push({ c: c, s: cItems.length - 1, h: horseCount });
        }
        else {
            columnCount *= cItems.length;
            minBetCount *= cItems.length;
            allCols.push({ c: c, s: cItems.length });
            bankoCols.push({ c: c, s: cItems.length });
        }
    };
    for (var _i = 0, uniqueColumn_2 = uniqueColumn; _i < uniqueColumn_2.length; _i++) {
        var c = uniqueColumn_2[_i];
        _loop_3(c);
    }
    // console.log("getbetCount::::::")
    if (tjkBetSlipOptions.bettingType && tjkBetSlipOptions.bettingType.column === uniqueColumn.length) {
        var ix = 1;
        for (var _b = 0, bankoCols_1 = bankoCols; _b < bankoCols_1.length; _b++) {
            var c = bankoCols_1[_b];
            ix *= c.s;
        }
        minAvailableBetCount = getC(ix, sysCols);
        maxAvailableBetCount = getC(ix, sysCols.reverse());
        var betCounts = [minAvailableBetCount, maxAvailableBetCount].sort();
        if (sysCols.length > 0) {
            tjkBetSlipOptions.system = true;
            tjkBetSlipOptions.systemMaxMin = betCounts;
            columnCount = maxAvailableBetCount;
        }
        else {
            tjkBetSlipOptions.system = false;
            tjkBetSlipOptions.systemMaxMin = null;
        }
    }
    // console.log("1columnCount>>>>>",columnCount)
    // sıralı ikili combinasyon hesabı
    if (tjkBetSlipOptions.bettingType && (tjkBetSlipOptions.bettingType.id === 56 || tjkOrderedBetTypes.includes(tjkBetSlipOptions.bettingType.id) || tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id))) {
        console.log("uniqueColumn>>", uniqueColumn);
        var legs_1 = [];
        var _loop_4 = function (c) {
            var cItems = slipItem.filter(function (i) { return i.column === c; }).map(function (el) { return el.horse.no; });
            legs_1.push(cItems);
        };
        for (var _c = 0, uniqueColumn_3 = uniqueColumn; _c < uniqueColumn_3.length; _c++) {
            var c = uniqueColumn_3[_c];
            _loop_4(c);
        }
        if (tjkBetSlipOptions.bettingType.id === 50 && tjkBetSlipOptions.complete === true && legs_1.length > 0) {
            legs_1.push(legs_1[0]);
        }
        if (tjkBetSlipOptions.bettingType.id === 53 && tjkBetSlipOptions.complete === true && legs_1.length > 0) {
            legs_1.push(legs_1[0]);
            legs_1.push(legs_1[0]);
        }
        if (tjkBetSlipOptions.bettingType.id === 56 && tjkBetSlipOptions.complete === true && legs_1.length > 0) {
            legs_1.push(legs_1[0]);
            legs_1.push(legs_1[0]);
            legs_1.push(legs_1[0]);
        }
        if (tjkBetSlipOptions.bettingType.id === 63 && tjkBetSlipOptions.complete === true && legs_1.length > 0) {
            legs_1.push(legs_1[0]);
            legs_1.push(legs_1[0]);
            legs_1.push(legs_1[0]);
            legs_1.push(legs_1[0]);
        }
        if (legs_1.length === 2) {
            var combinations_1 = legs_1[0].flatMap(function (d) { return legs_1[1].map(function (v) {
                if (d !== v) {
                    return d + '-' + v;
                }
                return null;
            }); }).filter(function (e) { return e !== null; });
            columnCount = combinations_1.length;
            // ikili kombinasyonlarda aynı kombinasyonun tersi varsa çıkar
            if (tjkBetSlipOptions.bettingType.id === 10 || tjkBetSlipOptions.bettingType.id === 47 || tjkBetSlipOptions.bettingType.id === 50) {
                columnCount -= combinations_1.filter(function (e) { return combinations_1.includes(e.split('-')[1] + '-' + e.split('-')[0]); }).length / 2;
            }
            if (tjkBetSlipOptions.bettingType.id !== 50 && tjkBetSlipOptions.complete === true) {
                columnCount *= 2;
            }
        }
        else if (legs_1.length === 3) {
            var permu = [[0, 1, 2]];
            if (tjkBetSlipOptions.complete === true) {
                var pers = [];
                var tmp = Combinatorics.permutation([0, 1, 2], 3);
                var _a = tmp.next();
                while (_a) {
                    pers.push(_a);
                    _a = tmp.next();
                }
                permu = pers;
            }
            var allCombinations = [];
            var _loop_5 = function (_r) {
                var combinations = legs_1[_r[0]].flatMap(function (d) { return legs_1[_r[1]].map(function (v) {
                    if (d !== v) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).filter(function (e) { return e !== null; });
                combinations = combinations.flatMap(function (d) { return legs_1[_r[2]].map(function (v) {
                    if (d.split('-').indexOf(v) === -1) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).filter(function (e) { return e !== null; });
                // console.log("combinations",combinations)
                allCombinations.push.apply(allCombinations, combinations);
            };
            for (var _d = 0, permu_1 = permu; _d < permu_1.length; _d++) {
                var _r = permu_1[_d];
                _loop_5(_r);
            }
            columnCount = Array.from(new Set(allCombinations)).length;
        }
        else if (legs_1.length === 5) {
            var permu = [[0, 1, 2, 3, 4]];
            if (tjkBetSlipOptions.complete === true) {
                var pers = [];
                var tmp = Combinatorics.permutation([0, 1, 2, 3, 4], 5);
                var _a = tmp.next();
                while (_a) {
                    pers.push(_a);
                    _a = tmp.next();
                }
                permu = pers;
            }
            var allCombinations = [];
            var _loop_6 = function (_r) {
                var combinations = legs_1[_r[0]].flatMap(function (d) { return legs_1[_r[1]].map(function (v) {
                    if (d !== v) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).flatMap(function (d) { return legs_1[_r[2]].map(function (v) {
                    if (d && d.split('-').indexOf(v) === -1) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).flatMap(function (d) { return legs_1[_r[3]].map(function (v) {
                    if (d && d.split('-').indexOf(v) === -1) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).flatMap(function (d) { return legs_1[_r[4]].map(function (v) {
                    if (d && d.split('-').indexOf(v) === -1) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).filter(function (e) { return e !== null; });
                allCombinations.push.apply(allCombinations, combinations);
            };
            for (var _e = 0, permu_2 = permu; _e < permu_2.length; _e++) {
                var _r = permu_2[_e];
                _loop_6(_r);
            }
            columnCount = Array.from(new Set(allCombinations)).length;
        }
        else {
            columnCount = 0;
        }
    }
    // Tabela Bahis
    if (tjkBetSlipOptions.bettingType && tjkBetSlipOptions.bettingType.id === 54 || tjkBetSlipOptions.bettingType.id === 56) {
        var legs_2 = [];
        var _loop_7 = function (c) {
            var cItems = slipItem.filter(function (i) { return i.column === c; }).map(function (el) { return el.horse.no; });
            legs_2.push(cItems);
        };
        for (var _f = 0, uniqueColumn_4 = uniqueColumn; _f < uniqueColumn_4.length; _f++) {
            var c = uniqueColumn_4[_f];
            _loop_7(c);
        }
        if (tjkBetSlipOptions.bettingType.id === 56 && legs_2.length > 0) {
            legs_2.push(legs_2[0]);
            legs_2.push(legs_2[0]);
            legs_2.push(legs_2[0]);
        }
        if (legs_2.length === 4) {
            var permu = [[0, 1, 2, 3]];
            if (tjkBetSlipOptions.complete === true) {
                var pers = [];
                var tmp = Combinatorics.permutation([0, 1, 2, 3], 4);
                var _a = tmp.next();
                while (_a) {
                    pers.push(_a);
                    _a = tmp.next();
                }
                permu = pers;
            }
            var allCombinations = [];
            var _loop_8 = function (_r) {
                var combinations = legs_2[_r[0]].flatMap(function (d) { return legs_2[_r[1]].map(function (v) {
                    if (d !== v) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).filter(function (e) { return e !== null; });
                combinations = combinations.flatMap(function (d) { return legs_2[_r[2]].map(function (v) {
                    if (d.split('-').indexOf(v) === -1) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).filter(function (e) { return e !== null; });
                combinations = combinations.flatMap(function (d) { return legs_2[_r[3]].map(function (v) {
                    if (d.split('-').indexOf(v) === -1) {
                        return d + '-' + v;
                    }
                    return null;
                }); }).filter(function (e) { return e !== null; });
                console.log("combinations", combinations);
                allCombinations.push.apply(allCombinations, combinations);
            };
            for (var _g = 0, permu_3 = permu; _g < permu_3.length; _g++) {
                var _r = permu_3[_g];
                _loop_8(_r);
            }
            columnCount = Array.from(new Set(allCombinations)).length;
        }
    }
    // Virgullu combinasyon hesabı
    if (tjkBetSlipOptions.bettingType && (tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) || tjkBetSlipOptions.bettingType.id === 56)) {
        console.log("columnCount", columnCount);
        if (tjkBetSlipOptions.complete !== true) {
            // console.log(tjkBetSlipOptions.bettingType.id,  tjkBetSlipOptions.complete)
            columnCount = Combinatorics.C(slipItem.length, tjkBetSlipOptions.bettingType.horseCount);
        }
        if (tjkBetSlipOptions.complete === true) {
            console.log("tjkBetSlipOptions.bettingType.id", tjkBetSlipOptions.bettingType.id);
            if (tjkBetSlipOptions.bettingType.id === 50) {
                columnCount *= 2;
            }
            else if (tjkBetSlipOptions.bettingType.id === 53) {
                return columnCount;
            }
            else if (tjkBetSlipOptions.bettingType.id === 56) {
                return columnCount;
            }
        }
        return columnCount;
    }
    // Tek Kolonlu combinasyon hesabı
    if (tjkBetSlipOptions.bettingType && tjkSingleLegBetTypes.includes(tjkBetSlipOptions.bettingType.id)) {
        return Combinatorics.C(slipItem.length, 2);
    }
    // Ganyan ve Plasede betcount 2 kat hesaplanıyor
    if (tjkBetSlipOptions.bettingType && tjkBetSlipOptions.bettingType.id === 4) {
        columnCount *= 2;
    }
    return tjkBetSlipOptions.bettingType && tjkBetSlipOptions.bettingType.column === uniqueColumn.length ? columnCount : 0;
}
var memoBetCount = 0;
export function getbetCount() {
    if (updateBetCount.status) {
        memoBetCount = getbetCount2();
        updateBetCount.status = false;
    }
    return memoBetCount;
}
export function PlayTjkCoupon(save) {
    if (save === void 0) { save = false; }
    return __awaiter(this, void 0, void 0, function () {
        var betCount, price, couponData, orderUrl;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    // console.log("PlayTjkCoupon", tjkBetslipStore)
                    // console.log("tjkBetSlipOptions", tjkBetSlipOptions)
                    updateBetCount.status = true;
                    betCount = getbetCount();
                    price = Number(tjkBetSlipOptions.betSlipMultiply) * Number(betCount) * Number(tjkBetSlipOptions.bettingType.price);
                    couponData = {
                        "betType": tjkBetSlipOptions.bettingType.id,
                        "cardId": tjkBetSlipOptions.hippodrome.card_id,
                        "race": tjkBetSlipOptions.bettingSubType.split("-")[0],
                        "multiplier": tjkBetSlipOptions.betSlipMultiply,
                        "betCount": betCount,
                        "price": price.toFixed(2),
                        "legs": getCouponLegs(),
                        "poolUnit": Math.round(Number(tjkBetSlipOptions.bettingType.price) * Number(100)),
                        "systemAmount": 5
                    };
                    console.log(JSON.stringify(couponData));
                    orderUrl = env.core_api + "/order/api/tjk/place-order/";
                    return [4 /*yield*/, api.start('post', orderUrl, {
                            token: Storage.get('token'),
                            coupon: JSON.stringify(couponData),
                            unit: tjkBetSlipOptions.usePointBalance ? 2 : 0,
                            complete: tjkBetSlipOptions.complete,
                            save: save,
                            device_os: isAndroid ? 'Android' : isIOS ? 'iOS' : isMacOs ? 'Macintosh' : isWindows ? 'Windows' : 'Linux',
                            browser_type: 'YENI-WEB',
                            device_type: isMobile ? 'Mobile' : 'Desktop',
                        }, true, 60000).then(function (result) {
                            console.log(result);
                            return result;
                        }).catch(function (error) {
                            modalServiceSubject.next({
                                title: 'Teknik hata',
                                content: error + '<p>Kuponunuzun oynanıp oynanmadığını TJK kuponlarım sayfasından kontrol ediniz.</p>',
                                confirm: {
                                    cancel: {
                                        label: "TJK Kuponlarım",
                                        action: function () {
                                            window.location.href = '/hesabim/tjk/devam-edenler';
                                        }
                                    }
                                }
                            });
                            return null;
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function clearTjkBetSlip() {
    var slipItem = tjkBetslipStore.filter(function (el) {
        if (el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
            el.bettingType.id === tjkBetSlipOptions.bettingType.id &&
            el.bettingSubType === tjkBetSlipOptions.bettingSubType) {
            return el;
        }
    });
    for (var _i = 0, slipItem_1 = slipItem; _i < slipItem_1.length; _i++) {
        var item = slipItem_1[_i];
        console.log(item, tjkBetslipStore.indexOf(item));
        tjkBetslipStore.splice(tjkBetslipStore.indexOf(item), 1);
    }
    tjkBetSlipOptions.errorMessage = null;
    tjkBetSlipOptions.betSlipMultiply = 1;
    tjkBetSlipOptions.usePointBalance = false;
    tjkBetSlipActionSubject.next({ action: "clear", data: null });
}
