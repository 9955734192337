var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { availableBettingSubTypes, availableHippodromes, availableRuns, availableTjkBettingTypes, DailyRaces, tjkBetSlipOptions, tjkBettingTypes } from "./tjk.store";
import _ from "lodash";
import moment from "moment";
import { ApiService } from "../../lib/http.service";
import { ServerDate } from "../../lib/misc.functions";
import { compareUpdatesAndHorses } from "./tjk.betslip.service";
import { env } from "../../definitions/global.vars";
export function getAvailableHippodromes() {
    availableHippodromes.splice(0, availableHippodromes.length);
    //fixme:
    // availableHippodromes.push(..._.orderBy(DailyRaces,['opening_date']).filter((el:any)=>el.status===false));
    availableHippodromes.push.apply(availableHippodromes, _.orderBy(DailyRaces, ['opening_date']));
    return availableHippodromes;
}
export function getAvailableBettingSubTypes(hippodrome) {
    availableBettingSubTypes.splice(0, availableBettingSubTypes.length);
    if (tjkBetSlipOptions.bettingType && hippodrome) {
        if (Number(tjkBetSlipOptions.bettingType.id) === 1 ||
            Number(tjkBetSlipOptions.bettingType.id) === 2 ||
            Number(tjkBetSlipOptions.bettingType.id) === 4 ||
            Number(tjkBetSlipOptions.bettingType.id) === 8 ||
            Number(tjkBetSlipOptions.bettingType.id) === 9 ||
            Number(tjkBetSlipOptions.bettingType.id) === 10 ||
            Number(tjkBetSlipOptions.bettingType.id) === 11 ||
            Number(tjkBetSlipOptions.bettingType.id) === 47 ||
            Number(tjkBetSlipOptions.bettingType.id) === 48 ||
            Number(tjkBetSlipOptions.bettingType.id) === 54 ||
            Number(tjkBetSlipOptions.bettingType.id) === 55 ||
            Number(tjkBetSlipOptions.bettingType.id) === 61 ||
            Number(tjkBetSlipOptions.bettingType.id) === 62 ||
            Number(tjkBetSlipOptions.bettingType.id) === 51 ||
            [50, 53, 56, 63].includes(tjkBetSlipOptions.bettingType.id)) {
            console.log(tjkBetSlipOptions.bettingType.runs.map(function (r) {
                return String(r);
            }));
            console.log(tjkBetSlipOptions.bettingType.runs.filter(function (r) {
                var run = tjkBetSlipOptions.hippodrome.runs.find(function (x) { return x.status === 0 && Number(x.no) === Number(r); });
                if (run) {
                    return String(r);
                }
                else {
                    return null;
                }
            }).map(function (r) {
                return String(r);
            }));
            // GANYAN , PLASE, GANYAN & PLASE,
            // availableBettingSubTypes.push(...tjkBetSlipOptions.bettingType.runs.map((r: any) => {
            //   return String(r)
            // }));
            availableBettingSubTypes.push.apply(availableBettingSubTypes, tjkBetSlipOptions.bettingType.runs.filter(function (r) {
                var run = tjkBetSlipOptions.hippodrome.runs.find(function (x) { return x.status === 0 && Number(x.no) === Number(r); });
                if (run) {
                    return String(r);
                }
                else {
                    return null;
                }
            }).map(function (r) {
                return String(r);
            }));
        }
        if ([14, 15, 16, 17, 18, 19].includes(Number(tjkBetSlipOptions.bettingType.id))) {
            // 3LÜ ,4LÜ, 5Lİ, 6LI, 7li GANYAN
            var runs = _.orderBy(tjkBetSlipOptions.bettingType.runs.filter(function (r) {
                var run = tjkBetSlipOptions.hippodrome.runs.find(function (x) { return x.status === 0 && Number(x.no) === Number(r); });
                if (run) {
                    return r;
                }
                else {
                    return null;
                }
            }), ['no'], ['asc']).reverse();
            var s = [];
            // console.log("runs",runs)
            for (var _i = 0, runs_1 = runs; _i < runs_1.length; _i++) {
                var r = runs_1[_i];
                var chunkSize = tjkBetSlipOptions.bettingType.column;
                var chunk = [];
                for (var i = r; i < r + chunkSize; i += 1) {
                    // console.log("i",i)
                    chunk.push(i);
                }
                s.push(chunk.join('-'));
            }
            // console.log("s",s)
            //
            //
            // const pageSize = Math.ceil(runs.length/chunkSize);
            // for (let i = 0; i < pageSize; i+=1) {
            //   const chunk = runs.slice((i*chunkSize), ((i+1)*chunkSize));
            //   if(chunk.length===chunkSize){
            //     s.push(chunk.reverse().join('-'));
            //   }
            // }
            availableBettingSubTypes.push.apply(availableBettingSubTypes, s.reverse());
        }
    }
    return availableBettingSubTypes;
}
export function getAvailableBettingTypes() {
    availableTjkBettingTypes.splice(0, availableTjkBettingTypes.length);
    var hippodromeBetTypes = [];
    if (tjkBetSlipOptions.hippodrome && tjkBetSlipOptions.hippodrome.wager_details) {
        hippodromeBetTypes = tjkBetSlipOptions.hippodrome.wager_details.map(function (e) { return Number(e.type); });
    }
    availableTjkBettingTypes.push.apply(availableTjkBettingTypes, tjkBettingTypes.filter(function (el) {
        if (hippodromeBetTypes.includes(el.id)) {
            var item = tjkBetSlipOptions.hippodrome.wager_details.find(function (x) { return Number(x.type) === Number(el.id); });
            el.price = item.price;
            el.runs = item.runs;
            return el;
        }
    }));
    return availableTjkBettingTypes;
}
export function getAvailableRuns() {
    var runList = [];
    if (String(tjkBetSlipOptions.bettingSubType).split('-').length > 1) {
        runList = tjkBetSlipOptions.hippodrome.runs.filter(function (r) {
            if (tjkBetSlipOptions.bettingSubType.split('-').map(function (n) { return Number(n); }).includes(Number(r.no))) {
                return r;
            }
        });
    }
    else {
        runList = tjkBetSlipOptions.hippodrome.runs.filter(function (r) {
            if (Number(r.no) === Number(tjkBetSlipOptions.bettingSubType)) {
                return r;
            }
        });
    }
    availableRuns.splice(0, availableRuns.length);
    //fixme
    // availableRuns.push(...runList.filter((el:any)=>String(el.status)==="0"));
    availableRuns.push.apply(availableRuns, tjkBetSlipOptions.hippodrome.runs);
}
export function getDailyRaces() {
    return __awaiter(this, void 0, void 0, function () {
        var today, api, date;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    today = ServerDate();
                    api = new ApiService();
                    date = moment(today).format('DDMMYYYY');
                    return [4 /*yield*/, api.start('get', env.tjk_api + "/api/bulletin/".concat(date, "/"), null, true).then(function (result) {
                            if (result.status) {
                                if (DailyRaces.length > 0) {
                                    var isUpdated = false;
                                    var _loop_1 = function (newHippodrome) {
                                        newHippodrome.opening_date = new Date(newHippodrome.date.split('T')[0] + 'T' + newHippodrome.opening + ':00');
                                        newHippodrome.runs.filter(function (run) {
                                            run.running_date = new Date(newHippodrome.date.split('T')[0] + 'T' + run.time + ':00');
                                            return run;
                                        });
                                        var exHippodrome = DailyRaces.find(function (el) { return el.id === newHippodrome.id; });
                                        if (exHippodrome) {
                                            for (var _b = 0, _c = Object.entries(newHippodrome); _b < _c.length; _b++) {
                                                var _d = _c[_b], key = _d[0], value = _d[1];
                                                if (!['runs', 'wager_details'].includes(key) && exHippodrome[key] !== value) {
                                                    exHippodrome[key] = value;
                                                    isUpdated = true;
                                                }
                                                else if (key === 'wager_details') {
                                                    if (newHippodrome.wager_details) {
                                                        if (!exHippodrome.wager_details) {
                                                            exHippodrome['wager_details'] = [];
                                                        }
                                                        var _loop_2 = function (newBet) {
                                                            var exBet = exHippodrome.wager_details.find(function (el) { return el.type === newBet.type; });
                                                            if (exBet) {
                                                                for (var _j = 0, _k = Object.entries(newBet); _j < _k.length; _j++) {
                                                                    var _l = _k[_j], bKey = _l[0], bValue = _l[1];
                                                                    if (['price', 'runs'].includes(bKey)) {
                                                                        exBet[bKey] = bValue;
                                                                        isUpdated = true;
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                exHippodrome.wager_details.push(newBet);
                                                            }
                                                        };
                                                        for (var _e = 0, _f = newHippodrome.wager_details; _e < _f.length; _e++) {
                                                            var newBet = _f[_e];
                                                            _loop_2(newBet);
                                                        }
                                                    }
                                                    else {
                                                        exHippodrome['wager_details'] = [];
                                                    }
                                                }
                                                else if (key === 'runs') {
                                                    if (newHippodrome.runs) {
                                                        var _loop_3 = function (newRun) {
                                                            var exRun = exHippodrome.runs.find(function (el) { return el.no === newRun.no; });
                                                            if (exRun) {
                                                                for (var _m = 0, _o = Object.entries(newRun); _m < _o.length; _m++) {
                                                                    var _p = _o[_m], rkey = _p[0], rValue = _p[1];
                                                                    if (!['horses'].includes(rkey) && exRun[rkey] !== rValue) {
                                                                        exRun[rkey] = rValue;
                                                                        isUpdated = true;
                                                                    }
                                                                    else if ('horses' === rkey) {
                                                                        if (newRun.horses) {
                                                                            var _loop_4 = function (newHorse) {
                                                                                var exHorse = exRun.horses.find(function (el) { return el.no === newHorse.no; });
                                                                                if (exHorse) {
                                                                                    for (var _s = 0, _t = Object.entries(newHorse); _s < _t.length; _s++) {
                                                                                        var _u = _t[_s], hkey = _u[0], hValue = _u[1];
                                                                                        if (exHorse[hkey] !== hValue) {
                                                                                            exHorse[hkey] = hValue;
                                                                                            isUpdated = true;
                                                                                        }
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    // at ekle
                                                                                    if (newHorse.run_status) {
                                                                                        exRun.horses.push(newHorse);
                                                                                    }
                                                                                }
                                                                            };
                                                                            for (var _q = 0, _r = newRun.horses; _q < _r.length; _q++) {
                                                                                var newHorse = _r[_q];
                                                                                _loop_4(newHorse);
                                                                            }
                                                                        }
                                                                        else {
                                                                            exRun['horses'] = [];
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                // koşu eklenecek
                                                                if (Number(newRun.status) === 0) {
                                                                    exHippodrome.runs.push(newRun);
                                                                }
                                                            }
                                                        };
                                                        for (var _g = 0, _h = newHippodrome.runs; _g < _h.length; _g++) {
                                                            var newRun = _h[_g];
                                                            _loop_3(newRun);
                                                        }
                                                    }
                                                    else {
                                                        exHippodrome['runs'] = [];
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            if (newHippodrome.status) {
                                                DailyRaces.push(newHippodrome);
                                            }
                                        }
                                    };
                                    for (var _i = 0, _a = result.data; _i < _a.length; _i++) {
                                        var newHippodrome = _a[_i];
                                        _loop_1(newHippodrome);
                                    }
                                    // fixme : bu kısım tekrar yazılmalı
                                    // let newHippodromeIds = result.data.filter((e:any)=>e.status===true).map((r:any)=>Number(r.id))
                                    // console.log("newHippodromeIds",newHippodromeIds)
                                    // let exHippodromesMustRemove = DailyRaces.filter((el:any)=>!newHippodromeIds.includes(Number(el.id))).map((h:any)=>h.id)
                                    // console.log("exHippodromesMustRemove",exHippodromesMustRemove)
                                    //
                                    // for(let id of exHippodromesMustRemove){
                                    //   let index = DailyRaces.findIndex((d:any)=>Number(d.id)===Number(id))
                                    //   if(index!==-1) {
                                    //     DailyRaces.splice(index, 1)
                                    //   }
                                    // }
                                    if (isUpdated) {
                                        compareUpdatesAndHorses();
                                    }
                                }
                                else {
                                    DailyRaces.splice(0, DailyRaces.length);
                                    DailyRaces.push.apply(DailyRaces, result.data.filter(function (el) {
                                        el.opening_date = new Date(el.date.split('T')[0] + 'T' + el.opening + ':00');
                                        el.runs.filter(function (run) {
                                            run.running_date = new Date(el.date.split('T')[0] + 'T' + run.time + ':00');
                                            return run;
                                        });
                                        return el;
                                    }));
                                }
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
