var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Play from "../../../assets/feather/play.svg";
import LMT from "../../../assets/feather/saha.svg";
import Close from "../../../assets/feather/x-circle.svg";
import { dateFormat, getDateHumanize, ServerDate, useForceUpdate } from "../../../lib/misc.functions";
import { member } from "../../../store/login.store";
import { Storage } from "../../../lib/localstorege.service";
import { modalServiceSubject } from "../../../components/modal/modal";
import { fillEventStatistics } from "../../../services/sportsbook.services";
import { statisticsMap } from "../../../store/sportsbook.store";
import iconCorner from "../../../assets/feather/corner.png";
import { CompetitionLabelHelper } from "../../../definitions/constants/competition-maps";
var LMTLoaded = false;
var liveStreamPlayer;
var lmtAI;
var iframeAI;
var lmtAvailableSports = ['futbol', 'basketbol', 'soccer', 'basketball'];
export function EventDetailHeader(props) {
    var event = props.event;
    var closeDetail = props.closeDetail;
    var getStreamPlayer = props.getStreamPlayer;
    var navigate = props.navigate;
    var _a = useState(false), showLiveStream = _a[0], setShowLiveStream = _a[1];
    var _b = useState(false), showLMT = _b[0], setShowLMT = _b[1];
    var width = window.innerWidth;
    if (event.theSportsId) {
        lmtAvailableSports.push('tenis');
    }
    var loadLMT = function () {
        setShowLiveStream(false);
        var liveStreamContainer = document.getElementById('live-stream-container');
        if (liveStreamContainer) {
            liveStreamContainer.classList.remove('show');
            liveStreamContainer.classList.add('hide');
        }
        if (!LMTLoaded || LMTLoaded !== event.betradarId) {
            if (Number(event.eventType) === 1 && lmtAvailableSports.indexOf(event.sportSlug) !== -1) {
                try {
                    var lmtAI_1 = document.getElementById('lmt-container');
                    // @ts-ignore
                    lmtAI_1.innerHTML = '';
                    // @ts-ignore
                    iframeAI.remove();
                }
                catch (e) {
                }
                if (event.sportSlug === 'tenis' && event.theSportsId) {
                    var lmtAI_2 = document.getElementById('lmt-container');
                    var eventContent = document.getElementById('event-content');
                    if (lmtAI_2 && eventContent) {
                        lmtAI_2.style.marginBottom = '-10px';
                        var iframeLMT = document.createElement("iframe");
                        iframeLMT.width = '100%';
                        iframeLMT.style.minHeight = 'unset';
                        iframeLMT.height = String(((404 / 800 * eventContent.clientWidth) + 92));
                        iframeLMT.src = "https://widgets.thesports01.com/tr/3D/tennis?profile=lzmpyfj7vsdukm6&uuid=" + event.theSportsId;
                        console.log(iframeLMT.src);
                        lmtAI_2.append(iframeLMT);
                    }
                }
                else {
                    if (event.betradarId !== "0" && event.betradarId !== undefined) {
                        if (lmtAI) {
                            lmtAI.style.marginBottom = 'unset';
                        }
                        var opts = {
                            scoreboard: 'disable',
                            detailedScoreboard: 'disable',
                            tabsPosition: 'top',
                            matchId: event.betradarId,
                        };
                        try {
                            // @ts-ignore
                            window.SIR('addWidget', '.sr-widget-alt', 'match.lmtPlus', opts);
                        }
                        catch (e) {
                        }
                    }
                }
                LMTLoaded = event.betradarId;
                setShowLMT(true);
                setShowLiveStream(false);
                if (liveStreamPlayer) {
                    liveStreamPlayer.stopVideo();
                    var liveStreamContainer_1 = document.getElementById('live-stream-container');
                    if (liveStreamContainer_1) {
                        liveStreamContainer_1.classList.remove('show');
                        liveStreamContainer_1.classList.add('hide');
                    }
                }
            }
        }
        else if (LMTLoaded === event.betradarId) {
            setShowLMT(!showLMT);
        }
    };
    var unLoadLMT = function () {
        try {
            // @ts-ignore
            window.SIR('removeWidget', document.querySelector('.sr-widget-alt'));
        }
        catch (e) {
        }
        try {
            // @ts-ignore
            lmtAI.innerHTML = '';
            // @ts-ignore
            iframeAI.remove();
        }
        catch (e) {
        }
        LMTLoaded = false;
        var lmtContainer = document.getElementById('lmt-container');
        if (lmtContainer) {
            lmtContainer.classList.add('hide');
            lmtContainer.classList.remove('show');
        }
    };
    var showStream = function () {
        if ((!member.id || !Storage.get('token')) && event.liveStreamAvailable === 2) {
            modalServiceSubject.next({
                title: 'Üyelik Gerekli!',
                content: '<p>Canlı yayın hizmetimiz yanlızca Oley.com üyelerine açıktır. Lütfen maç keyfini yaşamak için hemen üye girişi yapınız.</p>',
                confirm: {
                    sure: {
                        label: 'Giriş Yap',
                        action: function () {
                            navigate('/giris-yap');
                        }
                    },
                    cancel: {
                        label: 'Üye Ol',
                        action: function () {
                            navigate('/kayit');
                        }
                    }
                },
            });
        }
        else {
            var today = ServerDate();
            var secoundsLeft = (new Date(event.eventDate).getTime() - today.getTime()) / 1000;
            if (secoundsLeft > 0 && event.liveStreamAvailable !== 2) {
                if (!showLiveStream) {
                    setShowLiveStream(true);
                    setShowLMT(false);
                    var liveStreamContainer = document.getElementById('live-stream-container');
                    if (liveStreamContainer) {
                        liveStreamContainer.classList.remove('hide');
                        liveStreamContainer.classList.add('show');
                    }
                    var liveMatcheVideoContainer = document.getElementById('live-match-video-container');
                    if (liveMatcheVideoContainer) {
                        liveMatcheVideoContainer.classList.remove('show');
                        liveMatcheVideoContainer.classList.add('hide');
                    }
                }
                else {
                    setShowLiveStream(false);
                }
            }
            else {
                // console.log("liveStreamPlayer>>",liveStreamPlayer)
                if (!liveStreamPlayer) {
                    getStreamPlayer().then(function (res) {
                        liveStreamPlayer = res;
                        setShowLiveStream(true);
                    });
                }
                else {
                    if (showLiveStream) {
                        liveStreamPlayer.stopVideo();
                        setShowLiveStream(false);
                        var liveStreamContainer = document.getElementById('live-stream-container');
                        if (liveStreamContainer) {
                            liveStreamContainer.classList.remove('show');
                            liveStreamContainer.classList.add('hide');
                        }
                    }
                    else {
                        liveStreamPlayer.playVideo();
                        setShowLiveStream(true);
                        var liveStreamContainer = document.getElementById('live-stream-container');
                        if (liveStreamContainer) {
                            liveStreamContainer.classList.remove('hide');
                            liveStreamContainer.classList.add('show');
                        }
                    }
                }
            }
        }
        unLoadLMT();
    };
    useEffect(function () {
        setShowLiveStream(false);
        setShowLMT(false);
        if (LMTLoaded === false || LMTLoaded !== event.betradarId) {
            if (width > 968) {
                loadLMT();
            }
        }
        return function () {
            unLoadLMT();
            if (liveStreamPlayer) {
                liveStreamPlayer.stopVideo();
                liveStreamPlayer.remove();
                liveStreamPlayer = null;
            }
        };
    }, [event]);
    if (!event) {
        return _jsx(_Fragment, {}, void 0);
    }
    var eventType = Number(event.eventType);
    var lmtContainer = document.getElementById('lmt-container');
    if (lmtContainer) {
        if (showLMT) {
            lmtContainer.classList.remove('hide');
            lmtContainer.classList.add('show');
        }
        else {
            lmtContainer.classList.add('hide');
            lmtContainer.classList.remove('show');
        }
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: { display: 'flex' } }, { children: [event.isEnded === true || event.liveStreamAvailable === 0 ? _jsx(_Fragment, {}, void 0) : _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "liveStreamBadge", onClick: function () {
                                    showStream();
                                } }, { children: _jsx("img", { src: Play }, void 0) }), void 0), ":", _jsx(_Fragment, {}, void 0)] }, void 0), eventType === 1 && lmtAvailableSports.includes(event.sportSlug) ? _jsx(_Fragment, { children: _jsx("div", __assign({ style: {
                                marginLeft: event.isEnded === true || event.liveStreamAvailable === 0 ? '0px' : '45px',
                                height: "23px",
                                overflow: "hidden",
                                position: "absolute",
                                zIndex: 2
                            }, onClick: function () {
                                loadLMT();
                            } }, { children: _jsx("img", { src: LMT, width: 36, height: 36, style: { marginTop: "-6px", filter: LMTLoaded ? 'hue-rotate(270deg)' : 'unset' } }, void 0) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsxs("div", __assign({ className: "competition" }, { children: [event.competitionGroupName !== 'Uluslararası Kulüpler' ? event.competitionGroupName + ' - ' : '', CompetitionLabelHelper(event.competitionName).replace(event.competitionGroupName, '')] }), void 0), _jsx("div", __assign({ className: "close-detail invert-color" }, { children: _jsx("img", { src: Close, width: 24, onClick: function () {
                                closeDetail();
                            } }, void 0) }), void 0)] }), void 0), Number(event.eventType) === 1 && event.sportSlug !== 'motor-sporlar' ?
                _jsx(HeaderStats, { event: event }, void 0) : _jsx("div", __assign({ className: "mt-1 mb-2 text-center" }, { children: event.eventName }), void 0)] }, void 0));
}
var renewalTimer;
export function HeaderStats(prop) {
    var event = prop.event;
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        if (Number(event.isLiveEvent) === 1) {
            fillEventStatistics([event.eventId]).then(function () {
                forceUpdate();
            }).catch(function () {
                forceUpdate();
            });
        }
        return function () {
            clearTimeout(renewalTimer);
        };
    }, []);
    clearTimeout(renewalTimer);
    if (Number(event.isLiveEvent) === 1) {
        renewalTimer = setTimeout(function () {
            fillEventStatistics([event.eventId]).then(function () {
                forceUpdate();
            }).catch(function () {
                forceUpdate();
            });
        }, 5000);
    }
    var statistic = statisticsMap[event.eventId];
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row mb-2" }, { children: [_jsxs("div", __assign({ className: "col home nowrap" }, { children: [event.eventParticipant[0].participantName, statistic && statistic.homeTeam.statistics ? _jsxs("ul", __assign({ className: "event-header-statistic" }, { children: [_jsxs("li", { children: [_jsx("i", __assign({ className: "corner-card" }, { children: _jsx("img", { src: iconCorner }, void 0) }), void 0), " ", statistic.homeTeam.statistics.corners ? statistic.homeTeam.statistics.corners : '-'] }, void 0), _jsxs("li", { children: [_jsx("i", { className: "yellow-card" }, void 0), " ", statistic.homeTeam.statistics.yellowCards] }, void 0), _jsxs("li", { children: [_jsx("i", { className: "red-card" }, void 0), " ", Number(statistic.homeTeam.statistics.redCards) + Number(statistic.homeTeam.statistics.secondYellowCards)] }, void 0)] }), void 0) : _jsx(_Fragment, {}, void 0)] }), void 0), event.currentScore ?
                    _jsxs("div", __assign({ className: "currentScore text-center" }, { children: [event.currentScore, _jsx("span", __assign({ className: "time" }, { children: event.minute }), void 0)] }), void 0) :
                    _jsxs("div", __assign({ className: "date" }, { children: [event.isEnded === true ? dateFormat(event.eventDate) : getDateHumanize(event.eventDate), _jsx("span", __assign({ className: "time" }, { children: event.eventDate.split("T")[1].substring(0, 5) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col away nowrap" }, { children: [event.eventParticipant[1].participantName, statistic && statistic.awayTeam.statistics ? _jsxs("ul", __assign({ className: "event-header-statistic" }, { children: [_jsxs("li", { children: [_jsx("i", { className: "red-card" }, void 0), " ", Number(statistic.awayTeam.statistics.redCards) + Number(statistic.awayTeam.statistics.secondYellowCards)] }, void 0), _jsxs("li", { children: [_jsx("i", { className: "yellow-card" }, void 0), " ", statistic.awayTeam.statistics.yellowCards] }, void 0), _jsxs("li", { children: [_jsx("i", __assign({ className: "corner-card" }, { children: _jsx("img", { src: iconCorner }, void 0) }), void 0), " ", statistic.awayTeam.statistics.corners ? statistic.awayTeam.statistics.corners : '-'] }, void 0)] }), void 0) : _jsx(_Fragment, {}, void 0)] }), void 0)] }), void 0) }, void 0));
}
