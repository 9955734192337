var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { TrendEvents } from "../sosyoley/widgets/trends";
import { useParams } from "react-router-dom";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import KatarMenu from "./components/katar-menu";
import Tarihce from "./subpages/tarihce";
import IddiaOranlari from "./subpages/iddia-oranlari";
import Kadrolar from "./subpages/kadrolar";
import PuanDurumu from "./subpages/puan-durumu";
import FiksturSonuclar from "./subpages/fikstur-sonuclar";
import '../sosyoley/sosyoley.css';
import Katar2022Home from "./subpages/katar2022-home";
import PredictionGame from "./subpages/prediction-game";
import KatarMobilMenu from "./components/katar-mobile-menu";
var Katar2022 = function () {
    var params = useParams();
    var _a = useState(null), contentType = _a[0], setContentType = _a[1];
    var width = window.innerWidth;
    document.body.style.overflow = 'unset';
    if (contentType !== (params.contentType || params.memberId)) {
        setContentType((params.contentType || params.memberId));
    }
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible, params.subpage]);
    var pages = {
        'katar-2022': _jsx(Katar2022Home, {}, void 0),
        'fikstur-sonuclar': _jsx(FiksturSonuclar, {}, void 0),
        'puan-durumu': _jsx(PuanDurumu, {}, void 0),
        'kadrolar': _jsx(Kadrolar, {}, void 0),
        'iddaa-oranlari': _jsx(IddiaOranlari, {}, void 0),
        'tarihce': _jsx(Tarihce, {}, void 0),
        'tahmin-yarismasi': _jsx(PredictionGame, {}, void 0)
    };
    var pageTitles = {
        'katar-2022': 'Katar 2022',
        'fikstur-sonuclar': 'Fikstür ve Sonuçlar',
        'puan-durumu': 'Puan Durumu',
        'kadrolar': 'Kadrolar',
        'iddaa-oranlari': 'İddaa Oranları',
        'tarihce': 'Tarihçe',
        'tahmin-yarismasi': 'Tahmin Yarışması'
    };
    useEffect(function () {
        // const katarPage = document.getElementById("katarPage")
        // if (width > 968) {
        //     betSlipVisibleSubject.next(true);
        //     if (katarPage) {
        //         katarPage.classList.add('betslip-visible')
        //     }
        // } else {
        //     if (katarPage) {
        //         katarPage.classList.remove('betslip-visible')
        //     }
        // }
    }, []);
    // if (!params.subpage) {
    //     return <></>
    // }
    function menuSelected() {
        var element = document.getElementById("menuHamburger");
        if (element.style.display == "none") {
            element.style.display = "inline";
        }
        else {
            element.style.display = "none";
        }
    }
    return (_jsx("div", __assign({ className: "container-fluid page sosyoley mb-2" }, { children: _jsxs("div", __assign({ className: 'row' }, { children: [_jsx("div", __assign({ className: 'sub-menu visible-sm pb-md-5 pb-sm-3' }, { children: _jsx(KatarMobilMenu, {}, void 0) }), void 0), width >= 768 ? _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "left-column" }, { children: [_jsx(KatarMenu, {}, void 0), _jsx(TrendEvents, {}, void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsxs("div", __assign({ id: "katarPage", className: "right-column", style: { marginTop: innerWidth <= 968 ? "25px" : "0px" } }, { children: [_jsxs("div", __assign({ id: "innerLoading" }, { children: [_jsx("div", __assign({ className: "label" }, { children: "Oley" }), void 0), _jsxs("div", __assign({ className: "lds-roller" }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0)] }), void 0), params.subpage ? pages[params.subpage] : _jsx(Katar2022Home, {}, void 0)] }), void 0)] }), void 0) }), void 0));
};
export default Katar2022;
