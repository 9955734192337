var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { linkify, useForceUpdate } from "../../../lib/misc.functions";
import { useEffect } from "react";
import { askToDelete, askToReport, commentUpdateSubject, likePost } from "../../../services/sosyoley.services";
import { env } from "../../../definitions/global.vars";
import DefaultAvatar from "../../../assets/img/avatar.png";
import { Link } from "react-router-dom";
import iconComment from "../../../assets/feather/message-circle.svg";
import cantComment from "../../../assets/feather/x-circle.svg";
import HeartFill from "../../../assets/feather/heart-fill.svg";
import Heart from "../../../assets/feather/heart.svg";
import Flag from "../../../assets/feather/flag.svg";
import { member } from "../../../store/login.store";
import TrashRed from "../../../assets/feather/trash-2-red.svg";
var CommetLoadMoreLock = false;
var scrollListenerTimer;
var CommetPage = 0;
var postIds = [];
export function TjkComments(props) {
    var forceUpdate = useForceUpdate();
    var hippodrome = props.hippodrome;
    var setPostCommentModal = props.modalState;
    var selectedEditor = props.selectedEditor;
    var setSelectedEditor = props.setSelectedEditor;
    useEffect(function () {
        postIds = hippodrome.comments.map(function (p) { return p.post_id; });
        // console.log("postIds", postIds)
        var commentUpdateSubject_subscription = commentUpdateSubject.subscribe(function (eventId) {
            console.log(eventId);
            if (typeof eventId !== 'object') {
                if (Number(hippodrome.id) === eventId || postIds.includes(eventId)) {
                    // console.log("force update")
                    forceUpdate();
                }
            }
            else if (eventId.action === 'add') {
                // console.log(eventId, hippodrome.comments)
                hippodrome.comments.unshift(eventId.data.post);
                postIds.unshift(eventId.data.post.post_id);
                forceUpdate();
            }
            else if (eventId.action === 'comment_count') {
                // console.log(eventId, hippodrome.comments)
                hippodrome.comments.filter(function (e) {
                    if (Number(e.post_id) === Number(eventId.post_id)) {
                        e.comment_count = eventId.count;
                    }
                    return e;
                });
                forceUpdate();
            }
            else if (eventId.action === 'delete') {
                hippodrome.comments = hippodrome.comments.filter(function (e) {
                    if (Number(e.post_id) !== Number(eventId.post_id)) {
                        return e;
                    }
                });
                forceUpdate();
            }
        });
        return function () {
            commentUpdateSubject_subscription.unsubscribe();
        };
    }, []);
    var shownComment = [];
    if (selectedEditor) {
        shownComment = hippodrome.comments.filter(function (el) { return el.member_id === selectedEditor.id; });
    }
    else {
        shownComment = hippodrome.comments;
    }
    if (hippodrome.comments) {
        if (hippodrome.comments.length > 0) {
            return (_jsx(_Fragment, { children: shownComment.map(function (el, key) {
                    return (_jsxs("div", __assign({ className: "post" }, { children: [_jsx("img", { className: "avatar", src: el.member.photo ? env.sosyoley_api + el.member.photo : DefaultAvatar }, void 0), _jsxs(Link, __assign({ to: "/sosyoley/profil/" + el.member_id + "/tumu", className: "nickname d-flex align-items-center", style: { height: "100%" } }, { children: ["@", el.member.nick_name] }), void 0), _jsx("div", { className: "comment", dangerouslySetInnerHTML: { __html: linkify(el.comment) } }, void 0), _jsxs("div", __assign({ className: "row actions m-2" }, { children: [_jsx("div", __assign({ className: "col-2" }, { children: (el.comment_perm) ?
                                            (_jsxs(_Fragment, { children: [_jsx("img", { src: iconComment, width: 16, onClick: function () { setPostCommentModal(el); } }, void 0), _jsx("i", { children: el.comment_count }, void 0)] }, void 0))
                                            : (_jsx("img", { src: cantComment, width: 16 }, void 0)) }), void 0), _jsxs("div", __assign({ className: "col-2" }, { children: [_jsx("img", { src: el.is_liked && el.is_liked[0] === 1 ? HeartFill : Heart, width: 16, onClick: function () { likePost(el); } }, void 0), _jsx("i", { children: el.like_count }, void 0)] }), void 0), _jsx("div", __assign({ className: "col-2" }, { children: _jsx("img", { src: Flag, width: 16, onClick: function () { askToReport(el); } }, void 0) }), void 0), _jsx("div", __assign({ className: "col-2" }, { children: member && Number(el.member_id) === Number(member.id) ? _jsx("img", { src: TrashRed, width: 16, onClick: function () {
                                                askToDelete(el, event);
                                            } }, void 0) : _jsx(_Fragment, {}, void 0) }), void 0), !selectedEditor ? _jsx(_Fragment, { children: _jsx("div", __assign({ className: "col-2" }, { children: _jsx("button", __assign({ onClick: function () {
                                                    var txtComment = document.getElementById('id_txtComment');
                                                    if (txtComment) {
                                                        // @ts-ignore
                                                        txtComment.value = txtComment.value !== '' ? txtComment.value + ' @' + el.member.nick_name : '@' + el.member.nick_name;
                                                        txtComment.focus();
                                                    }
                                                }, className: "btn btn-small btn-outline-primary" }, { children: "Yan\u0131tla" }), void 0) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0)] }), key));
                }) }, void 0));
        }
        else {
            return (_jsx("div", __assign({ className: "text-center m-3" }, { children: "Hen\u00FCz hi\u00E7 yorum yok" }), void 0));
        }
    }
    return _jsx("div", __assign({ className: "text-center m-3" }, { children: "Y\u00FCkleniyor.." }), void 0);
}
