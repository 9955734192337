import { ApiService } from '../../lib/http.service';
import { env } from "../../definitions/global.vars";
export var Api = new ApiService();
export var HelpApi = (function () {
    return {
        help: function () {
            return Api.start('get', env.cdn_url + '/json/help_questions.json', null, false);
        },
        alliIddaa: function () {
            return Api.start('get', env.cdn_url + '/json/markets_playable.json', null, false);
        },
    };
})();
