var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
export function TjkMenu(props) {
    var navigate = useNavigate();
    var subPage = props.subPage;
    return (_jsxs(_Fragment, { children: [_jsx(Link, __assign({ to: ("/at-yarisi/bulten"), className: "btn " + (subPage == "bulten" ? 'btn-default' : 'btn-outline-default') }, { children: "B\u00FClten" }), void 0), _jsx(Link, __assign({ to: ("/at-yarisi/sonuclar"), className: "btn " + (subPage == "sonuclar" ? 'btn-default' : 'btn-outline-default') }, { children: "Sonu\u00E7lar" }), void 0), _jsx(Link, __assign({ to: ("/at-yarisi/muhtemeller"), className: "btn " + (subPage == "muhtemeller" ? 'btn-default' : 'btn-outline-default') }, { children: "Muhtemeller " }), void 0), _jsx(Link, __assign({ to: ("/at-yarisi/agf"), className: "btn " + (subPage == "agf" ? 'btn-default' : 'btn-outline-default') }, { children: "AGF" }), void 0), _jsx(Link, __assign({ to: ("/at-yarisi/tay-tv"), className: "btn " + (subPage == "tay-tv" ? 'btn-default' : 'btn-outline-default') }, { children: "TJK TV" }), void 0), _jsx(Link, __assign({ to: ("/at-yarisi/tjk-tv-yurtdisi"), className: "btn " + (subPage == "tjk-tv-yurtdisi" ? 'btn-default' : 'btn-outline-default') }, { children: "TJK TV Y.DI\u015EI" }), void 0)] }, void 0));
}
