var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTrendEvents } from "../../../services/sosyoley.services";
import { eventMapping } from "../../../store/sportsbook.store";
export function TrendEvents() {
    var _a = useState([]), trendEvents = _a[0], setTrendEvents = _a[1];
    useEffect(function () {
        getTrendEvents().then(function (response) {
            var events = [];
            response.forEach(function (el) {
                var sbEvent = eventMapping[el.event_id.toString()];
                if (sbEvent) {
                    events.push(sbEvent);
                }
            });
            setTrendEvents(events);
        });
    }, []);
    if (trendEvents.length === 0) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsx("div", __assign({ className: "card mt-4" }, { children: _jsx("div", __assign({ className: "card-body pl-3 pr-3" }, { children: _jsx("ul", __assign({ className: "trend-events" }, { children: trendEvents.slice(0, 5).map(function (event, key) {
                    return (_jsxs("li", { children: [_jsxs(Link, __assign({ to: "/iddaa/".concat(event.sportSlug, "/tumu/").concat(event.competitionId, "/"), className: "competition" }, { children: [event.competitionGroupName, ", ", event.competitionName.replace(event.competitionGroupName, '')] }), void 0), _jsx(Link, __assign({ to: "/iddaa/".concat(event.sportSlug, "/tumu/").concat(event.competitionId, "/").concat(event.eventId, "/"), className: "event-name" }, { children: event.eventName }), void 0)] }, key));
                }) }), void 0) }), void 0) }), void 0));
}
