var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { tjkBetSlipOptions, tjkSortedBetTypes } from "../tjk.store";
import { SortableList } from "../components/tjk.betslip";
export function HorseSortable(props) {
    var showRun = props.showRun;
    var addFromSelect = props.addFromSelect;
    var itemOrder = props.itemOrder;
    var onSortEnd = props.onSortEnd;
    if (![50, 11, 53, 56, 63].includes(tjkBetSlipOptions.bettingType.id)) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs(_Fragment, { children: [_jsxs("select", __assign({ className: "horseSelect mb-2", defaultValue: "-1", onChange: function (e) {
                    if (e.target.value !== '-1') {
                        addFromSelect(e.target.value);
                        e.target.value = '-1';
                    }
                } }, { children: [_jsx("option", __assign({ value: '-1' }, { children: "At Se\u00E7iniz" }), void 0), showRun.horses.filter(function (h) { return h.run_status === true && !itemOrder.includes(h.no + ' - ' + h.name); }).map(function (horse, key) {
                        return (_jsxs("option", __assign({ value: "".concat(horse.no, " - ").concat(horse.name) }, { children: [horse.no, " - ", horse.name] }), "horse-select-".concat(key)));
                    })] }), void 0), tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ?
                _jsx(SortableList, { addFromSelect: addFromSelect, items: itemOrder, pressDelay: 200, onSortEnd: onSortEnd }, void 0)
                : _jsx(_Fragment, {}, void 0)] }, void 0));
}
