var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { ApiService } from "../../lib/http.service";
import iconFather from "../../assets/feather/father-at-yarisi.svg";
import _ from "lodash";
import { useForceUpdate } from "../../lib/misc.functions";
import { env } from "../../definitions/global.vars";
var reloadDataTimer = null;
export var AGFData = [];
export function TjkAgf() {
    var api = new ApiService();
    var forceUpdate = useForceUpdate();
    var _a = useState([]), results = _a[0], setResults = _a[1];
    var _b = useState(null), hippodrome = _b[0], setHippodrome = _b[1];
    var _c = useState(0), shownBetType = _c[0], setShownBetType = _c[1];
    var _d = useState(0), shownRun = _d[0], setShownRun = _d[1];
    var width = window.innerWidth;
    var isMobile = width < 986 ? true : false;
    var LoadData = function (isFirst) {
        if (isFirst === void 0) { isFirst = false; }
        if (isFirst === true && AGFData.length !== 0) {
            setHippodrome(AGFData[0]);
            setShownBetType(0);
            if (AGFData[0].wagers &&
                AGFData[0].wagers.length > 0 &&
                AGFData[0].wagers[0].runs &&
                AGFData[0].wagers[0].runs.length > 0) {
                setShownRun(AGFData[0].wagers[0].runs[0].no);
            }
            // @ts-ignore
            document.getElementById('tjkLoading').style.display = 'none';
        }
        else {
            api.start('get', env.tjk_api + "/api/agf/", null, true).then(function (result) {
                // console.log(result)
                if (result.status) {
                    result.data.filter(function (el) {
                        el.opening_date = new Date(el.date.split('T')[0] + 'T' + el.wagers[0].time.split(':').slice(0, 3).join(':'));
                        return el;
                    });
                    if (AGFData.length === 0) {
                        Object.assign(AGFData, _.orderBy(result.data, ['opening_date']));
                        setHippodrome(AGFData[0]);
                        setShownBetType(0);
                        if (AGFData[0].wagers &&
                            AGFData[0].wagers.length > 0 &&
                            AGFData[0].wagers[0].runs &&
                            AGFData[0].wagers[0].runs.length > 0) {
                            setShownRun(AGFData[0].wagers[0].runs[0].no);
                        }
                    }
                    else {
                        var _loop_1 = function (newHippodrome) {
                            newHippodrome.opening_date = new Date(newHippodrome.date.split('T')[0] + 'T' + newHippodrome.wagers[0].time.split(':').slice(0, 3).join(':'));
                            var exHippodrome = AGFData.find(function (el) { return el.id === newHippodrome.id; });
                            if (exHippodrome) {
                                for (var _b = 0, _c = Object.entries(newHippodrome); _b < _c.length; _b++) {
                                    var _d = _c[_b], key = _d[0], value = _d[1];
                                    if (!['wagers'].includes(key) && exHippodrome[key] !== value) {
                                        exHippodrome[key] = value;
                                    }
                                    else if (key === 'wagers') {
                                        if (newHippodrome.wagers) {
                                            var _loop_2 = function (newWager) {
                                                var exWager = exHippodrome.wagers.find(function (el) { return el.wager === newWager.wager; });
                                                if (exWager) {
                                                    for (var _g = 0, _h = Object.entries(newWager); _g < _h.length; _g++) {
                                                        var _j = _h[_g], wkey = _j[0], wValue = _j[1];
                                                        if (exWager[wkey] !== wValue) {
                                                            exWager[wkey] = wValue;
                                                        }
                                                    }
                                                }
                                                else {
                                                    // koşu eklenecek
                                                    exHippodrome.wagers.push(newWager);
                                                }
                                            };
                                            for (var _e = 0, _f = newHippodrome.wagers; _e < _f.length; _e++) {
                                                var newWager = _f[_e];
                                                _loop_2(newWager);
                                            }
                                        }
                                        else {
                                            exHippodrome['wagers'] = [];
                                        }
                                    }
                                }
                            }
                            else {
                                AGFData.push(newHippodrome);
                            }
                        };
                        for (var _i = 0, _a = result.data; _i < _a.length; _i++) {
                            var newHippodrome = _a[_i];
                            _loop_1(newHippodrome);
                        }
                    }
                    forceUpdate();
                }
                // @ts-ignore
                document.getElementById('tjkLoading').style.display = 'none';
            });
        }
    };
    useEffect(function () {
        // @ts-ignore
        document.getElementById('tjkLoading').style.display = 'block';
        LoadData(true);
        reloadDataTimer = setInterval(function () {
            LoadData();
        }, 3000);
        return function () {
            clearInterval(reloadDataTimer);
        };
    }, []);
    var changeHippodrome = function (hippodrome) {
        setHippodrome(hippodrome);
        setShownBetType(0);
        if (hippodrome.wagers &&
            hippodrome.wagers.length > 0 &&
            hippodrome.wagers[0].runs &&
            hippodrome.wagers[0].runs.length > 0) {
            setShownRun(hippodrome.wagers[0].runs[0].no);
        }
    };
    var getMinimalName = function (name) {
        if (name.split(' ').length > 1) {
            var firstChar = name.charAt(0);
            return firstChar + '. ' + name.split(' ').slice(1, name.split(' ').length).join(' ');
        }
        else {
            return name;
        }
    };
    var eqStyles = {
        "1": { transform: 'scaleX(-1)', marginLeft: 5, filter: 'brightness(0)' },
        "2": { transform: 'scaleX(-1)', marginLeft: 5, filter: 'invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)' },
        "3": { transform: 'scaleX(-1)', marginLeft: 5 },
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "header clearfix pl-sm-2 pl-lg-0 mt-sm-0" }, { children: _jsx("div", __assign({ style: isMobile ? { position: 'relative', zIndex: 1, overflowX: 'scroll', overflowY: 'hidden' } : {} }, { children: hippodrome ? _jsx("ul", __assign({ className: "locationtab clearfix mb-lg-2 float-left", style: { width: 'max-content' } }, { children: AGFData.map(function (data, key) {
                            return (_jsx("li", __assign({ className: "cursor-pointer tjklocation " + (hippodrome.hippodrome === data.hippodrome ? 'activeTab' : ''), id: "location" + key, onClick: function () { return changeHippodrome(data); } }, { children: data.location }), "hipoodrome-agf-".concat(key)));
                        }) }), void 0) : null }), void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0), _jsxs("div", __assign({ className: "runs" }, { children: [hippodrome && hippodrome.wagers ? _jsx(_Fragment, { children: _jsx("ul", __assign({ className: "runstab d-flex" }, { children: Array.from(Array(hippodrome.wagers.length).keys()).map(function (run, wkey) {
                                return (_jsxs("li", __assign({ className: "cursor-pointer ".concat(hippodrome.wagers.length === 1 ? 'w-100' : 'w-50 float-left', " ").concat(run == shownBetType ? 'active' : ''), id: "location" + wkey, onClick: function () {
                                        setShownBetType(wkey);
                                        setShownRun(hippodrome.wagers[wkey].runs[0].no);
                                    } }, { children: [wkey + 1, ". 6'l\u0131"] }), "hipoodrome-agf-wagers-".concat(wkey)));
                            }) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), hippodrome && hippodrome.wagers ? _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row agf-tables" }, { children: [hippodrome.wagers[shownBetType].runs.map(function (r, key) {
                                    return (_jsx("div", __assign({ className: "col-2 col-lg-2" }, { children: _jsxs("div", __assign({ className: "run-no", style: isMobile && shownRun === r.no ? { color: 'var(--color-text-lighter)', backgroundColor: 'var(--color-bg-dark)' } : {}, onClick: function () { setShownRun(r.no); } }, { children: [key + 1, ".", !isMobile && _jsx("span", { children: " Ayak" }, void 0)] }), void 0) }), "agf-runs-".concat(key)));
                                }), hippodrome.wagers[shownBetType].runs.map(function (r, key) {
                                    var runFalseHorses = [];
                                    return (_jsx(React.Fragment, { children: shownRun === r.no || !isMobile ?
                                            _jsxs("div", __assign({ className: "".concat(isMobile ? 'w-100' : 'col-2 col-lg-2'), style: isMobile ? { paddingLeft: 15, paddingRight: 15 } : {} }, { children: [_jsxs("div", __assign({ className: "horse-header d-flex", style: isMobile ? { color: 'var(--color-text-lighter)', backgroundColor: 'var(--color-bg-dark)' } : {} }, { children: [_jsx("div", __assign({ className: "".concat(isMobile ? 'w-5' : 'w-25', " text-center") }, { children: "#" }), void 0), _jsx("div", __assign({ className: "".concat(isMobile ? 'w-65' : 'w-75', " text-center") }, { children: "At No" }), void 0), isMobile && _jsx("div", __assign({ className: "w-30 text-center" }, { children: "Jokey" }), void 0)] }), void 0), r.horses.map(function (h, key) {
                                                        if (h.stablemate !== null) {
                                                            h.stablemate.map(function (s) {
                                                                if (s.run_status === false) {
                                                                    s.sstyle = eqStyles[h.stablemate[0].stablemate];
                                                                    runFalseHorses.push(s);
                                                                }
                                                            });
                                                        }
                                                        return (_jsxs("div", __assign({ className: "horse-no d-flex ".concat(h.agf_order === 1 ? 'text-success' : '') }, { children: [_jsx("div", __assign({ className: "".concat(isMobile ? 'w-5' : 'w-25', " text-center") }, { children: h.agf_order }), void 0), _jsxs("div", __assign({ className: "".concat(isMobile ? 'w-65 bgLight' : 'w-75', " text-left") }, { children: [h.no, " ", isMobile && h.name, " - (%", h.agf_ratio, ") ", h.stablemate !== null ? _jsx("img", { src: iconFather, className: "".concat(false && h.run_status === false && 'black-to-red'), style: eqStyles[h.stablemate[0].stablemate] }, void 0) : _jsx(_Fragment, {}, void 0), " ", h.run_status === false ? _jsx(_Fragment, { children: _jsx("span", __assign({ className: "text-danger" }, { children: "(K)" }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), h.stablemate !== null ? _jsx(_Fragment, { children: h.stablemate.filter(function (c) { return c.run_status === true; }).map(function (s, k) {
                                                                                return (_jsxs("span", __assign({ className: "".concat(isMobile ? ' d-block ' : '', " stablemate") }, { children: [s.no, " ", isMobile && s.name, " - (%", s.agf_ratio, ") ", _jsx("img", { src: iconFather, className: "".concat(false && s.run_status === false && 'black-to-red'), style: eqStyles[h.stablemate[0].stablemate] }, void 0), " ", s.run_status === false ? _jsx(_Fragment, { children: _jsx("span", __assign({ className: "text-danger" }, { children: "(K)" }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), "item-agf-stablemate-".concat(k, "-").concat(key)));
                                                                            }) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0), isMobile && _jsx("div", __assign({ className: "w-30 text-left" }, { children: getMinimalName(h.jockey) }), void 0)] }), "agf-horses-".concat(key)));
                                                    }), runFalseHorses.map(function (h, sskey) {
                                                        return (_jsxs("div", __assign({ className: "horse-no d-flex ".concat(h.agf_order === 1 ? 'text-success' : '') }, { children: [_jsx("div", __assign({ className: "".concat(isMobile ? 'w-5' : 'w-25', " text-center") }, { children: "-" }), void 0), _jsxs("div", __assign({ className: "".concat(isMobile ? 'w-65 bgLight' : 'w-75', " text-left") }, { children: [h.no, " ", isMobile && h.name, " - (%", h.agf_ratio, ") ", _jsx("img", { src: iconFather, className: "".concat(false && h.run_status === false && 'black-to-red'), style: h.sstyle }, void 0), "  ", _jsx("span", __assign({ className: "text-danger" }, { children: "(K)" }), void 0)] }), void 0), isMobile && _jsx("div", __assign({ className: "w-30 text-left" }, { children: getMinimalName(h.jockey) }), void 0)] }), "agf-horses-".concat(sskey)));
                                                    })] }), "agf-runs-".concat(key)) : _jsx(_Fragment, {}, void 0) }, "agf-runs-r-".concat(key)));
                                })] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0)] }, void 0));
}
