var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Menu from "./widgets/menu";
import SubPageSelected from "./widgets/selected";
import "./account.css";
import { member } from "../../store/login.store";
import { Storage } from "../../lib/localstorege.service";
import { modalServiceSubject } from "../../components/modal/modal";
import { betSlipVisibleSubject } from "../../store/betslip.store";
export default function Account() {
    var params = useParams();
    var navigator = useNavigate();
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    var waitForFill = function (myList, tries) {
        if (tries === void 0) { tries = 0; }
        if (myList === undefined && tries < 100) {
            setTimeout(function () {
                waitForFill(member.id, tries += 1);
            }, 100);
        }
        else {
            if (member.id === undefined || Storage.get('token') === null) {
                setTimeout(function () {
                    modalServiceSubject.next({
                        title: 'Giriş Yapınız',
                        content: 'Bu sayfayı görüntüleyebilmeniz için önce giriş yapmanız gerekir.',
                        confirm: {
                            sure: {
                                label: 'Giriş Yap',
                                action: function () {
                                    navigator('/giris-yap');
                                }
                            },
                            cancel: {
                                label: 'Üye Ol',
                                action: function () {
                                    navigator('/kayit');
                                }
                            }
                        }
                    });
                }, 50);
                navigator('/');
            }
        }
    };
    if (member.id === undefined && Storage.get('token') !== null) {
        waitForFill(member.id);
    }
    if (member.id) {
        return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "container-fluid page account-page" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "left-column" }, { children: [_jsxs("div", __assign({ className: "profile" }, { children: [_jsxs("h2", { children: [_jsx("span", __assign({ className: "member-id" }, { children: member.id }), void 0), _jsx("span", __assign({ className: "first-name" }, { children: member.first_name }), void 0), _jsx("span", __assign({ className: "last-name" }, { children: member.last_name }), void 0)] }, void 0), _jsxs("div", __assign({ className: "balances row" }, { children: [_jsx("div", __assign({ className: "col-sm-6 pr-1" }, { children: _jsxs("div", __assign({ className: "balance" }, { children: [_jsx("span", { children: "TL Bakiye" }, void 0), parseFloat(member.debit_balance).toFixed(2), " \u20BA"] }), void 0) }), void 0), _jsx("div", __assign({ className: "col-sm-6 pl-1" }, { children: _jsxs("div", __assign({ className: "balance" }, { children: [_jsx("span", { children: "OP Bakiye" }, void 0), (parseFloat(member.point_balance) / 100).toFixed(2), " \u20BA"] }), void 0) }), void 0)] }), void 0)] }), void 0), _jsx(Menu, {}, void 0)] }), void 0), _jsx("div", __assign({ className: "right-column" }, { children: _jsx(SubPageSelected, { params: params, subpage: params.subpage }, void 0) }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ id: "accountPageLoading" }, { children: _jsxs("div", __assign({ className: "lds-roller" }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }), void 0)] }, void 0));
    }
    return _jsx(_Fragment, {}, void 0);
}
