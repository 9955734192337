var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import "./sosyoley.css";
import Posts from "./subpages/posts";
import { useParams } from "react-router-dom";
import { SosyoleyMenu } from "./widgets/menu";
import { TrendEvents } from "./widgets/trends";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import AlignLeft from '../../assets/feather/align-right.svg';
import LeaderBoards from './subpages/leader.boards';
import Sosyoleyciler from './subpages/sosyoleyciler';
import { SearchMember } from "./components/search.member";
import Editors from './subpages/editors';
export default function Sosyoley() {
    var params = useParams();
    var _a = useState(null), contentType = _a[0], setContentType = _a[1];
    var width = window.innerWidth;
    document.body.style.overflow = 'unset';
    if (contentType !== (params.contentType || params.memberId)) {
        setContentType((params.contentType || params.memberId));
    }
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible, params.subpage]);
    var pages = {
        'zaman-tuneli': _jsx(Posts, { subpage: params.subpage, parentParams: params, contentType: { contentType: contentType, setContentType: setContentType } }, void 0),
        'takip-ettiklerimin-paylasimlari': _jsx(Posts, { parentParams: params, subpage: params.subpage, contentType: { contentType: contentType, setContentType: setContentType } }, void 0),
        'profilim': _jsx(Posts, { subpage: params.subpage, parentParams: params, contentType: { contentType: contentType, setContentType: setContentType } }, void 0),
        'profil': _jsx(Posts, { subpage: params.subpage, parentParams: params, contentType: { contentType: contentType, setContentType: setContentType } }, void 0),
        'bildirimler': _jsx(_Fragment, {}, void 0),
        'zirvedekiler': _jsx(LeaderBoards, {}, void 0),
        'en-populer-kuponlar': _jsx(Posts, { subpage: params.subpage, parentParams: params, contentType: { contentType: contentType, setContentType: setContentType } }, void 0),
        'en-cok-oynanan-oyunlar': _jsx(_Fragment, {}, void 0),
        'sosyoleyciler': _jsx(Sosyoleyciler, {}, void 0),
        'yazarlar': _jsx(Editors, {}, void 0),
        'anasayfa': _jsx(_Fragment, { children: _jsx(Posts, { subpage: params.subpage, parentParams: params, contentType: { contentType: contentType, setContentType: setContentType } }, void 0) }, void 0),
    };
    var pageTitles = {
        'zaman-tuneli': 'Zaman Tüneli',
        'takip-ettiklerimin-paylasimlari': 'Takip Ettiklerim',
        'profilim': 'Profil',
        'profil': 'Profil',
        'bildirimler': 'Bildirimlerim',
        'zirvedekiler': 'Zirvedekiler',
        'en-populer-kuponlar': 'En Popüler Kuponlar',
        'en-cok-oynanan-oyunlar': 'En çok Oynanan Bahisler',
        'sosyoleyciler': 'Sosyoleyciler',
        'anasayfa': 'Anasayfa',
        'yazarlar': 'Yazarlar',
    };
    useEffect(function () {
        var sosyoleyPage = document.getElementById("sosyoleyPage");
        if (width > 968) {
            betSlipVisibleSubject.next(true);
            if (sosyoleyPage) {
                sosyoleyPage.classList.add('betslip-visible');
            }
        }
        else {
            if (sosyoleyPage) {
                sosyoleyPage.classList.remove('betslip-visible');
            }
        }
    }, []);
    if (!params.subpage) {
        return _jsx(_Fragment, {}, void 0);
    }
    function menuSelected() {
        var element = document.getElementById("menuHamburger");
        if (element.style.display == "none") {
            element.style.display = "inline";
        }
        else {
            element.style.display = "none";
        }
    }
    return (_jsx("div", __assign({ className: "container-fluid page sosyoley" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: 'sub-menu visible-sm pb-5' }, { children: [_jsx("div", __assign({ className: "title" }, { children: pageTitles[params.subpage] }), void 0), _jsx(SearchMember, { type: "mobile" }, void 0), _jsx("div", __assign({ className: "toggler", onClick: function () { return menuSelected(); } }, { children: _jsx("img", { className: "ico", src: AlignLeft, alt: "Men\u00FC" }, void 0) }), void 0), _jsx("div", __assign({ onClick: function () { return menuSelected(); }, className: "menu-body", id: "menuHamburger" }, { children: _jsx(SosyoleyMenu, {}, void 0) }), void 0)] }), void 0), width >= 768 ? _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "left-column" }, { children: [_jsx(SosyoleyMenu, {}, void 0), _jsx(TrendEvents, {}, void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsxs("div", __assign({ id: "sosyoleyPage", className: "right-column" }, { children: [_jsxs("div", __assign({ id: "innerLoading" }, { children: [_jsx("div", __assign({ className: "label" }, { children: "Oley" }), void 0), _jsxs("div", __assign({ className: "lds-roller" }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0)] }), void 0), params.subpage ? pages[params.subpage] : null] }), void 0)] }), void 0) }), void 0));
}
