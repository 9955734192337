var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Title = function () {
    return (_jsxs("div", __assign({ className: "support-title" }, { children: [_jsx("div", { children: "Tarih \u00A0" }, void 0), _jsx("div", { children: "\u00C7ekili\u015F Tarihi\u00A0" }, void 0), _jsx("div", { children: "\u00C7ekili\u015F\u00A0" }, void 0), _jsx("div", { children: "Bilet Bedeli\u00A0" }, void 0), _jsx("div", { children: "Bilet No\u00A0" }, void 0), _jsx("div", { children: "Seri No\u00A0" }, void 0)] }), void 0));
};
var TableWidget = function (props) {
    return (_jsxs("div", { children: [_jsx(Title, {}, void 0), props.iddiaData
                .map(function (data, key) {
                var prop = data.display_label.split(";");
                return (_jsxs("div", { children: [_jsx("div", { children: prop[0] }, void 0), _jsx("div", { children: prop[1] }, void 0), _jsx("div", { children: prop[2] }, void 0), _jsxs("div", { children: [prop[3], " ", prop[4]] }, void 0), _jsx("div", { children: prop[5] }, void 0), _jsx("div", { children: prop[6] }, void 0)] }, "accountMilliPiyango" + key));
            })] }, void 0));
};
export default TableWidget;
