var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Storage } from "../../../lib/localstorege.service";
import { initialLeftMenu, sportsMenu, } from "../../../lib/misc.functions";
import { env } from "../../../definitions/global.vars";
import { Api } from "../../register/register.api";
import "../../../assets/css/sport.color.icon.css";
import { SosyoleyUserInfo } from "../components/sosyoley.user.info";
export var editorMemberInfo = [];
var Editors = function () {
    var _a = useState("-followers_count"), status = _a[0], setStatus = _a[1];
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card social-profile" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("div", __assign({ className: 'd-flex' }, { children: _jsx("h2", __assign({ className: "w-100 m-0 d-md-flex d-none", style: { borderBottom: '1px solid var(--color-bg-medium)' } }, { children: " Yazarlar " }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ id: "InfiniteScroll", className: "body p-0 " }, { children: _jsx(ListMembers, { status: status }, void 0) }), void 0)] }), void 0) }, void 0));
};
export default Editors;
export function ListMembers(props) {
    var params = props;
    var _a = useState([]), editors = _a[0], setEditors = _a[1];
    useEffect(function () {
        var member_id = "";
        Api.start('get', env.cdn_url + '/json/editors.json', null, false).then(function (response) {
            if (response.status) {
                var member_id_1 = "";
                response.data.map(function (ed) {
                    if (member_id_1 != "") {
                        member_id_1 = member_id_1 + "," + ed.member_id;
                    }
                    else {
                        member_id_1 = ed.member_id;
                    }
                });
                var data = {
                    member_id: member_id_1,
                    token: Storage.get('token')
                };
                Api.start('post', env.accounting_api + '/misc/api/get-member-info/', data, true).then(function (result) {
                    if (result.status) {
                        response.data.map(function (ed) {
                            var member = result.data.find(function (x) { return x.member_id == ed.member_id; });
                            if (member) {
                                ed.post_count = member.post_count;
                                ed.shared_coupon_count = member.shared_coupon_count;
                                ed.following_count = member.following_count;
                                ed.followers_count = member.followers_count;
                            }
                        });
                        response.data = response.data.filter(function (x) { return x.member_id !== 12152365; });
                        setEditors(response.data);
                    }
                });
            }
        });
    }, []);
    //
    // // @ts-ignore
    // memberStatus = params.status;
    // const fetchData = (isFirst: any = false, forcePage: any = null, top: any = null) => {
    //     // @ts-ignore
    //     document.getElementById('loadingAnimation').style.display = 'block';
    //     hasMore = false;
    //     if (isFirst) {
    //         membersData = [];
    //         page = forcePage !== null ? forcePage : 0;
    //         hasMore = true;
    //     }
    //
    //     let data: any;
    //     if (memberStatus) {
    //         data = {
    //             ordering: memberStatus,
    //             page: page,
    //             token: Storage.get('token')
    //         };
    //     }
    //     else {
    //         data = {
    //             page: page,
    //             token: Storage.get('token')
    //         };
    //     }
    //
    //     // @ts-ignore
    //     const objectHeight = document.getElementById('InfiniteScroll').offsetHeight;
    //     // @ts-ignore
    //     const objectTop = document.getElementById('InfiniteScroll').offsetTop;
    //     Api.start('post', env.accounting_api + '/misc/api/get-member-info/', data, true).then(
    //         (result: any) => {
    //             // @ts-ignore
    //             document.getElementById('loadingAnimation').style.display = 'none';
    //
    //             if (result.status) {
    //                 if (result.data.length < 10) {
    //                     hasMore = false;
    //                     lockScroll = true;
    //                 } else {
    //                     hasMore = true;
    //                 }
    //                 page += 1;
    //
    //                 // membersData = membersData.concat(result.data);
    //                 forceUpdate();
    //                 if (!isFirst) {
    //                     if (top === 0) {
    //                         window.scrollTo(0, top)
    //                     } else {
    //                         const threshold = window.outerHeight < 700 ? 600 : 700;
    //                         window.scrollTo(0, (objectHeight - objectTop - threshold))
    //                     }
    //                 }
    //                 timer = setTimeout(() => {
    //                     if (result.data.length >= 10) {
    //                         lockScroll = false;
    //                         if (membersData.length === 10 && objectHeight < window.outerHeight) {
    //                             fetchData(false, 1, 0)
    //                         }
    //                     }
    //                     triggerScroll();
    //                 }, 500);
    //
    //             } else {
    //                 modalServiceSubject.next({
    //                     title: 'beklenmedik bir sorun',
    //                     content: result.message,
    //                     confirm: {
    //                         cancel: { label: 'Tamam' }
    //                     },
    //                     timeout: 3000,
    //                 })
    //             }
    //         }
    //     )
    //
    // }
    // useEffect(() => {
    //     window.addEventListener('scroll', triggerScroll)
    //     return () => {
    //         clearTimeout(timer);
    //         window.removeEventListener('scroll', triggerScroll)
    //     }
    // }, [])
    // const triggerScroll = () => {
    //     const threshold = window.outerHeight < 968 ? 100 : 150;
    //     // @ts-ignore
    //     const objectHeight = document.getElementById('InfiniteScroll').offsetHeight;
    //     // @ts-ignore
    //     const objectTop = document.getElementById('InfiniteScroll').offsetTop;
    //     const visibleZone = (window.outerHeight - objectTop - threshold)
    //     if (objectHeight > visibleZone) {
    //         if ((objectHeight - visibleZone) < window.scrollY && !lockScroll) {
    //             lockScroll = true;
    //             fetchData(false);
    //         }
    //     }
    // }
    return (_jsx(_Fragment, { children: editors.map(function (memberInfo, key) {
            return (_jsx("div", { children: _jsx(SosyoleyUserInfo, { memberInfo: memberInfo, type: "editor" }, void 0) }, key + "editors"));
        }) }, void 0));
}
