var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ApiService } from "../../lib/http.service";
import iconFather from "../../assets/feather/father-at-yarisi.svg";
import _ from "lodash";
import { env } from "../../definitions/global.vars";
var reloadDataTimer = null;
export var MuhtemellerData = [];
export function TjkMuhtemeller() {
    var api = new ApiService();
    var _a = useState([]), results = _a[0], setResults = _a[1];
    var _b = useState(null), hippodrome = _b[0], setHippodrome = _b[1];
    var _c = useState(0), shownBetType = _c[0], setShownBetType = _c[1];
    var _d = useState(0), shownRun = _d[0], setShownRun = _d[1];
    var width = window.innerWidth;
    var isMobile = width < 986 ? true : false;
    useEffect(function () {
        // @ts-ignore
        document.getElementById('tjkLoading').style.display = 'block';
        LoadData(true);
        reloadDataTimer = setInterval(function () {
            LoadData();
        }, 3000);
        return function () {
            clearInterval(reloadDataTimer);
        };
    }, []);
    var LoadData = function (isFirst) {
        if (isFirst === void 0) { isFirst = false; }
        if (isFirst === true && MuhtemellerData.length !== 0) {
            setHippodrome(MuhtemellerData[0]);
            setShownBetType(0);
            if (MuhtemellerData[0].wagers &&
                MuhtemellerData[0].wagers.length > 0 &&
                MuhtemellerData[0].wagers[0].runs &&
                MuhtemellerData[0].wagers[0].runs.length > 0) {
                setShownRun(MuhtemellerData[0].wagers[0].runs[0].no);
            }
            // @ts-ignore
            document.getElementById('tjkLoading').style.display = 'none';
        }
        else {
            api.start('get', env.tjk_api + '/api/possibles/', null, true).then(function (result) {
                // console.log(result)
                if (result.status) {
                    if (MuhtemellerData.length === 0) {
                        Object.assign(MuhtemellerData, result.data);
                        if (MuhtemellerData.length > 0) {
                            setHippodrome(MuhtemellerData[0]);
                        }
                    }
                    else {
                        var _loop_1 = function (newHippodrome) {
                            var exHippodrome = MuhtemellerData.find(function (el) { return el.id === newHippodrome.id; });
                            if (exHippodrome) {
                                for (var _b = 0, _c = Object.entries(newHippodrome); _b < _c.length; _b++) {
                                    var _d = _c[_b], key = _d[0], value = _d[1];
                                    if (!['runs'].includes(key) && exHippodrome[key] !== value) {
                                        exHippodrome[key] = value;
                                    }
                                    else if (key === 'runs') {
                                        if (newHippodrome.runs) {
                                            var _loop_2 = function (newRun) {
                                                var exRun = exHippodrome.runs.find(function (el) { return el.no === newRun.no; });
                                                if (exRun) {
                                                    for (var _g = 0, _h = Object.entries(newRun); _g < _h.length; _g++) {
                                                        var _j = _h[_g], rkey = _j[0], rValue = _j[1];
                                                        if (!['horses'].includes(rkey) && exRun[rkey] !== rValue) {
                                                            exRun[rkey] = rValue;
                                                        }
                                                        else if ('horses' === rkey) {
                                                            if (newRun.horses) {
                                                                var _loop_3 = function (newHorse) {
                                                                    var exHorse = exRun.horses.find(function (el) { return el.no === newHorse.no; });
                                                                    if (exHorse) {
                                                                        for (var _m = 0, _o = Object.entries(newHorse); _m < _o.length; _m++) {
                                                                            var _p = _o[_m], hkey = _p[0], hValue = _p[1];
                                                                            if (exHorse[hkey] !== hValue) {
                                                                                exHorse[hkey] = hValue;
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        // at ekle
                                                                        if (newHorse.run_status) {
                                                                            exRun.horses.push(newHorse);
                                                                        }
                                                                    }
                                                                };
                                                                for (var _k = 0, _l = newRun.horses; _k < _l.length; _k++) {
                                                                    var newHorse = _l[_k];
                                                                    _loop_3(newHorse);
                                                                }
                                                            }
                                                            else {
                                                                exRun['horses'] = [];
                                                            }
                                                        }
                                                    }
                                                }
                                                else {
                                                    // koşu eklenecek
                                                    if (Number(newRun.status) === 0) {
                                                        exHippodrome.runs.push(newRun);
                                                    }
                                                }
                                            };
                                            for (var _e = 0, _f = newHippodrome.runs; _e < _f.length; _e++) {
                                                var newRun = _f[_e];
                                                _loop_2(newRun);
                                            }
                                        }
                                        else {
                                            exHippodrome['runs'] = [];
                                        }
                                    }
                                }
                            }
                            else {
                                MuhtemellerData.push(newHippodrome);
                            }
                        };
                        for (var _i = 0, _a = result.data; _i < _a.length; _i++) {
                            var newHippodrome = _a[_i];
                            _loop_1(newHippodrome);
                        }
                    }
                }
                // @ts-ignore
                document.getElementById('tjkLoading').style.display = 'none';
            });
        }
    };
    var changeHippodrome = function (hippodrome) {
        setHippodrome(hippodrome);
        setShownBetType(0);
        var acikBahisler = hippodrome.runs.findIndex(function (r) { return r.status === 'AÇIK'; });
        setShownRun(acikBahisler > -1 ? acikBahisler : (hippodrome.runs.length - 1));
    };
    useEffect(function () {
        var allHorses = document.getElementsByClassName("wager-list");
        if (allHorses) {
            for (var _i = 0, allHorses_1 = allHorses; _i < allHorses_1.length; _i++) {
                var e = allHorses_1[_i];
                e.style.backgroundColor = 'unset';
            }
        }
    }, [hippodrome, shownRun]);
    var colorHorses = function (h1, h2) {
        var allHorses = document.getElementsByClassName("wager-list");
        if (allHorses) {
            for (var _i = 0, allHorses_2 = allHorses; _i < allHorses_2.length; _i++) {
                var e = allHorses_2[_i];
                e.style.backgroundColor = 'unset';
            }
        }
        if (h2 === null) {
            var fisrtHorses = document.getElementsByClassName("first-horse-".concat(h1));
            if (fisrtHorses) {
                for (var _a = 0, fisrtHorses_1 = fisrtHorses; _a < fisrtHorses_1.length; _a++) {
                    var e = fisrtHorses_1[_a];
                    // console.log(e)
                    e.style.backgroundColor = '#CC000088';
                }
            }
            var secondHorses = document.getElementsByClassName("second-horse-".concat(h1));
            if (secondHorses) {
                for (var _b = 0, secondHorses_1 = secondHorses; _b < secondHorses_1.length; _b++) {
                    var e = secondHorses_1[_b];
                    // console.log(e)
                    e.style.backgroundColor = '#CC000044';
                }
            }
        }
        else {
            var fisrtHorses = document.getElementsByClassName("first-horse-".concat(h1, " second-horse-null"));
            if (fisrtHorses) {
                for (var _c = 0, fisrtHorses_2 = fisrtHorses; _c < fisrtHorses_2.length; _c++) {
                    var e = fisrtHorses_2[_c];
                    // console.log(e)
                    e.style.backgroundColor = '#CC000088';
                }
            }
            var secondHorses = document.getElementsByClassName("first-horse-".concat(h2, " second-horse-null"));
            if (secondHorses) {
                for (var _d = 0, secondHorses_2 = secondHorses; _d < secondHorses_2.length; _d++) {
                    var e = secondHorses_2[_d];
                    // console.log(e)
                    e.style.backgroundColor = '#CC000044';
                }
            }
            var overHorses = document.getElementsByClassName("first-horse-".concat(h1, " second-horse-").concat(h2));
            if (overHorses) {
                for (var _e = 0, overHorses_1 = overHorses; _e < overHorses_1.length; _e++) {
                    var e = overHorses_1[_e];
                    e.style.backgroundColor = '#CC000088';
                }
            }
        }
    };
    var eqStyles = {
        "1": { transform: 'scaleX(-1)', marginLeft: 5, filter: 'brightness(0)' },
        "2": { transform: 'scaleX(-1)', marginLeft: 5, filter: 'invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)' },
        "3": { transform: 'scaleX(-1)', marginLeft: 5 },
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "header clearfix pl-sm-2 pl-lg-0 mt-sm-0" }, { children: _jsx("div", __assign({ style: isMobile ? { position: 'relative', zIndex: 1, overflowX: 'scroll', overflowY: 'hidden' } : {} }, { children: hippodrome ? _jsx("ul", __assign({ className: "locationtab clearfix mb-lg-2 float-left", style: { width: 'max-content' } }, { children: MuhtemellerData.map(function (data, key) {
                            return (_jsx("li", __assign({ className: "cursor-pointer tjklocation " + (hippodrome.keyword === data.keyword ? 'activeTab' : ''), id: "location" + key, onClick: function () { return changeHippodrome(data); } }, { children: data.hippodrome }), key + "raceData"));
                        }) }), void 0) : null }), void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0), _jsx("div", __assign({ className: "runs" }, { children: hippodrome && hippodrome.runs ? _jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "row agf-tables w-100 d-inline-flex flex-nowrap ml-0 mr-0" }, { children: hippodrome.runs.map(function (r, hkey) {
                                return (_jsx("div", __assign({ className: "w-100" }, { children: _jsxs("div", __assign({ className: "run-no", style: shownRun === hkey ? { color: 'var(--color-text-lighter)', backgroundColor: 'var(--color-bg-dark)' } : {}, onClick: function () { setShownRun(hkey); } }, { children: [r.no, ".", !isMobile && _jsx("span", { children: " Ko\u015Fu" }, void 0)] }), void 0) }), "agf-runs-".concat(hkey)));
                            }) }), void 0), _jsx("div", { className: "clearfix" }, void 0), _jsxs("div", __assign({ className: "text-center bgDarker p-2 fw-bold" }, { children: [hippodrome.runs[shownRun].no, ". Ko\u015Fu - ", hippodrome.runs[shownRun].clock, " ", hippodrome.runs[shownRun].status === 'AÇIK' ? ' - BAHSE AÇIK' : ' - (RESMİ)'] }), void 0), _jsx("div", __assign({ className: "w-100 d-inline-flex flex-nowrap" }, { children: hippodrome.runs[shownRun].wagers.map(function (r, key) {
                                return (_jsx("div", __assign({ className: "w-100 agf-tables wager-col" }, { children: _jsxs("div", __assign({ className: "horse-no" }, { children: [_jsx("div", __assign({ className: "horse-header p-0 d-flex", style: { color: 'var(--color-text-lighter)', backgroundColor: 'var(--color-bg-dark)' } }, { children: _jsx("div", __assign({ className: "w-100 text-center" }, { children: r.wager }), void 0) }), void 0), r.possibilities.map(function (p, pkey) {
                                                var minGanyan = _.orderBy(r.possibilities, [function (a) { return Number(a.ganyan); }], ['asc'])[0];
                                                return (_jsxs("div", __assign({ className: "\n                    ".concat(key === 0 ? 'wager-list' : 'wager-list', " \n                    ").concat(key === 0 && Number(minGanyan.ganyan) === Number(p.ganyan) ? 'fw-bold text-success' : '', " \n                    first-horse-").concat(p.result1, " \n                    second-horse-").concat(p.result2, " \n                    p-1"), onMouseOver: function () {
                                                        colorHorses(p.result1, p.result2);
                                                    } }, { children: [p.result1, " - ", key !== 0 && p.result2, !isMobile && key === 0 && p.horse_name_lower, key === 0 && ["1", "2", "3"].includes(p.stablemate) ? _jsx("img", { src: iconFather, style: eqStyles[p.stablemate] }, void 0) : null, _jsx("span", __assign({ className: "float-right" }, { children: p.ganyan === '-' ? 'KOŞMAZ' : p.ganyan }), void 0)] }), "possibilities-".concat(pkey)));
                                            })] }), "agf-runs-".concat(key)) }), "agf-runs-r-".concat(key)));
                            }) }), void 0)] }, void 0) : _jsx(_Fragment, {}, void 0) }), void 0)] }, void 0));
}
