var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { initialLeftMenu, sportsMenu } from "../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../store/betslip.store";
var PuanDurumu = function () {
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    var params = useParams();
    var isMobile = window.outerHeight < 786;
    var widget_body = {
        "bundleId": ["soccer-st"],
        "accountId": "ae03a315-b8e3-454d-8985-0f59b1c8f86b",
        "widgets": {
            "soccerStandings": [{
                    "element": 'qatar2022_standings',
                    "tournamentId": 79,
                    "options": {
                        // 'theme': 'eski.oley.com' + '/assets/css/widgets' + isMobile + '/football/Euro2020Puan.css',
                        "lang": "tr-TR"
                    }
                }]
        }
    };
    useEffect(function () {
        // @ts-ignore
        if (window.broadageWidget.has('qatar2022_standings')) {
            // @ts-ignore
            window.broadageWidget.remove('qatar2022_standings');
        }
        // @ts-ignore
        window.broadageWidget.insert(widget_body);
    }, [params]);
    useEffect(function () {
        // @ts-ignore
        if (window.broadageWidget.has('qatar2022_standings')) {
            // @ts-ignore
            window.broadageWidget.remove('qatar2022_standings');
        }
        // @ts-ignore
        window.broadageWidget.insert(widget_body);
    }, []);
    return (_jsxs("div", __assign({ className: 'card' }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Puan Durumu" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", { id: "qatar2022_standings" }, void 0) }), void 0)] }), void 0));
};
export default PuanDurumu;
