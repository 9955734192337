var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { availableBettingSubTypes, availableHippodromes, availableTjkBettingTypes, DailyRaces, tjkBetSlipOptions, tjkBettingTypesMap2, tjkEditor, tjkHippodromsMap } from "./tjk.store";
import { getAvailableBettingSubTypes, getAvailableBettingTypes, getAvailableHippodromes, getDailyRaces } from "./tjk.utils";
import { currencyFormat, useForceUpdate } from "../../lib/misc.functions";
import { ApiService } from "../../lib/http.service";
import { env } from "../../definitions/global.vars";
import { Storage } from "../../lib/localstorege.service";
import DefaultAvatar from "../../assets/img/avatar.png";
import { addToTJKBetSlip } from "./tjk.betslip.service";
import { useNavigate } from "react-router";
var savedCouponList = [];
var editorIds = [];
export function SavedCoupons() {
    var forceUpdate = useForceUpdate();
    var _a = useState(null), hippodrome = _a[0], setHippodrome = _a[1];
    var _b = useState(0), shownBetType = _b[0], setShownBetType = _b[1];
    var _c = useState(null), selectedEditor = _c[0], setSelectedEditor = _c[1];
    var navigate = useNavigate();
    var width = window.innerWidth;
    var isMobile = width < 986 ? true : false;
    useEffect(function () {
        console.log(DailyRaces);
        if (DailyRaces.length === 0) {
            getDailyRaces().then(function () {
                getAvailableHippodromes();
                getCoupons();
            });
        }
        else {
            getCoupons();
        }
    }, []);
    var changeHippodrome = function (shippodrome) {
        setHippodrome(shippodrome);
        if (shippodrome === null) {
            setSelectedEditor(null);
        }
        getCoupons();
    };
    var getCoupons = function () {
        var availableHippodromeIds = availableHippodromes.map(function (h) { return h._date + '__' + h.card_id; });
        var api = new ApiService();
        var data = {
            token: Storage.get('token'),
            status: 6,
            page: 1
        };
        api.start('post', env.core_api + "/order/api/tjk/get-coupons/", data, true)
            .then(function (res) {
            if (res.status) {
                var availableCoupons = res.data.data.filter(function (c) { return availableHippodromeIds.includes(c.card.split('-')[0] + '__' + c.card_id); });
                var playableCoupons = [];
                var _loop_1 = function (c) {
                    var isAvailable = true;
                    var hippodromeObject = availableHippodromes.find(function (h) { return Number(h._date) === Number(c.card.split('-')[0]) && Number(h.card_id) === Number(c.card_id); });
                    c.hippodromeObject = hippodromeObject;
                    var _loop_2 = function (i, value) {
                        var runObject = hippodromeObject.runs.find(function (r) { return Number(r.no) === Number(c.race_id) + Number(i); });
                        if (runObject.status !== 0) {
                            isAvailable = false;
                        }
                    };
                    for (var _a = 0, _b = c.legs.entries(); _a < _b.length; _a++) {
                        var _c = _b[_a], i = _c[0], value = _c[1];
                        _loop_2(i, value);
                    }
                    if (isAvailable) {
                        playableCoupons.push(c);
                    }
                };
                for (var _i = 0, availableCoupons_1 = availableCoupons; _i < availableCoupons_1.length; _i++) {
                    var c = availableCoupons_1[_i];
                    _loop_1(c);
                }
                savedCouponList.splice(0, savedCouponList.length);
                savedCouponList.push.apply(savedCouponList, playableCoupons);
                editorIds = __spreadArray([], Array.from(new Set(playableCoupons.map(function (c) { return c.member_id; }))), true);
                forceUpdate();
            }
        });
    };
    var filterEditor = function (member) {
        setSelectedEditor(member);
    };
    var shownList = savedCouponList;
    if (selectedEditor) {
        shownList = savedCouponList.filter(function (el) { return Number(el.member_id) === Number(selectedEditor); });
    }
    if (hippodrome) {
        shownList = shownList.filter(function (el) { return Number(el.card.split('-')[0]) === Number(hippodrome._date) && Number(el.card_id) === Number(hippodrome.card_id); });
    }
    var playTJKCoupon = function (coupon) {
        console.log("coupon", coupon);
        tjkBetSlipOptions.hippodrome = coupon.hippodromeObject;
        getAvailableBettingTypes();
        tjkBetSlipOptions.bettingType = availableTjkBettingTypes.find(function (b) { return b.id === Number(coupon.bet_type); });
        console.log("tjkBetSlipOptions.bettingType", tjkBetSlipOptions.bettingType);
        getAvailableBettingSubTypes(coupon.hippodromeObject);
        console.log("availableBettingSubTypes", availableBettingSubTypes);
        if ([14, 15, 16, 17, 18, 19].includes(Number(tjkBetSlipOptions.bettingType.id))) {
            tjkBetSlipOptions.bettingSubType = availableBettingSubTypes.find(function (b) { return b[0] === String(coupon.race_id); });
        }
        else {
            tjkBetSlipOptions.bettingSubType = String(coupon.race_id);
        }
        var _loop_3 = function (i, value) {
            console.log(i, value);
            var runObject = coupon.hippodromeObject.runs.find(function (r) { return Number(r.no) === Number(coupon.race_id) + Number(i); });
            console.log("status>>", runObject.status);
            if (runObject.status === 0) {
                var _loop_4 = function (horseNo) {
                    var horseObject = runObject.horses.find(function (h) { return Number(h.no) === Number(horseNo); });
                    addToTJKBetSlip(i + 1, runObject, horseObject, 'add');
                };
                for (var _c = 0, value_1 = value; _c < value_1.length; _c++) {
                    var horseNo = value_1[_c];
                    _loop_4(horseNo);
                }
            }
        };
        for (var _i = 0, _a = coupon.legs.entries(); _i < _a.length; _i++) {
            var _b = _a[_i], i = _b[0], value = _b[1];
            _loop_3(i, value);
        }
        navigate('/at-yarisi/bulten/philadelph/');
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "header clearfix pl-sm-2 pl-lg-0 mt-sm-0" }, { children: _jsx("div", __assign({ style: isMobile ? { position: 'relative', zIndex: 1, overflowX: 'scroll', overflowY: 'hidden' } : {} }, { children: _jsxs("ul", __assign({ className: "locationtab clearfix mb-lg-2 float-left", style: { width: 'max-content' } }, { children: [_jsx("li", __assign({ className: "cursor-pointer tjklocation " + (hippodrome === null ? 'activeTab' : ''), onClick: function () { return changeHippodrome(null); } }, { children: "T\u00FCm\u00FC" }), void 0), availableHippodromes.map(function (data, key) {
                                return (_jsx("li", __assign({ className: "cursor-pointer tjklocation " + (hippodrome && hippodrome.keyword === data.keyword ? 'activeTab' : ''), id: "location" + key, onClick: function () { return changeHippodrome(data); } }, { children: data.hippodrome }), key + "raceData"));
                            })] }), void 0) }), void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-12 col-sm-12 col-md-4 col-lg-3" }, { children: _jsxs("div", __assign({ className: "col-editors" }, { children: [_jsx("h3", { children: "TJK Yazarlar\u0131" }, void 0), _jsx("div", __assign({ className: "tjkEditors saved-coupons" }, { children: savedCouponList.length > 0 ? editorIds.map(function (el, key) {
                                        var editor = tjkEditor[Number(el)];
                                        return (_jsxs("div", __assign({ className: "tjkEditor cursor-pointer ".concat(selectedEditor === editor.id ? 'selected' : ''), onClick: function () {
                                                filterEditor(el);
                                            } }, { children: [_jsx("img", { className: "avatar", src: editor.photo ? editor.photo : DefaultAvatar }, void 0), _jsxs("h3", { children: [editor.first_name, " ", editor.last_name, " ", editor.nick_name && _jsxs(_Fragment, { children: ["(", editor.nick_name, ")"] }, void 0)] }, void 0), _jsx("a", __assign({ className: "cursor-pointer" }, { children: "Kuponlar\u0131n\u0131 G\u00F6ster" }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }), "tjkEditor_".concat(key)));
                                    }) : _jsx(_Fragment, { children: "Hen\u00FCz hi\u00E7 yorum yap\u0131lmam\u0131\u015F" }, void 0) }), void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ className: "col-12 col-sm-12 col-md-8 col-lg-9 pl-0 pt-2 " }, { children: [_jsx("h3", { children: "Haz\u0131r Kuponlar" }, void 0), _jsx("div", __assign({ className: "row pl-2 pr-2 tjk-saved-coupons" }, { children: shownList.map(function (coupon, key) {
                                    var editor = tjkEditor[Number(coupon.member_id)];
                                    return (_jsxs("div", __assign({ className: "col-lg-3 p-2 tjk-saved-coupon" }, { children: [_jsxs("div", __assign({ className: "head" }, { children: [_jsx("h3", { children: coupon.card && tjkHippodromsMap[coupon.card.split('-')[1]] ? tjkHippodromsMap[coupon.card.split('-')[1]].HIPODROM : coupon.card.split('-')[1] }, void 0), _jsxs("h4", { children: [editor.first_name, " ", editor.last_name] }, void 0)] }), void 0), _jsxs("div", __assign({ className: "body" }, { children: [_jsx("div", __assign({ className: "legs" }, { children: coupon.legs.map(function (leg, legKey) {
                                                            return (_jsxs("ul", __assign({ className: "column" }, { children: [_jsxs("li", __assign({ className: "column-no" }, { children: [Number(coupon.race_id) + legKey, ".", _jsx("br", {}, void 0), "Ko\u015Fu"] }), void 0), leg.map(function (h, hKey) {
                                                                        return (_jsx("li", { children: h }, "leg-horse-".concat(hKey)));
                                                                    })] }), "betslip-column-".concat(legKey)));
                                                        }) }), void 0), _jsxs("div", __assign({ className: "footer px-2 py-1" }, { children: [_jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col" }, { children: "Bahis Tipi" }), void 0), _jsx("div", __assign({ className: "col" }, { children: tjkBettingTypesMap2[Number(coupon.bet_type)].name }), void 0)] }), void 0), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col" }, { children: "Bahis Say\u0131s\u0131" }), void 0), _jsx("div", __assign({ className: "col" }, { children: coupon.bet_count }), void 0)] }), void 0), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col" }, { children: "Misli" }), void 0), _jsx("div", __assign({ className: "col" }, { children: coupon.multiply }), void 0)] }), void 0), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col" }, { children: "Kupon Tutar\u0131" }), void 0), _jsxs("div", __assign({ className: "col" }, { children: [currencyFormat(Number(coupon.amount || 0)), "\u20BA"] }), void 0)] }), void 0), _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col" }, { children: _jsx("button", __assign({ className: "btn btn-primary w-100", onClick: function () {
                                                                            playTJKCoupon(coupon);
                                                                        } }, { children: "Oyna" }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), "tjk-saved-coupon-".concat(key)));
                                }) }), void 0)] }), void 0)] }), void 0)] }, void 0));
}
