var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { highlightedEvents } from "../../../store/sportsbook.store";
import { Event } from "../../sportsbook/components/event";
import { myHighLightedMarketGroups } from "../../../services/sportsbook.services";
export default function HighLightedEvents() {
    // @ts-ignore
    var marketGroup = myHighLightedMarketGroups['futbol'];
    return (_jsxs("div", __assign({ className: "widget highlighted card ml-1 mr-1" }, { children: [_jsx("div", __assign({ className: "header" }, { children: _jsx("h2", { children: "\u00D6ne \u00C7\u0131kan Kar\u015F\u0131la\u015Fmalar" }, void 0) }), void 0), _jsxs("div", __assign({ className: "body" }, { children: [_jsxs("div", __assign({ className: "event-card list header hidden-wd" }, { children: [_jsxs("div", __assign({ className: "event-card " }, { children: [_jsx("div", __assign({ className: "participants-labels fw-bold fs-small  hidden-sm hidden-detail" }, { children: "Kar\u015F\u0131la\u015Fma Ad\u0131" }), void 0), _jsx("div", __assign({ className: "highlighted-odds", style: { marginTop: 0 } }, { children: _jsx("div", __assign({ style: { display: 'flex', marginRight: '50px' } }, { children: marketGroup.map(function (el, key) {
                                                return (_jsx("div", __assign({ className: "group " + (key === 2 ? 'last-group hidden-detail' : (key === 1 ? 'middle-group' : '')) }, { children: _jsx("div", __assign({ className: "text-center p-0 w-100" }, { children: el.name }), void 0) }), "highlighted-".concat(key)));
                                            }) }), void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }), void 0), highlightedEvents.map(function (event, key) {
                        return (_jsx("div", { children: _jsx("div", __assign({ className: "event-card list " + ((key %= 2) === 0 ? ' dark ' : '') }, { children: _jsx(Event, { className: "event-card", fromOutSource: true, event: event, eventType: 'tumu' }, void 0) }), void 0) }, "highlightedEvents-".concat(key, "-").concat(event.eventId)));
                    })] }), void 0)] }), void 0));
}
