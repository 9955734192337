var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Storage } from "../../../lib/localstorege.service";
import { initialLeftMenu, sportsMenu, useForceUpdate } from "../../../lib/misc.functions";
import { env } from "../../../definitions/global.vars";
import { modalServiceSubject } from "../../../components/modal/modal";
import { Api } from "../../register/register.api";
import "../../../assets/css/sport.color.icon.css";
import { SosyoleyUserInfo } from "../components/sosyoley.user.info";
var page = 0;
var hasMore = true;
var Sosyoleyciler = function () {
    var _a = useState("-followers_count"), status = _a[0], setStatus = _a[1];
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card social-profile" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsxs("div", __assign({ className: 'd-flex' }, { children: [_jsx("h2", __assign({ className: "w-100 m-0 d-md-flex d-none", style: { borderBottom: '1px solid var(--color-bg-medium)' } }, { children: " Sosyoleyciler " }), void 0), _jsxs("div", __assign({ className: "actions text-right m-0", style: { display: 'block' } }, { children: [_jsx("a", __assign({ onClick: function () { return setStatus("-followers_count"); }, className: "sort " + (status === '-followers_count' ? 'active' : '') }, { children: "Takip\u00E7i" }), void 0), _jsx("a", __assign({ onClick: function () { return setStatus("-following_count"); }, className: "sort " + (status === '-following_count' ? 'active' : '') }, { children: "Takip Edilen" }), void 0), _jsx("a", __assign({ onClick: function () { return setStatus("-post_count"); }, className: "sort " + (status === '-post_count' ? 'active' : '') }, { children: "Payla\u015F\u0131m" }), void 0), _jsx("a", __assign({ onClick: function () { return setStatus("-shared_coupon_count"); }, className: "sort " + (status === '-shared_coupon_count' ? 'active' : '') }, { children: "Kupon" }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ id: "InfiniteScroll", className: "body p-0 " }, { children: _jsx(ListMembers, { status: status }, void 0) }), void 0)] }), void 0) }, void 0));
};
export default Sosyoleyciler;
var membersData = [];
var lockScroll = false;
var memberStatus = -1;
var timer;
export function ListMembers(props) {
    var forceUpdate = useForceUpdate();
    var params = props;
    useEffect(function () {
        window.scrollTo(0, 0);
        membersData = [];
        fetchData(true);
    }, [params.status]);
    // @ts-ignore
    memberStatus = params.status;
    var fetchData = function (isFirst, forcePage, top) {
        if (isFirst === void 0) { isFirst = false; }
        if (forcePage === void 0) { forcePage = null; }
        if (top === void 0) { top = null; }
        // @ts-ignore
        document.getElementById('loadingAnimation').style.display = 'block';
        hasMore = false;
        if (isFirst) {
            membersData = [];
            page = forcePage !== null ? forcePage : 0;
            hasMore = true;
        }
        var data;
        if (memberStatus) {
            data = {
                ordering: memberStatus,
                page: page,
                token: Storage.get('token')
            };
        }
        else {
            data = {
                page: page,
                token: Storage.get('token')
            };
        }
        // @ts-ignore
        var objectHeight = document.getElementById('InfiniteScroll').offsetHeight;
        // @ts-ignore
        var objectTop = document.getElementById('InfiniteScroll').offsetTop;
        Api.start('post', env.accounting_api + '/misc/api/get-member-info/', data, true).then(function (result) {
            // @ts-ignore
            document.getElementById('loadingAnimation').style.display = 'none';
            if (result.status) {
                if (result.data.length < 10) {
                    hasMore = false;
                    lockScroll = true;
                }
                else {
                    hasMore = true;
                }
                page += 1;
                membersData = membersData.concat(result.data);
                forceUpdate();
                if (!isFirst) {
                    if (top === 0) {
                        window.scrollTo(0, top);
                    }
                    else {
                        var threshold = window.outerHeight < 700 ? 600 : 700;
                        window.scrollTo(0, (objectHeight - objectTop - threshold));
                    }
                }
                timer = setTimeout(function () {
                    if (result.data.length >= 10) {
                        lockScroll = false;
                        if (membersData.length === 10 && objectHeight < window.outerHeight) {
                            fetchData(false, 1, 0);
                        }
                    }
                    triggerScroll();
                }, 500);
            }
            else {
                modalServiceSubject.next({
                    title: 'beklenmedik bir sorun',
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 3000,
                });
            }
        });
    };
    useEffect(function () {
        window.addEventListener('scroll', triggerScroll);
        return function () {
            clearTimeout(timer);
            window.removeEventListener('scroll', triggerScroll);
        };
    }, []);
    var triggerScroll = function () {
        var threshold = window.outerHeight < 968 ? 100 : 150;
        // @ts-ignore
        var objectHeight = document.getElementById('InfiniteScroll').offsetHeight;
        // @ts-ignore
        var objectTop = document.getElementById('InfiniteScroll').offsetTop;
        var visibleZone = (window.outerHeight - objectTop - threshold);
        if (objectHeight > visibleZone) {
            if ((objectHeight - visibleZone) < window.scrollY && !lockScroll) {
                lockScroll = true;
                fetchData(false);
            }
        }
    };
    return (_jsxs(_Fragment, { children: [membersData.map(function (memberInfo, key) {
                return (_jsx(SosyoleyUserInfo, __assign({ memberInfo: memberInfo, type: "sosyoleyciler" }, { children: " " }), key + "SosyoleyUserInfo"));
            }), hasMore == false && membersData.length === 0 ? _jsx(_Fragment, { children: _jsx("p", __assign({ className: "text-center py-5" }, { children: "Listelenecek data yok." }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ id: "loadingAnimation", className: "text-center py-5" }, { children: _jsxs("div", __assign({ className: "lds-roller", style: { position: 'unset' } }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }), void 0)] }, void 0));
}
