var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApiService } from "../lib/http.service";
import { env } from "../definitions/global.vars";
export function getSportotoFixture(draw_id) {
    if (draw_id === void 0) { draw_id = 'current'; }
    return __awaiter(this, void 0, void 0, function () {
        var apiServives;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServives = new ApiService();
                    return [4 /*yield*/, apiServives.start('get', env.sportsbook_api + '/toto/bulletin/' + draw_id + '/', null, false)
                            .then(function (response) {
                            if (response.hasOwnProperty('status')) {
                                if (response.status === true) {
                                    return [true, response.data];
                                }
                                else {
                                    return [false, response.message];
                                }
                            }
                            else {
                                return [false, 'İşlem Tamamlanamadı.'];
                            }
                        })
                            .catch(function (e) {
                            console.log(e);
                            return [false, 'İşlem Tamamlanamadı.'];
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function getCurrentScores(eventId) {
    return __awaiter(this, void 0, void 0, function () {
        var idList, apiServives, requestUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(eventId && eventId.length > 0)) return [3 /*break*/, 2];
                    idList = eventId.join(',');
                    apiServives = new ApiService();
                    requestUrl = "/events/?from-date=2019-01-01&fields=eventType,isLiveEvent,sportSlug,competitionId,eventId,bettingPhase,eventType,currentScore,officialScore,eventName&eventIds=";
                    return [4 /*yield*/, apiServives.start('get', env.sportsbook_api + requestUrl + idList, null, false)
                            .then(function (response) {
                            if (response.status) {
                                return response.data;
                            }
                            else {
                                return null;
                            }
                        })
                            .catch(function (e) {
                            console.log(e);
                            return null;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2: return [2 /*return*/, null];
            }
        });
    });
}
export function getSportotoLatestResult(drawId) {
    if (drawId === void 0) { drawId = null; }
    return __awaiter(this, void 0, void 0, function () {
        var apiServives, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServives = new ApiService();
                    if (drawId) {
                        url = env.sportsbook_api + '/toto/results/' + drawId + '/';
                    }
                    else {
                        url = env.core_api + '/misc/api/get-toto-result/';
                    }
                    return [4 /*yield*/, apiServives.start('get', url, null, false)
                            .then(function (response) {
                            if (response.status) {
                                return response.data;
                            }
                            else {
                                return null;
                            }
                        })
                            .catch(function (e) {
                            console.log(e);
                            return null;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function getSportotoDraws() {
    return __awaiter(this, void 0, void 0, function () {
        var apiServives;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServives = new ApiService();
                    return [4 /*yield*/, apiServives.start('get', env.sportsbook_api + '/toto/results/', null, false)
                            .then(function (response) {
                            if (response.status) {
                                return response.data;
                            }
                            else {
                                return null;
                            }
                        })
                            .catch(function (e) {
                            console.log(e);
                            return null;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function playSportotoCoupon(couponData) {
    return __awaiter(this, void 0, void 0, function () {
        var apiServives;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServives = new ApiService();
                    return [4 /*yield*/, apiServives.start('post', env.core_api + '/order/api/order-sportoto/', couponData, true)
                            .then(function (response) {
                            return response;
                        })
                            .catch(function (e) {
                            console.log(e);
                            return null;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
