var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Copy from '../../../../assets/feather/copy.svg';
export default function OtherBanks() {
    var copyMemberId = function () {
        var element = document.getElementById("copy-iban");
        navigator.clipboard.writeText("TR78 0013 4000 0038 6205 9000 04");
        element.style.display = "inline";
        element.classList.add("elementToFadeInAndOut");
    };
    return (_jsx("div", __assign({ className: "otherBanks" }, { children: _jsx("div", { children: _jsxs("div", { children: ["E\u011Fer anla\u015Fmal\u0131 oldu\u011Fumuz bankalardan biriyle \u00E7al\u0131\u015Fm\u0131yorsan\u0131z kendi ad\u0131n\u0131za kay\u0131tl\u0131 bir banka hesab\u0131ndan, Oley.com\u2019a a\u015Fa\u011F\u0131da belirtilen banka hesap bilgilerimiz ile EFT/FAST yapabilirsiniz. Kendi ad\u0131n\u0131za kay\u0131tl\u0131 olmayan farkl\u0131 bir ki\u015Fiye ait banka hesab\u0131ndan yap\u0131lan EFT/FAST i\u015Flemleri i\u00E7in g\u00F6ndermi\u015F oldu\u011Funuz tutar, banka taraf\u0131ndan al\u0131nan masraflar d\u00FC\u015F\u00FCld\u00FCkten sonra iade edilir.", _jsxs("div", { children: [_jsx("h3", { children: "Banka Hesap Bilgilerimiz" }, void 0), _jsx("table", { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { children: "Hesap Ad\u0131 (Al\u0131c\u0131 Ad\u0131)\t" }, void 0), _jsx("td", { children: "E Elektronik Bahis Oyunlar\u0131 A.\u015E." }, void 0)] }, void 0), _jsxs("tr", { children: [_jsx("td", { children: "Banka" }, void 0), _jsx("td", { children: "Denizbank" }, void 0)] }, void 0), _jsxs("tr", { children: [_jsx("td", { children: "\u015Eube Kodu" }, void 0), _jsx("td", { children: "4840" }, void 0)] }, void 0), _jsxs("tr", { children: [_jsx("td", { children: "Hesap No" }, void 0), _jsx("td", { children: "3862059-353" }, void 0)] }, void 0), _jsxs("tr", { children: [_jsx("td", { children: "IBAN" }, void 0), _jsx("td", { children: "TR78 0013 4000 0038 6205 9000 04" }, void 0), _jsxs("td", { children: [_jsx("img", { src: Copy, alt: "copy", className: "cursor-pointer", id: "copy-iban-icon", onClick: function () { return copyMemberId(); } }, void 0), _jsx("span", __assign({ id: "copy-iban", className: "copy-tooltip-navbar ", style: { position: "inherit" } }, { children: "Kopyaland\u0131" }), void 0)] }, void 0)] }, void 0)] }, void 0) }, void 0)] }, void 0)] }, void 0) }, void 0) }), void 0));
}
