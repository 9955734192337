var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import { AccountTransactionApi } from "../../subpages/accountTransactions/account.transactions.api";
import { Storage } from "../../../../lib/localstorege.service";
import CodeMobile from "./code.mobile";
import { customStyles } from "../../../../definitions/constants/modal.constant";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { member } from "../../../../store/login.store";
import CheckCircle from "../../../../assets/feather/check-circle.svg";
import MinusCircle from "../../../../assets/feather/minus-circle.svg";
export var mobile;
function MobileUpdate() {
    var _a = React.useState(false), modalIsOpenMobile = _a[0], setIsOpenMobile = _a[1];
    var _b = React.useState(false), codeModal = _b[0], setCodeModal = _b[1];
    function openModalEmail() {
        setCodeModal(true);
    }
    function openModalMobile() {
        setIsOpenMobile(true);
    }
    function closeModal() {
        setIsOpenMobile(false);
    }
    var _c = useForm(), register = _c.register, handleSubmit = _c.handleSubmit, errors = _c.formState.errors;
    var onSubmit = function (data) {
        updateMobileApi(data);
    };
    var updateMobileApi = function (data) {
        mobile = data.mobile;
        data.token = Storage.get('token');
        data.type = "send";
        AccountTransactionApi.updateMobileMember(data).then(function (result) {
            if (result.status) {
                setIsOpenMobile(false);
                openModalEmail();
                modalServiceSubject.next({
                    title: 'SMS kodu gönderildi',
                    content: result.message,
                    confirm: {
                        cancel: {
                            label: 'Tamam'
                        }
                    }
                });
            }
            else {
                modalServiceSubject.next({
                    title: 'Hata!',
                    content: result.message,
                    confirm: {
                        cancel: {
                            label: 'Tamam'
                        }
                    }
                });
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "table row mt-3" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("label", { children: "Cep Telefonu" }, void 0), _jsx("div", { style: { position: 'absolute', fontSize: 16, marginTop: '7px', marginLeft: '10px' } }, void 0), _jsxs("div", __assign({ className: "form-control" }, { children: [member.mobile, _jsx("i", __assign({ className: "ml-2" }, { children: member.mobile_verified ? (_jsx("img", { title: "Do\u011Frulanm\u0131\u015F mobil no", src: CheckCircle, width: 16 }, void 0)) : (_jsx("img", { title: "Do\u011Frulanmam\u0131\u015F", src: MinusCircle, width: 16 }, void 0)) }), void 0), _jsx("a", __assign({ href: "#", className: "btn btn-small btn-primary float-right", onClick: function () { return openModalMobile(); } }, { children: "De\u011Fi\u015Ftir" }), void 0)] }), void 0)] }), void 0) }), void 0), _jsxs(Modal, __assign({ isOpen: modalIsOpenMobile, onRequestClose: closeModal, style: customStyles, contentLabel: "" }, { children: [_jsx("div", __assign({ className: "header" }, { children: _jsx("h3", { children: "Telefon G\u00FCncelleme" }, void 0) }), void 0), _jsx("label", { children: "L\u00FCtfen yeni telefon numaran\u0131z\u0131 girip onaylay\u0131n\u0131z." }, void 0), _jsx("div", __assign({ className: "card-body p-0" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ id: 'phoneNumber' }, { children: [_jsx("input", __assign({ type: 'number', onKeyDown: function (e) { return (e.keyCode === 69 ? e.preventDefault() : null); }, placeholder: "Telefon Numarası" }, register('mobile', { required: true }), { className: "form-control" }), void 0), errors.mobile && errors.mobile.type === "required" &&
                                            _jsx("span", __assign({ className: "text-validation" }, { children: "L\u00FCtfen ge\u00E7erli bir numara giriniz." }), void 0)] }), void 0), _jsx("div", { children: _jsx("input", { className: "btn btn-primary mt-3", type: "submit" }, void 0) }, void 0)] }), void 0) }), void 0)] }), void 0), _jsx(CodeMobile, { codeModal: codeModal, setCodeModal: setCodeModal }, void 0)] }, void 0));
}
export default MobileUpdate;
