var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export default function DepositChannels(props) {
    function selected(id) {
        var element = document.getElementById(id);
        if (element.style.display == "none") {
            element.style.display = "inline";
        }
        else {
            element.style.display = "none";
        }
    }
    return (_jsx("div", __assign({ className: "depositChannels" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col" }, { children: _jsx("table", __assign({ className: "table" }, { children: _jsx("tbody", { children: props.depositMoneyData.map(function (moneyDepositOption, key) {
                            return (_jsx("tr", { children: _jsxs("td", __assign({ style: { border: "none" } }, { children: [_jsx("div", __assign({ onClick: function () { return selected(moneyDepositOption.id); }, className: "event-card list dark cursor-pointer" }, { children: _jsx("div", __assign({ className: "fw-bold" }, { children: moneyDepositOption.name }), void 0) }), void 0), _jsx("div", __assign({ id: moneyDepositOption.id, style: { display: "none" } }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-sm-12 col-lg-6" }, { children: moneyDepositOption.options.map(function (option, key) {
                                                            return (_jsxs("div", __assign({ className: "row " }, { children: [_jsx("div", __assign({ className: "col-lg-3 col-md-6" }, { children: _jsx("i", { className: option.logo }, void 0) }), void 0), _jsxs("div", __assign({ className: "col-sm-12" }, { children: [_jsx("h3", { children: option.header }, void 0), _jsx("h5", { children: option.sub_header }, void 0), _jsx("span", __assign({ className: "bank-footer-info" }, { children: option.text }), void 0)] }), void 0)] }), "depositChannelsOption".concat(key)));
                                                        }) }), void 0), _jsx("div", __assign({ className: "col-sm-12 col-lg-6 d-flex justify-content-center" }, { children: _jsx("iframe", { style: { minHeight: "0vh" }, id: "video_{{bank.id}}", src: moneyDepositOption.video_url, frameBorder: 0, allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }, void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }, "depositChannels".concat(key)));
                        }) }, void 0) }), void 0) }), void 0) }), void 0) }), void 0));
}
