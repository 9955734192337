var Member = /** @class */ (function () {
    function Member() {
    }
    //
    Member.prototype.getFullName = function () {
        return this.first_name + ' ' + this.last_name;
    };
    Member.prototype.getAvailableBalance = function () {
        return Number(this.debit_balance) + (Number(this.point_balance) / 100);
    };
    return Member;
}());
export { Member };
var Location = /** @class */ (function () {
    function Location() {
    }
    return Location;
}());
export { Location };
