var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import { Storage } from "../../../../lib/localstorege.service";
import { customStyles } from "../../../../definitions/constants/modal.constant";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { initialLeftMenu, sportsMenu } from "../../../../lib/misc.functions";
import { FinancalTransactionsApi } from '../../subpages/financialTransactions/financial.transactions.api';
function AddBankAccount(props) {
    var _a = React.useState(false), modalIsOpen = _a[0], setIsModalOpen = _a[1];
    var token = Storage.get('token');
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    var onSubmit = function (data) {
        addMyBankAccount(data);
    };
    function closeModal() {
        setIsModalOpen(false);
    }
    var addMyBankAccount = function (data) {
        data.token = token;
        data.iban = data.iban.replace(/ /g, '');
        if (!data.iban.startsWith('TR')) {
            data.iban = 'TR' + data.iban;
        }
        if (data.iban.endsWith(' ')) {
            data.iban = data.iban.replace(' ', '');
        }
        // data.iban = 'TR' + data.iban.replace(/ /g, '');
        FinancalTransactionsApi.addMyBankAccounts(data).then(function (result) {
            if (result.status) {
                setIsModalOpen(false);
                modalServiceSubject.next({
                    title: "Başarılı!",
                    content: "Kayıt başarıyla gerçekleşmiştir.",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
                props.setIsAdded(!props.isAdded);
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    function openModal() {
        setIsModalOpen(true);
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "d-flex justify-content-center my-3" }, { children: _jsx("button", __assign({ className: "btn btn-primary justify-content-center", onClick: function () { return openModal(); } }, { children: "Banka Hesab\u0131 Ekle" }), void 0) }), void 0), _jsx(Modal, __assign({ isOpen: modalIsOpen, onRequestClose: closeModal, style: customStyles, contentLabel: "" }, { children: _jsx("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs("div", __assign({ className: "table" }, { children: [_jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-1 m-2" }, { children: _jsx("label", __assign({ className: " my-auto" }, { children: "TR" }), void 0) }), void 0), _jsxs("div", __assign({ className: "col-10" }, { children: [_jsx("input", __assign({ className: "form-control" }, register('iban', { required: true })), void 0), errors.iban && errors.iban.type === "required" &&
                                                _jsx("span", __assign({ className: "text-validation" }, { children: "IBAN No gereklidir." }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "row justify-content-center mt-2  d-flex" }, { children: _jsx("button", __assign({ className: "btn btn-primary" }, { children: "Hesap Ekle" }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)] }, void 0));
}
export default AddBankAccount;
