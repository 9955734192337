var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import { AccountTransactionApi } from "../../subpages/accountTransactions/account.transactions.api";
import { Storage } from "../../../../lib/localstorege.service";
import { customStyles } from "../../../../definitions/constants/modal.constant";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { mobile } from './mobile.update';
var token = Storage.get('token');
function CodeMobile(props) {
    function closeModal() {
        props.setCodeModal(false);
    }
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var onSubmit = function (data) {
        codeMobileApi(data);
    };
    var codeMobileApi = function (data) {
        data.token = token;
        data.type = "validate";
        AccountTransactionApi.codeMobileMember(data).then(function (result) {
            if (result.status) {
                props.setCodeModal(false);
                modalServiceSubject.next({
                    title: 'Güncellendi!',
                    content: result.message,
                    confirm: {
                        cancel: {
                            label: 'Tamam'
                        }
                    }
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Modal, __assign({ isOpen: props.codeModal, onRequestClose: closeModal, style: customStyles, contentLabel: "" }, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("h3", { children: "Telefon Do\u011Frulama" }, void 0), _jsx("label", { children: "L\u00FCtfen do\u011Frulama kodu giriniz." }, void 0), props.codeModal ? _jsx(_Fragment, { children: _jsx("div", { children: _jsx(CountDown, { timer: 30 }, void 0) }, void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ className: "card-body p-0" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", { children: [_jsx("input", __assign({ placeholder: "Doğrulama Kodu" }, register('code', { required: true }), { className: "form-control" }), void 0), errors.code && errors.code.type === "required" && _jsx("span", __assign({ className: "text-validation" }, { children: "L\u00FCtfen do\u011Frulama kodu giriniz." }), void 0)] }, void 0), _jsx("input", { className: "btn btn-primary btn-large mt-3", type: "submit" }, void 0)] }), void 0) }), void 0)] }), void 0) }), void 0) }, void 0));
}
export default CodeMobile;
function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    var obj = {
        "h": String(hours).padStart(2, '0'),
        "m": String(minutes).padStart(2, '0'),
        "s": String(seconds).padStart(2, '0')
    };
    return obj;
}
export function CountDown(props) {
    var timer = props.timer;
    var _a = useState(timer), timeLeft = _a[0], setTimeLeft = _a[1];
    useEffect(function () {
        if (timeLeft > 0) {
            setTimeout(function () {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        }
    }, [timeLeft]);
    if (timeLeft <= 0) {
        return (_jsxs(_Fragment, { children: ["S\u00FCre doldu! tekrar do\u011Frulama kodu g\u00F6ndermeniz gerekiyor. ", _jsx("a", __assign({ onClick: function () {
                        AccountTransactionApi.updateMobileMember({
                            mobile: mobile,
                            type: "send",
                            token: token,
                        }).then(function () { setTimeLeft(timer); });
                    }, style: { cursor: 'pointer', fontWeight: 'bold', color: 'var(--color-danger)' } }, { children: "Tekrar G\u00F6nder" }), void 0), " "] }, void 0));
    }
    return (_jsx(_Fragment, { children: _jsxs("span", { children: [_jsxs("b", { children: [secondsToTime(timeLeft).m, ":", secondsToTime(timeLeft).s] }, void 0), " saniye sonra yeniden kod g\u00F6nderebilirsiniz."] }, void 0) }, void 0));
}
