var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import SporToto from "../../assets/img/sportoto_siyah.png";
import "./sportoto.css";
import SportotoFixTure from "./widgets/fixture";
import SportotoResult from "./widgets/result";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { useParams } from 'react-router-dom';
export default function Sportoto() {
    var params = useParams();
    var _a = useState(true), sporTotoTab = _a[0], setSporTotoTab = _a[1];
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        if (params.id == "oyna") {
            setSporTotoTab(true);
        }
        if (params.id == "sonuclar") {
            setSporTotoTab(false);
        }
    }, [params.id]);
    return (_jsx("div", __assign({ className: "container-fluid page sportoto" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "card w-100" }, { children: [_jsxs("div", __assign({ className: "card-title" }, { children: [_jsx("img", { src: SporToto, height: 30, alt: "SporToto" }, void 0), _jsxs("div", __assign({ className: "actions justify-content-end" }, { children: [_jsx("button", __assign({ onClick: function () { setSporTotoTab(true); }, className: "btn " + (sporTotoTab ? 'btn-primary' : 'btn-outline-primary') }, { children: "Program" }), void 0), _jsx("button", __assign({ onClick: function () { setSporTotoTab(false); }, className: "btn " + (!sporTotoTab ? 'btn-primary' : 'btn-outline-primary') }, { children: "Sonu\u00E7lar" }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: sporTotoTab ? _jsx(SportotoFixTure, {}, void 0) : _jsx(SportotoResult, {}, void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
}
