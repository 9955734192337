var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import "./campaigns.css";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { env } from "../../definitions/global.vars";
import { Link, useParams } from "react-router-dom";
import paths from "../../app/paths";
import { ApiService } from "../../lib/http.service";
import _ from "lodash";
var selectedCampaign = null;
export default function Campaigns() {
    var params = useParams();
    var _a = useState(null), campaigns = _a[0], setCampaigns = _a[1];
    var width = window.innerWidth;
    var isMobileView = width < 986 ? true : false;
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        var api = new ApiService();
        api.start('GET', env.misc_api + '/misc/api/list-campaign-cms/', {}, false)
            .then(function (res) {
            if (res.status) {
                setCampaigns(res.data.filter(function (e) { return e.status === true; }));
            }
        });
        return function () {
            selectedCampaign = null;
        };
    }, []);
    if (params.page && campaigns) {
        selectedCampaign = campaigns.find(function (el) { return el.slug === params.page; });
    }
    else {
        selectedCampaign = null;
    }
    return (_jsx("div", __assign({ className: "container-fluid page account-page" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "card w-100 p-3" }, { children: [_jsx("div", { children: _jsxs("div", __assign({ className: "row" }, { children: [isMobileView && selectedCampaign ? _jsx(_Fragment, { children: _jsx(Link, __assign({ className: "btn btn-primary w-100 mb-2", to: '/' + paths.CAMPAIGNS }, { children: "T\u00FCm Kampanyalar\u0131 G\u00F6ster" }), void 0) }, void 0) : _jsx(_Fragment, { children: _jsx("div", __assign({ className: (!selectedCampaign ? "col-lg-12" : 'col-lg-3') }, { children: campaigns ? _jsx(_Fragment, { children: _jsx("div", __assign({ className: (!selectedCampaign ? "row" : ' ') }, { children: _.orderBy(campaigns, ['is_valid', 'create_date'], ['desc', 'desc']).map(function (item, index) {
                                                    return (_jsx("div", __assign({ className: (!selectedCampaign ? "col-sm-12 col-md-6 col-lg-4" : ' ') }, { children: _jsxs("div", __assign({ className: "campaign-widget mb-5 " + (item.is_valid ? ' active ' : ' passive ') }, { children: [!item.is_valid ? _jsx("div", __assign({ className: "ribbon ribbon-old" }, { children: _jsx("span", { children: "Ge\u00E7mi\u015F" }, void 0) }), void 0) : null, _jsxs(Link, __assign({ to: '/' + paths.CAMPAIGNS + '/' + item.slug }, { children: [_jsx("img", { src: env.misc_api + '/media/' + item.mobile_photo }, void 0), _jsx("h2", __assign({ className: "title" }, { children: item.name }), void 0), _jsx("p", __assign({ className: "mb-2", style: { height: 45 } }, { children: item.title_abbreviated }), void 0)] }), void 0), !selectedCampaign && _jsx(Link, __assign({ className: "btn btn-primary w-100 m-0", to: '/' + paths.CAMPAIGNS + '/' + item.slug }, { children: "Kampanya Detaylar\u0131" }), void 0)] }), void 0) }), "campaign-list-".concat(index)));
                                                }) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0) }), void 0) }, void 0), selectedCampaign && _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "col-lg-9" }, { children: [_jsx("img", { className: "w-100", src: env.misc_api + '/media/' + selectedCampaign.photo }, void 0), _jsx("div", __assign({ className: "card-title" }, { children: _jsx("h1", { children: selectedCampaign.name }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body w-100" }, { children: [_jsx("h3", { children: "Kampanya Detaylar\u0131" }, void 0), _jsx("div", { dangerouslySetInnerHTML: { __html: selectedCampaign.description } }, void 0), selectedCampaign.participation_requirement && _jsxs(_Fragment, { children: [_jsx("h3", { children: "Kat\u0131l\u0131m \u015Eartlar\u0131 " }, void 0), _jsx("div", { dangerouslySetInnerHTML: { __html: selectedCampaign.participation_requirement } }, void 0)] }, void 0)] }), void 0)] }), void 0) }, void 0)] }), void 0) }, void 0), campaigns ? _jsx(_Fragment, {}, void 0) : _jsx(_Fragment, { children: _jsx("p", __assign({ className: "text-center p-5" }, { children: "Y\u00FCkleniyor. L\u00FCtfen bekleyiniz." }), void 0) }, void 0)] }), void 0) }), void 0) }), void 0));
}
