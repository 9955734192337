var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { initialLeftMenu, sportsMenu } from "../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../store/betslip.store";
import { useNavigate } from "react-router-dom";
import { modalServiceSubject } from "../../../components/modal/modal";
var Katar2022Home = function () {
    var navigate = useNavigate();
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    // function showSartname() {
    //     modalServiceSubject.next({
    //         title: "DÜNYA KUPASI YARIŞMA ŞARTNAMESİ",
    //         content: '<ul class="katar2022">  ' +
    //             '<li>Oley.com/katar-2022 linkinden Dünya Kupası tahmin yarışmasına katılan ve yarışmadaki tüm süreçleri doğru tahmin eden üye, 50.000 TL değerinde Oley Puan kazanma şansına sahip olacaktır.</li>' +
    //             '<li>Katılımcılar ilk etapta, Dünya Kupası’na katılan ülkelerin grubu kaçıncı sırada bitireceklerini tahmin eder. Gruplardaki sıralama oluşturulduktan sonra, belirledikleri son 16 takımın maçı ekrana gelir. Bu kısımda kullanıcılar her maçın galibini seçerek, çeyrek final oynayacak 8 takımı belirler. Aynı şekilde çeyrek final galipleri seçildiğinde, yarı final maçları ekrana gelir ve tekrar seçim yapılması beklenir. Katılımcı, ekrana gelen final ekranında kazanmasını beklediği takımı seçer. Tüm süreci tamamlayan kullanıcılar, "Şimdi Oyna" butonu ile Oley.com uzun vade futbol iddaa kısmına yönlendirilir ve kuponunu yapar. Finalisti belirleyip, uzun vade kuponunu yapmayan katılımcılar yarışmayı kazanmaya hak kazanamaz.</li>' +
    //             '<li>Yarışmayı birden fazla üye doğru tahmin ederse ödül yarışmayı kazanan kişi sayısına bölünecektir.</li>' +
    //             '<li>Yarışmaya katılım 20 Kasım 2022 saat 19:00’da sona erecektir. </li>' +
    //             '<li>Bir üyenin bir kez katılım hakkı bulunmaktadır.</li>' +
    //             '<li>Yarışmaya Oley.com üyeleri katılabilir. </li>' +
    //             '<li>50.000 TL değerindeki Oley Puan ödülü, Dünya Kupası final maçı sonuçlandıktan sonra kazanan katılımcı veya katılımcıların hesaplarına yüklenecektir. </li>' +
    //             '<li>Oley.com gerekli gördüğü takdirde yarışma şartlarında değişiklik yapabilir, yarışmayı daha erken sonlandırabilir.</li>' +
    //             '<li>18 yaşından küçükler ile Oley.com çalışanları ve Oley.com ile çalışan 3. partiler yarışmaya katılamaz, katılmış olsa bile ödül kazanma hakkı verilmez. </li>' +
    //             '<li>Bu yarışmaya katılan herkes yukarıdaki koşulları kabul etmiş sayılır.</li>' +
    //             '</ul>',
    //         confirm: {
    //             cancel: {label: 'Anladım'}
    //         },
    //         timeout: 0
    //     })
    // }
    function showHaftalikSartname() {
        modalServiceSubject.next({
            title: "HAFTALIK DÜNYA KUPASI YARIŞMALARI",
            content: '<ul class="katar2022">  ' +
                '<li>Dünya kupası süresince oley.com üzerinden her hafta olmak üzere toplamda dört hafta boyunca yarışmalar düzenlenecektir.</li><br>' +
                '<li style="list-style: none">Bu yarışmalar, </li>' +
                '<li>En Yüksek Oranlı Tutan Kupon, </li>' +
                '<li>Sosyoley’de En Çok Kupon Yaptıran, </li>' +
                '<li>En Çok Kupon Kazanan,</li>' +
                '<li>En Çok Kupon Kazandıran olarak düzenlenmektedir.</li> <br>' +
                '<li>Her bir yarışmanın ödülü 1000 TL değerinde Oley Puan’dır.</li>' +
                '<li>Haftalık olarak düzenlenecek bu yarışmalarda haftanın bir günü güncel puan durumu paylaşılacaktır.</li>' +
                '<li>Kullanıcıların yarışmaya dahil olmaları için, kuponlarının içinde en az 1(bir) adet dünya kupası maçı olması gerekmektedir.</li>' +
                '<li>Haftalık yarışmalar dünya kupası sonunda dördüncü hafta bitiminde sonlanacaktır.</li>' +
                '<li>Dünya kupası Sosyoley Haftalık Yarışması kapsamında, haftalar aşağıdaki şekilde belirlenecektir: <br><li style="list-style: none;margin-top: 20px">1.Hafta: 20 Kasım 2022 Pazar 00:00 / 26 Kasım 2022 Cumartesi 23:59</li><li style="list-style: none">2.Hafta: 27 Kasım 2022 Pazar 00:00 / 3 Aralık 2022 Cumartesi 23:59</li><li style="list-style: none">3.Hafta: 4 Aralık 2022 Pazar 00:00 / 10 Aralık 2022 Cumartesi 23:59</li><li style="list-style: none">4.Hafta: 11 Aralık 2022 Pazar 00:00 / 18 Aralık 2022 Pazar 23:59</li></li>' +
                '<br><li>Yarışmaya oley.com üyeleri katılabilir. </li>' +
                '<li>Oley.com gerekli gördüğü takdirde yarışma şartlarında değişiklik yapabilir, yarışmayı daha erken sonlandırabilir.</li>' +
                '<li>18 yaşından küçükler ile Oley.com çalışanları ve Oley.com ile çalışan 3. partiler yarışmaya katılamaz, katılmış olsa bile ödül kazanma hakkı verilmez. </li>' +
                '<li>Bu yarışmaya katılan herkes yukarıdaki koşulları kabul etmiş sayılır.</li>' +
                '</ul>',
            confirm: {
                cancel: { label: 'Anladım' }
            },
            timeout: 0
        });
    }
    return (_jsxs("div", __assign({ className: 'card katar2022' }, { children: [_jsx("div", __assign({ className: 'card-title d-flex' }, { children: _jsx("h2", { children: "Katar 2022" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body p-3" }, { children: _jsxs("div", __assign({ className: 'p-3', style: { background: 'var(--color-bg-medium)', borderRadius: '15px' } }, { children: [_jsx("img", { className: 'w-100 mb-3', src: '/assets/images/dunya-kupasi3.jpg', alt: "" }, void 0), _jsx("h2", __assign({ className: 'fw-bold' }, { children: "Sosyoley Haftal\u0131k \u00D6d\u00FCll\u00FC Yar\u0131\u015Fmalar" }), void 0), _jsx("p", { children: "D\u00FCnya Kupas\u0131 s\u00FCresince devam edecek olan Sosyoley D\u00FCnya Kupas\u0131 \u00D6d\u00FCll\u00FC Yar\u0131\u015Fmalar\u0131m\u0131zda a\u015Fa\u011F\u0131da belirtilen d\u00F6rt kriter, haftal\u0131k olarak de\u011Ferlendirilip kazananlar\u0131na her bir klasmana 1000 TL de\u011Ferinde Oley Puan olmak \u00FCzere, toplamda 16.000 TL de\u011Ferinde Oley Puan kazanma \u015Fans\u0131na sahip olabilirsiniz.Kuponlar\u0131n\u0131z\u0131n Sosyoley D\u00FCnya Kupas\u0131 \u00D6d\u00FCll\u00FC Yar\u0131\u015Fmalar\u0131\u2019nda de\u011Ferlendirilebilmesi i\u00E7in i\u00E7erisinde en az bir adet D\u00FCnya Kupas\u0131 ma\u00E7\u0131 olmas\u0131 gerekmektedir." }, void 0), _jsxs("ul", { children: [_jsx("li", { children: "En Y\u00FCksek Oranl\u0131 Tutan Kupon," }, void 0), _jsx("li", { children: "Sosyoley\u2019de En \u00C7ok Kupon Yapt\u0131ran," }, void 0), _jsx("li", { children: "En \u00C7ok Kazanan," }, void 0), _jsx("li", { children: "Sosyoley\u2019de En \u00C7ok Kazand\u0131ran" }, void 0)] }, void 0), _jsx("br", {}, void 0), _jsx("div", { children: _jsx("button", __assign({ className: 'btn btn-small mb-1', onClick: function () { return showHaftalikSartname(); } }, { children: "HAFTALIK D\u00DCNYA KUPASI YARI\u015EMALARI" }), void 0) }, void 0)] }), void 0) }), void 0)] }), void 0));
};
export default Katar2022Home;
