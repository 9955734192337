var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import BankTransferTableWidget from "../../widgets/withdrawmoney/bank.transfer.table.widget";
import { initialLeftMenu, sportsMenu } from "../../../../lib/misc.functions";
import BankTransferAddWidget from '../../widgets/withdrawmoney/bank.transfer.add.widget';
export default function WithdrawMoney() {
    var _a = useState(false), isTransfer = _a[0], setIsTransfer = _a[1];
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Para \u00C7ek" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body", style: { paddingLeft: '15px', paddingRight: '15px' } }, { children: _jsx(BankTransferAddWidget, { setIsTransfer: setIsTransfer, isTransfer: isTransfer }, void 0) }), void 0)] }), void 0), _jsx("br", {}, void 0), _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Banka Transferlerim" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body p-0" }, { children: _jsx(BankTransferTableWidget, { isTransfer: isTransfer }, void 0) }), void 0)] }), void 0)] }, void 0));
}
