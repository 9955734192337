var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { courseColors, tjkBetSlipActionSubject, tjkBetSlipOptions } from "../tjk.store";
import { addToTJKBetSlip, isAdded, isAddedAll } from "../tjk.betslip.service";
import iconSlash from "../../../assets/feather/slash.svg";
export function SingleColumnHorselist() {
    if (tjkBetSlipOptions.bettingSubType.split('-').length > 1) {
        return _jsx(_Fragment, {}, void 0);
    }
    else {
        return (_jsx(_Fragment, { children: Array.from(Array(tjkBetSlipOptions.bettingType.column).keys()).map(function (columnIndex, key) {
                var runObject = tjkBetSlipOptions.hippodrome.runs.find(function (r) { return r.no === tjkBetSlipOptions.bettingSubType; });
                var isAddedAllStatus = isAddedAll(key + 1, runObject);
                return (_jsxs("ul", __assign({ className: "column" }, { children: [_jsx("li", __assign({ className: "column-no", style: { backgroundColor: courseColors[runObject.course] } }, { children: columnIndex + 1 }), void 0), [14, 15, 16, 17, 18, 19].includes(tjkBetSlipOptions.bettingType.id) ?
                            _jsx("li", __assign({ className: "system ".concat(isAdded(columnIndex + 1, runObject, "s") !== false ? 'horse-added' : ''), onClick: function () {
                                    addToTJKBetSlip(key + 1, runObject, "s");
                                    tjkBetSlipActionSubject.next();
                                } }, { children: "S" }), void 0) : _jsx(_Fragment, {}, void 0), _jsx("li", __assign({ className: "all ".concat(isAddedAllStatus === true ? 'selected-all' : ''), onClick: function () {
                                runObject.horses.forEach(function (horse) {
                                    addToTJKBetSlip(key + 1, runObject, horse, isAddedAllStatus === false ? 'add' : 'remove');
                                });
                                tjkBetSlipActionSubject.next();
                            } }, { children: "H" }), void 0), runObject.horses.map(function (horse, key) {
                            return _jsx("li", __assign({ className: " ".concat(horse.agf_sort_no1 === 1 || horse.agf_sort_no2 === 1 ? 'agf-horse' : '', "  ").concat(isAdded(columnIndex + 1, runObject, horse) !== false ? 'horse-added' : '', " ").concat(horse.run_status === false ? 'horseNotAvailable' : ''), onClick: function () {
                                    addToTJKBetSlip(columnIndex + 1, runObject, horse, 'toggle');
                                } }, { children: horse.run_status !== true ? _jsx(_Fragment, { children: _jsx("img", { src: iconSlash }, void 0) }, void 0) : _jsx(_Fragment, { children: horse.no }, void 0) }), "tjk-column-selection-".concat(key));
                        })] }), "betslip-column-".concat(key)));
            }) }, void 0));
    }
}
