var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { ApiService } from "../../../lib/http.service";
import { useParams } from "react-router-dom";
import { initialLeftMenu, sportsMenu } from "../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../store/betslip.store";
export var Api = new ApiService();
var FiksturSonuclar = function () {
    var isMobile = window.outerHeight < 786;
    var params = useParams();
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    var request = {
        tournamentId: 256
    };
    Api.start('get', 'https://s1-sports-data-api.broadage.com/football/tournament/standings?tournamentId=22', request, false).then(function (res) {
        console.log(res, 'response');
    });
    var widget_body = {
        "bundleId": ["soccer-fx"],
        "accountId": "ae03a315-b8e3-454d-8985-0f59b1c8f86b",
        "widgets": {
            "soccerFixture": [{
                    "element": "qatar2022_fixture",
                    "tournamentId": 79,
                    "options": {
                        "lang": "tr-TR"
                    }
                }]
        }
    };
    useEffect(function () {
        // @ts-ignore
        if (window.broadageWidget.has('qatar2022_fixture')) {
            // @ts-ignore
            window.broadageWidget.remove('qatar2022_fixture');
        }
        // @ts-ignore
        window.broadageWidget.insert(widget_body);
    }, [params]);
    useEffect(function () {
        // @ts-ignore
        if (window.broadageWidget.has('qatar2022_fixture')) {
            // @ts-ignore
            window.broadageWidget.remove('qatar2022_fixture');
        }
        // @ts-ignore
        window.broadageWidget.insert(widget_body);
    }, []);
    return (_jsxs("div", __assign({ className: 'card' }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Fikst\u00FCr ve Sonu\u00E7lar" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", { id: "qatar2022_fixture" }, void 0) }), void 0)] }), void 0));
};
export default FiksturSonuclar;
