import { ApiService } from '../../../../lib/http.service';
import { env } from "../../../../definitions/global.vars";
export var Api = new ApiService();
export var FinancalTransactionsApi = (function () {
    return {
        depositMoney: function () {
            return Api.start('get', env.cdn_url + '/json/money_deposit_options.json', null, false);
        },
        myBankAccounts: function (request) {
            return Api.start('post', env.misc_api + '/member/api/account/list/', request, true);
        },
        addMyBankAccounts: function (request) {
            return Api.start('post', env.misc_api + '/member/api/account/add/', request, true);
        },
        deleteMyBankAccounts: function (request) {
            return Api.start('post', env.misc_api + '/member/api/account/delete/', request, true);
        },
        withdrawList: function (request) {
            return Api.start('post', env.list_api + '/accounting/api/withdraw/list/', request, true);
        },
        withdrawAdd: function (request) {
            return Api.start('post', env.core_api + '/accounting/api/withdraw/add/', request, true);
        },
        withdrawDelete: function (request) {
            return Api.start('post', env.core_api + '/accounting/api/withdraw/delete/', request, true);
        }
    };
})();
