var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { Storage } from "../../../../lib/localstorege.service";
import { useNavigate } from "react-router-dom";
import { dateTimeFormat, initialLeftMenu, ServerDate, sportsMenu, useForceUpdate } from "../../../../lib/misc.functions";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { Subject } from "rxjs";
import SporTotoDetail from "../../widgets/modal/spor.toto.detail";
import { Api, GameOperationsApi } from "./game.operations.api";
import { env } from "../../../../definitions/global.vars";
import Clock from "../../../../assets/feather/clock.svg";
import CheckCircle from "../../../../assets/feather/check-circle.svg";
import MinusCircle from "../../../../assets/feather/minus-circle.svg";
var page = 0;
var hasMore = true;
export var sporTotoCouponsMap = [];
export var sporTotoCouponsDetailMap = [];
var states = {
    1: 'devam eden',
    2: 'kazanan',
    3: 'kaybeden',
    5: 'kayıtlı',
};
var paramStates = {
    "devam-edenler": 1,
    "kazanan": 2,
    "kaybeden": 3,
};
var couponStatus = {
    1: _jsx("img", { src: Clock, width: 16 }, void 0),
    2: _jsx("img", { src: CheckCircle, width: 16 }, void 0),
    3: _jsx("img", { src: MinusCircle, width: 16 }, void 0)
};
export var couponDetailSubject = new Subject();
var SporToto = function (props) {
    var params = props.params;
    var sporTotoStatus = paramStates[params.couponId];
    var navigate = useNavigate();
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "widget card" }, { children: [_jsxs("div", __assign({ className: "header" }, { children: [_jsx("h1", { children: "Spor Toto Kuponlar\u0131m" }, void 0), _jsxs("ul", __assign({ className: "tabs clearfix" }, { children: [_jsx("li", __assign({ className: "cursor-pointer tab " + (sporTotoStatus === 1 ? 'active' : ''), onClick: function () { return navigate('/hesabim/spor-toto/devam-edenler'); } }, { children: "Devam Edenler" }), void 0), _jsx("li", __assign({ className: "cursor-pointer tab " + (sporTotoStatus === 2 ? 'active' : ''), onClick: function () { return navigate('/hesabim/spor-toto/kazanan'); } }, { children: "Kazananlar" }), void 0), _jsx("li", __assign({ className: "cursor-pointer tab " + (sporTotoStatus === 3 ? 'active' : ''), onClick: function () { return navigate('/hesabim/spor-toto/kaybeden'); } }, { children: "Kaybedenler" }), void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ id: "InfiniteScroll", className: "body p-0 " }, { children: [_jsx(ListCoupons, { params: params }, void 0), _jsx(SporTotoDetail, {}, void 0)] }), void 0)] }), void 0) }, void 0));
};
export default SporToto;
var sporTotoData = [];
var lockScroll = false;
var sporTotoStatus = 1;
var token = Storage.get('token');
var timer = null;
export function ListCoupons(props) {
    var forceUpdate = useForceUpdate();
    var params = props.params;
    useEffect(function () {
        window.scrollTo(0, 0);
        sporTotoData = [];
        fetchData(true);
    }, [params.couponId]);
    // @ts-ignore
    sporTotoStatus = paramStates[params.couponId];
    var fetchData = function (isFirst, forcePage, top) {
        if (isFirst === void 0) { isFirst = false; }
        if (forcePage === void 0) { forcePage = null; }
        if (top === void 0) { top = null; }
        try {
            // @ts-ignore
            document.getElementById('loadingAnimation').style.display = 'block';
            hasMore = false;
            if (isFirst) {
                sporTotoData = [];
                page = forcePage !== null ? forcePage : 0;
                hasMore = true;
            }
            var data = {
                token: Storage.get('token'),
                status: sporTotoStatus < 2 ? '0,1' : sporTotoStatus,
                page: page
            };
            // @ts-ignore
            var objectHeight_1 = document.getElementById('InfiniteScroll').offsetHeight;
            // @ts-ignore
            var objectTop_1 = document.getElementById('InfiniteScroll').offsetTop;
            Api.start('post', env.list_api + '/order/api/orders/sportoto/', data, true).then(function (result) {
                // @ts-ignore
                document.getElementById('loadingAnimation').style.display = 'none';
                if (result.status) {
                    if (result.data.length < 20) {
                        hasMore = false;
                        lockScroll = true;
                    }
                    else {
                        hasMore = true;
                    }
                    page += 1;
                    sporTotoData = sporTotoData.concat(result.data);
                    forceUpdate();
                    if (!isFirst) {
                        if (top === 0) {
                            window.scrollTo(0, top);
                        }
                        else {
                            var threshold = window.outerHeight < 700 ? 600 : 700;
                            window.scrollTo(0, (objectHeight_1 - objectTop_1 - threshold));
                        }
                    }
                    timer = setTimeout(function () {
                        if (result.data.length >= 20) {
                            lockScroll = false;
                            if (sporTotoData.length === 20 && objectHeight_1 < window.outerHeight) {
                                fetchData(false, 1, 0);
                            }
                        }
                        triggerScroll();
                    }, 500);
                }
                else {
                    modalServiceSubject.next({
                        title: 'beklenmedik bir sorun',
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 3000,
                    });
                }
            });
        }
        catch (e) {
            console.log(e);
        }
    };
    useEffect(function () {
        window.addEventListener('scroll', triggerScroll);
        return function () {
            clearTimeout(timer);
            window.removeEventListener('scroll', triggerScroll);
        };
    }, []);
    var triggerScroll = function () {
        var threshold = window.outerHeight < 968 ? 100 : 150;
        // @ts-ignore
        var objectHeight = document.getElementById('InfiniteScroll').offsetHeight;
        // @ts-ignore
        var objectTop = document.getElementById('InfiniteScroll').offsetTop;
        var visibleZone = (window.outerHeight - objectTop - threshold);
        if (objectHeight > visibleZone) {
            if ((objectHeight - visibleZone) < window.scrollY && !lockScroll) {
                lockScroll = true;
                fetchData(false);
            }
        }
    };
    var openCouponDetail = function (data) {
        couponDetailSubject.next(data);
    };
    function deleteSporToto(id, approve_code, draw_id) {
        var data = {
            token: token,
            id: id,
            draw_id: draw_id,
            coupon_code: approve_code
        };
        GameOperationsApi.sporTotoDelete(data).then(function (result) {
            if (result.status) {
                modalServiceSubject.next({
                    title: "Bilgilendirme",
                    content: approve_code + "numaralı kupon iptal edilmiştir",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
                var element = document.getElementById(id);
                element.style.display = "none";
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    }
    return (_jsxs(_Fragment, { children: [_jsxs("table", __assign({ className: "table" }, { children: [_jsx(Title, { sporTotoStatus: sporTotoStatus }, void 0), _jsx("tbody", { children: sporTotoData.map(function (data, key) {
                            var date = ServerDate();
                            return (_jsxs("tr", __assign({ onClick: function () { return openCouponDetail(data); }, id: data.id, className: "cursor-pointer" }, { children: [_jsx("td", __assign({ className: "text-center d-none d-md-table-cell" }, { children: data.approve_code }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: dateTimeFormat(data.approve_date) }), void 0), _jsxs("td", __assign({ className: "text-right" }, { children: [(Number(data.amount) / (data.unit === 1 ? 100 : 1)).toFixed(2), " \u20BA"] }), void 0), sporTotoStatus === 2 ? _jsxs("td", __assign({ className: "text-right" }, { children: [Number(data.winning).toFixed(2), " \u20BA"] }), void 0) : null, ((new Date(data.approve_date)) < (new Date(date.setSeconds(date.getSeconds() - 900)))) ? (_jsx("td", { children: couponStatus[data.status] }, void 0)) : (_jsx("td", __assign({ className: "text-center" }, { children: _jsx("button", __assign({ className: "btn btn-small btn-primary", onClick: function () { return deleteSporToto(data.id, data.approve_code, data.draw_id); } }, { children: "\u0130ptal Et" }), void 0) }), void 0))] }), "accountsportToto" + key));
                        }) }, void 0)] }), void 0), hasMore == false && sporTotoData.length === 0 ? _jsx(_Fragment, { children: _jsxs("p", __assign({ className: "text-center py-5" }, { children: ["Listelenecek ", states[sporTotoStatus], " kuponunuz bulunmamaktad\u0131r."] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ id: "loadingAnimation", className: "text-center py-5" }, { children: _jsxs("div", __assign({ className: "lds-roller", style: { position: 'unset' } }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }), void 0)] }, void 0));
}
var Title = function (props) {
    var sporTotoStatus = props.sporTotoStatus;
    return (_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center d-none d-md-table-cell" }, { children: "Kupon No" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Tarih" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Kupon Bedeli" }), void 0), sporTotoStatus === 2 ? _jsx("th", __assign({ className: "text-center" }, { children: "Kazan\u00E7" }), void 0) : null, _jsx("th", __assign({ className: "text-center" }, { children: "Durum" }), void 0)] }, void 0) }, void 0));
};
