var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { loginSubject, useAuthContext } from "../../store/login.store";
import { Link } from "react-router-dom";
export default function Login() {
    // @ts-ignore
    var _a = useAuthContext(), isAuthenticated = _a.isAuthenticated, setIsAuthenticated = _a.setIsAuthenticated;
    useEffect(function () {
        var loginSubject_subscription = loginSubject.subscribe(function (member) {
            if (member.id !== undefined && member.status === 1) {
                setIsAuthenticated(true);
            }
        });
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    }, []);
    return (_jsxs("div", __assign({ className: "text-right mt-sm-0 pr-2" }, { children: [_jsx(Link, __assign({ className: "btn btn-warning btnLogin ", to: "/giris-yap" }, { children: "G\u0130R\u0130\u015E YAP" }), void 0), _jsx(Link, __assign({ className: "btn btn-success btnRegister", to: "/kayit" }, { children: "\u00DCYE OL" }), void 0)] }), void 0));
}
