var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { initialLeftMenu, sportsMenu } from "../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../store/betslip.store";
var Kadrolar = function () {
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    var params = useParams();
    var isMobile = window.outerHeight < 786;
    var _a = useState(1853), selectedTeam = _a[0], setSelectedTeam = _a[1];
    var widget_body = {
        "bundleId": ["soccer-sqs"],
        "accountId": "ae03a315-b8e3-454d-8985-0f59b1c8f86b",
        "widgets": {
            "soccerSquadStats": [{
                    "element": "qatar2022_squads",
                    "teamId": selectedTeam,
                    "options": {
                        "lang": "tr-TR",
                        "tournamentId": 79
                    }
                }]
        }
    };
    var teams = [
        { id: 1853, name: 'ABD' },
        { id: 692, name: 'Almanya' },
        { id: 12120, name: 'Arjantin' },
        { id: 12090, name: 'Avustralya' },
        { id: 2064, name: 'Belçika' },
        { id: 2162, name: 'Brezilya' },
        { id: 2268, name: 'Danimarka' },
        { id: 5208, name: 'Ekvador' },
        { id: 5297, name: 'Fas' },
        { id: 1057, name: 'Fransa' },
        { id: 5404, name: 'Galler' },
        { id: 12193, name: 'Gana' },
        { id: 5396, name: 'Güney Kore' },
        { id: 5575, name: 'Hırvatistan' },
        { id: 2815, name: 'Hollanda' },
        { id: 249, name: 'İngiltere' },
        { id: 5685, name: 'İran' },
        { id: 455, name: 'İspanya' },
        { id: 3238, name: 'isviçre' },
        { id: 8231, name: 'Japonya' },
        { id: 5836, name: 'Kamerun' },
        { id: 12119, name: 'Kanada' },
        { id: 5857, name: 'Katar' },
        { id: 12116, name: 'Kosta Rika' },
        { id: 3379, name: 'Meksika' },
        { id: 6719, name: 'Polonya' },
        { id: 4167, name: 'Portekiz' },
        { id: 6997, name: 'Senegal' },
        { id: 7051, name: 'Sırbistan' },
        { id: 6981, name: 'Suudi Arabistan' },
        { id: 7385, name: 'Tunus' },
        { id: 7450, name: 'Uruguay' }
    ];
    useEffect(function () {
        // @ts-ignore
        if (window.broadageWidget.has('qatar2022_squads')) {
            // @ts-ignore
            window.broadageWidget.remove('qatar2022_squads');
        }
        // @ts-ignore
        window.broadageWidget.insert(widget_body);
    }, [params, selectedTeam]);
    useEffect(function () {
        // @ts-ignore
        if (window.broadageWidget.has('qatar2022_squads')) {
            // @ts-ignore
            window.broadageWidget.remove('qatar2022_squads');
        }
        // @ts-ignore
        window.broadageWidget.insert(widget_body);
    }, []);
    return (_jsxs("div", __assign({ className: 'card' }, { children: [_jsxs("div", __assign({ className: "card-title d-flex" }, { children: [_jsx("h2", { children: "Kadrolar" }, void 0), _jsx("select", __assign({ className: 'ml-auto w-25', name: "", id: "", onChange: function (e) { return setSelectedTeam(Number(e.target.value)); } }, { children: teams.map(function (team) {
                            return (_jsx("option", __assign({ value: team.id }, { children: team.name }), void 0));
                        }) }), void 0)] }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", { id: "qatar2022_squads" }, void 0) }), void 0)] }), void 0));
};
export default Kadrolar;
