var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FinancalTransactionsApi } from "../../subpages/financialTransactions/financial.transactions.api";
import { Storage } from "../../../../lib/localstorege.service";
import CheckCircle from "../../../../assets/feather/check-circle.svg";
import iconInfo from "../../../../assets/feather/info.svg";
import { dateTimeFormat } from "../../../../lib/misc.functions";
import { modalServiceSubject } from "../../../../components/modal/modal";
import MinusCircle from "../../../../assets/feather/minus-circle.svg";
var Title = function () {
    return (_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "Tarih" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Tutar" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Hesap" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Ba\u015Far\u0131l\u0131" }), void 0)] }, void 0) }, void 0));
};
var BankTransferTableWidget = function (props) {
    var _a = useState([]), withdrawData = _a[0], setWithdrawData = _a[1];
    var token = Storage.get('token');
    var data = {
        token: token
    };
    useEffect(function () {
        FinancalTransactionsApi.withdrawList(data).then(function (result) {
            if (result.status) {
                setWithdrawData(result.data);
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    }, [props.isTransfer]);
    function deleteTransfer(id) {
        var data = {
            token: token,
            id: id,
        };
        FinancalTransactionsApi.withdrawDelete(data).then(function (result) {
            if (result.status) {
                var element = document.getElementById(id);
                element.style.display = "none";
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    }
    return ((withdrawData.length !== 0) ? (_jsx("div", { children: _jsxs("table", __assign({ className: "table" }, { children: [_jsx(Title, {}, void 0), _jsx("tbody", { children: withdrawData ? withdrawData.map(function (data, key) {
                        return (_jsxs("tr", __assign({ id: data.id }, { children: [data.process_is_succeed === true ?
                                    _jsx("td", __assign({ className: "text-center" }, { children: dateTimeFormat(data.process_date) }), void 0) : _jsx("td", __assign({ className: "text-center" }, { children: dateTimeFormat(data.create_date) }), void 0), _jsxs("td", __assign({ className: "text-center" }, { children: [data.amount, "\u20BA"] }), void 0), _jsx("td", __assign({ className: "visible-sm text-center" }, { children: _jsx("i", __assign({ title: data.account.display_label }, { children: _jsx("img", { className: "invert-color", src: iconInfo, onClick: function () {
                                                modalServiceSubject.next({
                                                    content: data.account.display_label,
                                                    confirm: {
                                                        cancel: { label: 'Tamam' }
                                                    },
                                                    timeout: 0
                                                });
                                            }, width: 16 }, void 0) }), void 0) }), void 0), _jsx("td", __assign({ className: "hidden-sm text-center" }, { children: data.account.display_label }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: data.is_processed === false && data.status === 0 ?
                                        _jsx("button", __assign({ className: "btn btn-small btn-primary float-right", onClick: function () { return deleteTransfer(data.id); } }, { children: "\u0130ptal Et" }), void 0)
                                        : _jsxs(_Fragment, { children: [data.status === 0 ? _jsx("img", { src: iconInfo, width: 16 }, void 0) : null, data.status === 1 ? _jsx("img", { src: CheckCircle, width: 16 }, void 0) : null, data.status === 3 ? _jsx("img", { src: MinusCircle, width: 16 }, void 0) : null] }, void 0) }), void 0)] }), "withdrawmoneytable" + key));
                    }) : null }, void 0)] }), void 0) }, void 0)) : (_jsx("div", __assign({ className: "card-title pt-0 pb-3" }, { children: "Banka transfer i\u015Flemi bulunmamaktad\u0131r." }), void 0)));
};
export default BankTransferTableWidget;
