var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Storage } from "../../lib/localstorege.service";
import { useParams } from "react-router-dom";
import { dateTimeFormat, initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { notificationsDetailMap } from "./notifications";
import { Api, NotificationsApi } from './notifications.api';
import Close from "../../assets/feather/x-circle.svg";
import { useNavigate } from "react-router";
import { env } from "../../definitions/global.vars";
export var seenStatus = false;
var NotificationDetail = function () {
    var token = Storage.get('token');
    var navigate = useNavigate();
    var params = useParams();
    var dataNotification = notificationsDetailMap.find(function (x) { return x._id == params.id; });
    var _a = useState(dataNotification), notification = _a[0], setNotification = _a[1];
    useEffect(function () {
        if (innerWidth > 968) {
            betSlipVisibleSubject.next(true);
        }
        return function () {
            betSlipVisibleSubject.next(false);
        };
    }, []);
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        var data = {
            token: token,
            nofication_id: params.id,
        };
        if (notification) {
            Api.start('post', env.misc_api + '/misc/api/mark-seen-notification/', data, true).then(function (result) {
                seenStatus = result.status;
            });
            NotificationsApi.get_notification(data).then(function (result) {
                if (result.status) {
                    setNotification(result.data.find(function (x) { return x._id == params.id; }));
                }
            });
        }
    }, []);
    if (!notification) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsx("div", __assign({ className: "container-fluid page pl-lg-3 pl-md-3 pr-md-5 pl-sm-0 pr-sm-0" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "right-column p-md-0 p-sm-1" }, { children: _jsxs("div", __assign({ className: "card mt-sm-0" }, { children: [_jsxs("div", __assign({ className: "card-title mb-1" }, { children: [_jsx("h2", { children: notification.title }, void 0), _jsx("div", __assign({ className: "float-right" }, { children: _jsx("div", __assign({ className: "close-detail invert-color cursor-pointer" }, { children: _jsx("img", { src: Close, width: 24, onClick: function () { navigate('/bildirimler'); } }, void 0) }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("div", { dangerouslySetInnerHTML: { __html: notification.content } }, void 0), _jsx("div", __assign({ className: "float-right" }, { children: dateTimeFormat(notification.create_date) }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0));
};
export default NotificationDetail;
