export var EventStatusType = {
    scheduled: 1,
    firstHalf: 2,
    secondHalf: 3,
    halfTime: 4,
    fullTime: 5,
    extraTimeFirstHalf: 6,
    extraTimeHalfTime: 7,
    extraTimeSecondHalf: 8,
    finishedWithExtraTime: 9,
    penalties: 10,
    finishedWithPenalty: 11,
    firstPeriod: 12,
    secondPeriod: 13,
    thirdPeriod: 14,
    fourthPeriod: 15,
    firstSet: 16,
    secondSet: 17,
    thirdSet: 18,
    fourthSet: 19,
    fifthSet: 20,
    postponed: 21,
    stopped: 22,
    cancelled: 23,
    thirdExtraTime: 24,
    fourthExtraTime: 25,
    fifthExtraTime: 26,
    sixthExtraTime: 27,
    seventhExtraTime: 28,
    eighthExtraTime: 29,
    ninthExtraTime: 30,
    continues: 31,
    extraTimeToBegin: 32,
    penaltiesToBegin: 33,
    fifthPeriod: 34,
    sixthPeriod: 35,
    seventhPeriod: 36,
    eighthPeriod: 37,
    ninthPeriod: 38,
    sixthSet: 39,
    sevenSet: 40,
    eightSet: 41,
    ninthSet: 42,
    firstInning: 43,
    secondInning: 44,
    break: 46,
    first_day: 47,
    end_of_first_day: 48,
    second_day: 49,
    end_of_second_day: 50,
    third_day: 51,
    end_of_third_day: 52,
    forth_day: 53,
    end_of_forth_day: 54,
    fifth_day: 55,
    end_of_fifth_day: 56
};
export var LiveScoreStatusType = {
    500: 'Bşl.',
    501: 'D.A.',
    502: 'Uz.B.',
    503: 'Uz.',
    504: 'Uz.Ara',
    505: 'Uz.S.',
    506: 'M.S.',
    507: 'Kesl.',
    508: 'İpt.',
    509: 'Askı',
    510: 'P.S.',
    511: 'Hük.',
    512: 'Çek.',
    513: 'Gec.',
    514: 'Ert.',
    515: 'Terk',
    516: 'Dvm.',
    520: '1.Y.',
    521: '2.Y.',
    522: '1.U.',
    523: '2.U.',
    524: 'P.Bşl.',
    525: 'Pen.',
    526: '1.P.',
    527: '2.P.',
    528: '3.P',
    529: '1.Ç.',
    530: '1.Ara',
    531: '2.Ç.',
    532: '2.Ara',
    533: '3.Ç.',
    534: '3.Ara',
    535: '4.Ç.',
    536: '4.Ara',
    537: '1.Set',
    538: '2.Set',
    539: '3.Set',
    540: '4.Set',
    541: '5.Set',
    542: '1.Oy.',
    543: '2.Oy.',
    544: '3.Oy.',
    545: '4.Oy.',
    546: '5.Oy.',
    547: '6.Oy.',
    548: '7.Oy.',
    549: 'Al.S.',
    550: 'Al.S.S.',
    592: 'D.A',
    700: 'Bşl.',
};
export var sportSlugId = {
    1: "futbol",
    2: "voleybol",
    4: "buz hokeyi",
    6: "basketbol",
    8: "voleybol",
    10: "tenis",
    11: "hentbol",
    13: "snooker",
    15: "hockey",
    16: "mma",
    19: "snooker",
    20: "masa tenisi",
    23: "hentbol"
};
