var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { renewPasswordApi } from "../../services/login.services";
import { Link, useNavigate, useParams } from "react-router-dom";
import '../custom.css';
import { betSlipVisiblity } from "../../shared/betslip/betslip";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { modalServiceSubject } from "../../components/modal/modal";
var RenewPasswordPage = function () {
    var params = useParams();
    var navigate = useNavigate();
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var onSubmit = function (data) {
        data.hash = params.hash;
        renewPasswordApi(data).then(function (response) {
            if (response.status) {
                modalServiceSubject.next({
                    title: 'İşlem başarıyla tamamlandı',
                    content: "Yeni parolanızın kaydı başarıyla tamamlandı, bilgi güvenliğiniz için lütfen parolanızı oley.com ekibi dahil kimse ile paylaşmayınız",
                    confirm: {
                        sure: {
                            label: 'Giriş Yap', action: function () {
                                navigate('/giris-yap/');
                            }
                        },
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 10000
                });
                navigate('/');
            }
            else {
                modalServiceSubject.next({
                    title: 'Uyarı!',
                    content: response.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 5000
                });
            }
        }).catch(function (e) {
            console.log(e);
            modalServiceSubject.next({
                title: 'Uyarı!',
                content: "Beklenmedik bir hata oluştu, Lütfen tekrar deneyin",
                confirm: {
                    cancel: { label: 'Tamam' }
                },
                timeout: 5000
            });
        });
    };
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsx("div", __assign({ className: "container-fluid page login " }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "left-column" }, { children: _jsxs("ul", __assign({ className: "account-menu" }, { children: [_jsx("li", { children: _jsx("span", { children: "Bilgi Al" }, void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/uyelik" }, { children: "Yard\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/kisisel-verilerin-korunmasi" }, { children: "Ki\u015Fisel Verilerin Korunmas\u0131" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/kisisel-veri-basvurulari" }, { children: "Ki\u015Fisel Veri Ba\u015Fvurular\u0131" }), void 0) }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "right-column" }, { children: _jsxs("div", __assign({ className: betSlipVisiblity ? "width-bet-slip card" : "w-100 card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Yeni Parola Olu\u015Ftur" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("div", __assign({ className: "row table" }, { children: [_jsx("div", __assign({ className: "col-lg-5 col-sm-12" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", __assign({ type: "hidden" }, register('hash'), { value: params.hash }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "Yeni Parola" }, void 0), _jsx("input", __assign({ type: "password", className: "form-control" }, register('password', { required: true })), void 0), errors.password &&
                                                                _jsx("div", __assign({ className: "text-validation" }, { children: "L\u00FCtfen bir parola belirleyin." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "Yeni Parola (Tekrar)" }, void 0), _jsx("input", __assign({ type: "password", className: "form-control" }, register('password2', { required: true })), void 0), errors.password2 &&
                                                                _jsx("div", __assign({ className: "text-validation" }, { children: "L\u00FCtfen bir parola belirleyin." }), void 0)] }), void 0), _jsx("div", __assign({ className: "mt-2" }, { children: _jsx("input", { type: "submit", className: "btn btn-primary mt-2", value: "Kaydet" }, void 0) }), void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ className: "col-lg-7 col-sm-12 mt-sm-4" }, { children: [_jsxs("div", { children: [_jsxs("div", { children: [_jsx("p", { children: "\u00DCye olurken kulland\u0131\u011F\u0131n\u0131z e-posta adresiniz, \u00FCye numaran\u0131z ya da T.C. Kimlik Numaran\u0131z ve \u015Fifreniz ile birlikte Oley.com\u2019a giri\u015F yapabilirsiniz." }, void 0), _jsx("p", { children: "Oley.com\u2019da \u0130ddaa ve Spor Toto oynamak ve Milli Piyango bileti sat\u0131n almak i\u00E7in \u00FCyeli\u011Finizin bulunmas\u0131 gerekmektedir." }, void 0), _jsx("p", { children: "Spor Toto'nun yasal bayisi olan Oley.com' a 18 ya\u015F\u0131ndan b\u00FCy\u00FCkler \u00FCye olabilir." }, void 0)] }, void 0), "\u00DCyelik ile ilgili detayl\u0131 bilgi i\u00E7in\u00A0", _jsx("span", { children: _jsx(Link, __assign({ to: "/yardim/uyelik" }, { children: " Yard\u0131m " }), void 0) }, void 0), "' a T\u0131kla"] }, void 0), _jsx("div", { children: _jsx("img", { src: "../../assets/img/oley-logo-blue.png", alt: "" }, void 0) }, void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default RenewPasswordPage;
