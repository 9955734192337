var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createContext, useContext } from 'react';
import { BetSlip } from "../models/betslip.model";
import { betSlipActionSubject, BetSlipList, BetSlipOption } from "../store/betslip.store";
import { Storage } from "../lib/localstorege.service";
import { eventMapping, marketIdMapping, sportsbookList } from "../store/sportsbook.store";
import _ from "lodash";
import { member } from "../store/login.store";
import { ApiService } from "../lib/http.service";
import Check from "../assets/feather/check.svg";
import AlertTriangle from "../assets/feather/alert-triangle.svg";
import { modalServiceSubject } from "../components/modal/modal";
import { env } from "../definitions/global.vars";
import { AuthWSProvider } from "./websocket.services";
import { refreshMemberInfo } from "./login.services";
import { isAndroid, isIOS, isMobile, isMacOs, isWindows } from 'react-device-detect';
import html2canvas from "html2canvas";
import { eventEndedStatuses } from "../definitions/constants/event-result-type";
import { dataLayerAddToCart, dataLayerBetError, dataLayerPurchase, dataLayerRemoveFromCart, dataLayerSaveBet, savePurchaseData } from "./push.services";
var Combinatorics = require('js-combinatorics/combinatorics.js');
export var betSlipMaxMultiply = 10000;
var checkTimer;
var clearTimer;
var firstDelay;
export var BetSlipDisplayContext = createContext({
    betSlipVisible: false,
    setBetSlipVisible: function (c) {
        console.log("setBetSlipVisible>>", c);
    },
});
export var useBetSlipDisplayContext = function () { return useContext(BetSlipDisplayContext); };
function storeBetSlip() {
    var filteredBetslip = BetSlipList.filter(function (el) { return el.sportEvent !== null || typeof el.sportEvent !== 'undefined'; });
    var betslip = filteredBetslip.map(function (el) {
        return {
            eventId: el.sportEvent.eventId,
            marketId: el.marketId,
            outcomeNo: el.outcomeNo,
            isBanker: el.isBanker
        };
    });
    Storage.set('betslip', JSON.stringify(betslip));
}
export function restoreBetSlip() {
    var betslipData = Storage.get('betslip');
    if (betslipData) {
        var _loop_1 = function (item) {
            var event_1 = eventMapping[item.eventId.toString()];
            if (event_1) {
                try {
                    var market = marketIdMapping[event_1.eventId][item.marketId];
                    if (market) {
                        var outcome = market.marketOutcome.find(function (el) { return el.outcomeNo === item.outcomeNo; });
                        if (outcome) {
                            addToBetSlip(event_1, market, outcome, 'restore', item.isBanker);
                        }
                    }
                }
                catch (e) {
                }
            }
        };
        for (var _b = 0, _c = JSON.parse(betslipData); _b < _c.length; _b++) {
            var item = _c[_b];
            _loop_1(item);
        }
    }
    var processId = Storage.get('process_id');
    var couponIds = Storage.get('coupon_ids');
    // console.log(processId)
    // console.log(couponIds)
    if (processId && betslipData) {
        BetSlipOption.process_id = processId;
        if (couponIds) {
            BetSlipOption.coupon_ids = JSON.parse(couponIds);
            liveCouponResults(null);
        }
    }
}
export function clearBetSlip(justItems) {
    if (justItems === void 0) { justItems = false; }
    if (!justItems) {
        BetSlipOption.bettingInfoStatus = null;
        BetSlipOption.bettingInfoMessage = null;
        BetSlipOption.inProgress = false;
        BetSlipOption.betSlipMultiply = 30;
        BetSlipOption.betSlipQuantity = 1;
    }
    var eventIds = [];
    for (var _b = 0, BetSlipList_1 = BetSlipList; _b < BetSlipList_1.length; _b++) {
        var item = BetSlipList_1[_b];
        eventIds.push(item.sportEvent.eventId);
        item.outcome.isSelected = false;
        item.sportEvent.isAdded = false;
    }
    BetSlipList.splice(0, BetSlipList.length);
    BetSlipOption.process_id = '';
    BetSlipOption.coupon_ids = [];
    Storage.del('play_coupon');
    Storage.del('process_id');
    Storage.del('coupon_ids');
    storeBetSlip();
    betSlipActionSubject.next({
        action: 'clear',
        betSlip: eventIds,
    });
}
function couponSystem(coupons, systems) {
    var betSets = [];
    var certainBets = [];
    var i = 0;
    var tmp;
    var _a;
    for (i = 0; i < coupons.length; i++) {
        if (coupons[i].isBanker) {
            certainBets.push(coupons[i]);
        }
    }
    function addToBet(coupons) {
        var control = true;
        if (systems.indexOf(coupons.length) >= 0) {
            if (certainBets.length > 0) {
                for (var _i = 0; _i < certainBets.length; _i++) {
                    if (coupons.indexOf(certainBets[_i]) === -1) {
                        control = false;
                        break;
                    }
                }
                if (control) {
                    betSets.push(coupons);
                }
            }
            else {
                betSets.push(coupons);
            }
        }
    }
    for (i = 0; i < systems.length; i++) {
        if (coupons.length > 0) {
            try {
                tmp = Combinatorics.bigCombination(coupons, systems[i]);
            }
            catch (e) {
                console.log('SlipHelper.couponSystem.cactch', e);
                continue;
            }
            _a = tmp.next();
            while (_a) {
                addToBet(_a);
                _a = tmp.next();
            }
        }
    }
    return betSets;
}
export function combinationCalculation() {
    var specialRatio = 1;
    var li = BetSlipOption.systemOptions.filter(function (el) { return el.sysSelected === true; }).map(function (el) {
        return el.sysNum;
    });
    // eger secili bir system yoksa tek kupon hesaplama icin max degerli bir tek kombinasyon ekleniyor
    if (li.length === 0) {
        li.push(BetSlipList.length);
    }
    var systemComb = couponSystem(BetSlipList, li);
    // kupon toplam oran hesaplama
    var couponTotalRatio = 0;
    for (var _b = 0, systemComb_1 = systemComb; _b < systemComb_1.length; _b++) {
        var group = systemComb_1[_b];
        var rowRatio = 1;
        for (var _c = 0, group_1 = group; _c < group_1.length; _c++) {
            var coupon = group_1[_c];
            // let add: any = 0;
            // if(
            //     AdditionalRatioCampaign &&
            //     AdditionalRatioCampaign.specialEvents &&
            //     AdditionalRatioCampaign.specialEvents[String(coupon.market.event)] &&
            //     (
            //         AdditionalRatioCampaign.specialEvents[String(coupon.market.event)][String(coupon.marketId)] ||
            //         AdditionalRatioCampaign.specialEvents[String(coupon.market.event)]["*"]
            //     )
            // ){
            //   if(AdditionalRatioCampaign.specialEvents[String(coupon.market.event)]["*"]){
            //       add = AdditionalRatioCampaign.specialEvents[String(coupon.market.event)]["*"]
            //   }else{
            //       add = AdditionalRatioCampaign.specialEvents[String(coupon.market.event)][String(coupon.marketId)]
            //   }
            // }
            // specialRatio *= add;
            // rowRatio = rowRatio * (parseFloat(coupon.outcome.fixedOddsWeb) + add) ;
            rowRatio = rowRatio * parseFloat(coupon.outcome.fixedOddsWeb);
        }
        couponTotalRatio += rowRatio;
    }
    // BetSlipOption.specialRatio = specialRatio;
    BetSlipOption.systemCombinations = systemComb;
    BetSlipOption.totalRatio = couponTotalRatio;
}
export function setBanker(betSlip) {
    betSlip.isBanker = !betSlip.isBanker;
    storeBetSlip();
    betSlipActionSubject.next({
        action: 'calc',
        betSlip: BetSlipList,
    });
}
export function setBetSlipQuantity(value) {
    if (!member || !member.id || !member.max_rank || member.max_rank > 50) {
        if (value > 100) {
            value = 100;
        }
    }
    else if (value > 500) {
        value = 500;
    }
    BetSlipOption.betSlipQuantity = value;
    betSlipActionSubject.next({
        action: 'calc',
        betSlip: null,
    });
}
export function setBetSlipMultiply(value) {
    BetSlipOption.betSlipMultiply = value;
    betSlipActionSubject.next({
        action: 'calc',
        betSlip: null,
    });
}
export function setUsePointBalance(value) {
    BetSlipOption.usePointBalance = value;
    betSlipActionSubject.next({
        action: 'calc',
        betSlip: null,
    });
}
export function setBetSlipConditions(value) {
    if (value === "1") {
        BetSlipOption.accept_higher_odds = true;
        BetSlipOption.accept_lower_odds = true;
    }
    else if (value === "2") {
        BetSlipOption.accept_higher_odds = true;
        BetSlipOption.accept_lower_odds = false;
    }
    else if (value === "3") {
        BetSlipOption.accept_higher_odds = false;
        BetSlipOption.accept_lower_odds = false;
    }
    BetSlipOption.couponCondition = value;
    betSlipActionSubject.next({
        action: 'calc',
        betSlip: null,
    });
}
export function updateSystemOptions() {
    var betSlipSystemOptions = BetSlipOption.systemOptions || [];
    var minSystemCount = 0;
    if (BetSlipList.length > 0) {
        minSystemCount = BetSlipList.map(function (el) {
            return el.market.minCombiCount;
        }).sort().reverse()[0];
    }
    var bankerCount = BetSlipList.filter(function (item) { return item.isBanker === true; }).length;
    if (bankerCount > minSystemCount) {
        minSystemCount = bankerCount;
    }
    var maxSystemCount = BetSlipList.length;
    var newSet = betSlipSystemOptions.filter(function (item) { return item.sysNum >= minSystemCount && item.sysNum <= maxSystemCount; });
    var _loop_2 = function (i) {
        var totalAmount = Combinatorics.C(maxSystemCount, i) * BetSlipOption.betSlipMultiply;
        if (betSlipSystemOptions.filter(function (item) { return Number(item.sysNum) === Number(i); }).length === 0) {
            newSet.push({
                sysNum: Number(i),
                sysSelected: false
            });
        }
        if (totalAmount > betSlipMaxMultiply) {
            newSet = newSet.filter(function (el) { return Number(el.sysNum) !== i; });
        }
    };
    for (var i = minSystemCount; i <= maxSystemCount; i++) {
        _loop_2(i);
    }
    BetSlipOption.systemOptions = _.sortBy(newSet, 'sysNum');
    // betSlipOptions.systemOptions = betSlipSystemOptions;
    // this.betSlipSystemOptions = betSlipSystemOptions.filter(el=>el.sysNum>0);
    // this.storage.set('betSlipOptions', JSON.stringify(betSlipOptions)).then(response => {
    //   this.betSlipOptions = betSlipOptions;
    // });
    //
    // betSlipActionSubject.next({
    //   action: 'calc',
    //   betSlip: null,
    // });
}
export function setSystemChoice(systemChoice) {
    systemChoice.sysSelected = !systemChoice.sysSelected;
    betSlipActionSubject.next({
        action: 'calc',
        betSlip: null,
    });
}
export function initializeBetSlip() {
    updateSystemOptions();
    combinationCalculation();
}
export function removeFromBetSlip(event, market, outcome) {
    addToBetSlip(event, market, outcome);
}
export function addToBetSlip(event, market, outcome, actionType, isBanker) {
    // console.log(market)
    if (actionType === void 0) { actionType = 'toggle'; }
    if (isBanker === void 0) { isBanker = false; }
    if (BetSlipOption.inProgress === true && actionType !== 'restore') {
        modalServiceSubject.next({
            title: 'Lütfen bekleyiniz',
            content: 'Oynanmakta olan kuponunuz mevcut. Lütfen bekleyiniz.',
            confirm: {
                cancel: { label: 'Tamam' }
            },
            timeout: 0,
        });
        return false;
    }
    else if (BetSlipOption.bettingInfoStatus === 3) {
        clearTimeout(clearTimer);
        clearBetSlip();
    }
    if (BetSlipList.length === 20 && !event.isAdded) {
        modalServiceSubject.next({
            title: 'Uyarı!',
            content: 'Maksimum 20 oyun ekleyebilirsiniz.',
            confirm: {
                cancel: { label: 'Tamam' }
            },
            timeout: 0,
        });
        return false;
    }
    // console.log("current BetSlipList>>>",BetSlipList)
    // console.log(event, market.marketStatus)
    var betslipItem = new BetSlip();
    if (event && market && outcome &&
        Number(event.bettingStatus) !== -1 &&
        Number(event.bettingPhase) > -1 &&
        Number(outcome.fixedOddsWeb) > 1 &&
        Number(market.marketStatus) === 1 &&
        !eventEndedStatuses.includes(event.liveScoreStatus) &&
        (actionType == 'toggle' || actionType == 'add' || actionType == 'restore')) {
        betslipItem.sportEvent = event;
        betslipItem.market = market;
        betslipItem.marketId = market.marketId;
        betslipItem.outcomeNo = outcome.outcomeNo;
        betslipItem.outcomeName = outcome.outcomeName;
        betslipItem.outcome = outcome;
        betslipItem.isBanker = isBanker;
        if (actionType == 'toggle') {
            outcome.isSelected = !outcome.isSelected;
        }
        else {
            outcome.isSelected = true;
        }
    }
    else if (actionType === 'remove') {
        betslipItem.sportEvent = event;
        betslipItem.outcomeNo = outcome.outcomeNo;
        betslipItem.marketId = market.marketId;
        outcome.isSelected = false;
    }
    if (actionType == 'toggle' || actionType == 'add' || actionType == 'restore') {
        var alreadyExistIndex = BetSlipList.findIndex(function (el) { return el.sportEvent && event.eventId === el.sportEvent.eventId && market.marketId === el.marketId && outcome.outcomeNo === el.outcomeNo; });
        var sameEventExistIndex = BetSlipList.findIndex(function (el) { return el.sportEvent && event.eventId === el.sportEvent.eventId; });
        var filtered = BetSlipList.filter(function (el) {
            if (el.sportEvent && event.eventId !== el.sportEvent.eventId) {
                return el;
            }
        });
        if (alreadyExistIndex !== -1 && actionType == 'toggle') {
            BetSlipList[alreadyExistIndex].outcome.isSelected = false;
            BetSlipList.splice(alreadyExistIndex, 1);
        }
        else if (sameEventExistIndex !== -1) {
            BetSlipList[sameEventExistIndex].outcome.isSelected = false;
            BetSlipList.splice(sameEventExistIndex, 1);
            if (typeof betslipItem.sportEvent !== 'undefined') {
                filtered.push(betslipItem);
            }
        }
        else {
            if (typeof betslipItem.sportEvent !== 'undefined') {
                filtered.push(betslipItem);
            }
        }
        Object.assign(BetSlipList, filtered);
    }
    else if (actionType == 'remove') {
        var alreadyExistIndex = BetSlipList.findIndex(function (el) { return event.eventId === el.sportEvent.eventId && market.marketId === el.marketId && outcome.outcomeNo === el.outcomeNo; });
        BetSlipList[alreadyExistIndex].outcome.isSelected = false;
        BetSlipList.splice(alreadyExistIndex, 1);
    }
    else if (actionType == 'removeEvent') {
        var alreadyExistIndex = BetSlipList.findIndex(function (el) { return event.eventId === el.sportEvent.eventId; });
        console.log("alreadyExistIndex>>>>>", alreadyExistIndex);
        BetSlipList[alreadyExistIndex].outcome.isSelected = false;
        BetSlipList.splice(alreadyExistIndex, 1);
    }
    // betslipList save to localstorage
    if (actionType !== 'restore') {
        storeBetSlip();
    }
    event.isAdded = outcome ? outcome.isSelected : false;
    BetSlipOption.errorMessage = undefined;
    if (actionType !== 'restore') {
        betslipItem.sportEvent = event;
        betSlipActionSubject.next({
            action: actionType,
            betSlip: betslipItem,
        });
    }
    try {
        if (outcome.isSelected) {
            dataLayerAddToCart({
                item_name: event.eventName,
                item_id: event.eventId,
                price: 0.0,
                item_category: event.sportName,
                item_category2: event.competitionGroupName,
                item_category3: event.competitionName,
                quantity: 1,
                item_odd: outcome.fixedOddsWeb,
                high_odds: event.additionalBonus ? "evet" : "hayır",
                item_bet: outcome.outcomeName
            });
        }
        else {
            dataLayerRemoveFromCart({
                item_name: event.eventName,
                item_id: event.eventId,
                price: 0.0,
                item_category: event.sportName,
                item_category2: event.competitionGroupName,
                item_category3: event.competitionName,
                quantity: 1,
                item_odd: outcome.fixedOddsWeb,
                high_odds: event.additionalBonus ? "evet" : "hayır",
                item_bet: outcome.outcomeName
            });
        }
        var _message = {
            $type: 'addToBetSlip',
            data: {
                "member_id": member.id,
                "market_id": market.marketId,
                "market_version": market.marketVersion,
                "market_name": market.marketName,
                "event_name": event.eventName,
                "outcome_display": outcome.outcomeName,
                "event_id": event.eventId,
                "outcome_no": outcome.outcomeNo,
                "fixed_odds_web": outcome.fixedOddsWeb,
                "user_action": outcome.isSelected === true ? "add" : "remove",
            },
        };
        if (AuthWSProvider && AuthWSProvider.readyState === 1) {
            AuthWSProvider.send(JSON.stringify({ type: _message }));
        }
    }
    catch (e) {
        console.log('web-socket service send Message ', e);
    }
    return outcome;
}
function checkCouponMBSValidity() {
    var maxMBS = Math.max.apply(Math, BetSlipList.map(function (o) {
        return o.market.minCombiCount;
    }));
    return maxMBS <= BetSlipList.length;
}
function checkSamePlayerValidity() {
    var systemLength = BetSlipOption.systemCombinations.length;
    var checkData = [];
    var samePlayerList = [];
    // system işaretlenmişse bu kontrol çalıştırılmalı.
    if (BetSlipList.length > 0 && systemLength > 1) {
        for (var _b = 0, BetSlipList_2 = BetSlipList; _b < BetSlipList_2.length; _b++) {
            var item = BetSlipList_2[_b];
            for (var _c = 0, _d = item.sportEvent.eventParticipant; _c < _d.length; _c++) {
                var participant = _d[_c];
                checkData.push({
                    participantId: Number(participant.participantId),
                    marketId: Number(item.marketId)
                });
            }
        }
    }
    var reducedData = checkData.reduce(function (p, c) {
        p[c.participantId] = (p[c.participantId] || 0) + 1;
        return p;
    }, {});
    var result = checkData.filter(function (obj) {
        return reducedData[obj.participantId] > 1;
    });
    samePlayerList.push.apply(samePlayerList, result.map(function (item) { return item.marketId; }));
    return !(samePlayerList.length > 0);
}
function checkSameLeagueValidity() {
    var systemLength = BetSlipOption.systemCombinations.length;
    var checkData = [];
    var sameLeagueList = [];
    // system işaretlenmişse bu kontrol çalıştırılmalı.
    if (BetSlipList.length > 0 && systemLength > 1) {
        // Uzun Vadeli bir karşılaşma seçilmiş ise ve system işaretlenmişse bu kontrol çalıştırılmalı.
        var isLongTermExists = BetSlipList.filter(function (item) { return Number(item.sportEvent.eventType) === 2; }).length > 0;
        if (isLongTermExists) {
            for (var _b = 0, BetSlipList_3 = BetSlipList; _b < BetSlipList_3.length; _b++) {
                var item = BetSlipList_3[_b];
                // fixme : participant kullanımıyor ama neden for içinde?? :S
                // @ts-ignore
                for (var _c = 0, _d = item.sportEvent.eventParticipant; _c < _d.length; _c++) {
                    var participant = _d[_c];
                    checkData.push({
                        competitionId: Number(item.sportEvent.competitionId),
                        marketId: Number(item.marketId)
                    });
                }
            }
        }
    }
    var reducedData = checkData.reduce(function (p, c) {
        p[c.competitionId] = (p[c.competitionId] || 0) + 1;
        return p;
    }, {});
    var result = checkData.filter(function (obj) {
        return reducedData[obj.competitionId] > 1;
    });
    sameLeagueList.push.apply(sameLeagueList, result.map(function (item) { return item.marketId; }));
    //console.log("sameLeagueList", sameLeagueList);
    return !(sameLeagueList.length > 0);
}
function checkLockedMarkets() {
    // fixme : bu kısımda kilitli marketler ya da kapanmış maçların kontrolleri yapılacak.
    var lockedOdds = BetSlipList.filter(function (e) {
        if (Number(e.outcome.fixedOddsWeb) > 1 && e.market.marketStatus !== 0 && e.market.marketStatus < 2) {
            return e;
        }
    });
    // console.log(lockedOdds)
    return true;
}
function checkBalanceControl(couponAmount) {
    var availableBalance = BetSlipOption.usePointBalance ? Number(member.debit_balance) + (member.point_balance / 100) : member.debit_balance;
    return Number(availableBalance) >= couponAmount;
}
// export async function getRatioCampaignInfo() {
//   let url = env.cdn_url + '/json/ratio_campaign.json?v='+String(new Date().getHours())+String(new Date().getMinutes());
//   const apiService = new ApiService();
//   return await apiService.start('get', url, null, false).then((response) => {
//     Object.assign(AdditionalRatioCampaign, response)
//     if(member && member.id && member.additional_ratio && member.additional_ratio > 0){
//       AdditionalRatioCampaign.currentRatio = Number(member.additional_ratio);
//     }
//     return response;
//   })
// }
export function validateCoupon(forSave) {
    if (forSave === void 0) { forSave = false; }
    var isValid = true;
    BetSlipOption.errorMessage = null;
    if (!BetSlipOption.betSlipQuantity) {
        BetSlipOption.betSlipQuantity = 1;
    }
    if (!BetSlipOption.betSlipMultiply) {
        BetSlipOption.betSlipMultiply = 30;
    }
    var couponColumn = BetSlipOption.systemCombinations.length;
    var couponTotalRatio = BetSlipOption.totalRatio;
    var betSlipMultiply = BetSlipOption.betSlipMultiply;
    var betSlipQuantity = BetSlipOption.betSlipQuantity;
    var couponAmount = couponColumn * betSlipMultiply * betSlipQuantity;
    var maxProfit = couponTotalRatio * betSlipMultiply * betSlipQuantity;
    if (!forSave && couponAmount < 500 && betSlipQuantity > 1) {
        // console.log("check multi coupon")
        isValid = false;
        BetSlipOption.errorMessage = "Merkezi Bahis Sistemi kuralları gereğince, 500 TL üzeri kuponlarda çoklu kupon oynanabilmektedir.";
        // console.log(isValid, BetSlipOption.errorMessage )
    }
    if (!forSave && isValid) {
        // console.log("checkLockedMarkets")
        isValid = checkLockedMarkets();
        if (!isValid) {
            BetSlipOption.errorMessage = 'Kuponunuzda kilitlenmiş ya da geçersiz market ve/veya oran bulunmaktadır.';
        }
        // console.log(isValid, BetSlipOption.errorMessage )
    }
    if (!forSave && isValid) {
        // console.log("checkBalanceControl")
        isValid = checkBalanceControl(couponAmount);
        // console.log("checkBalanceControl", checkBalanceControl(couponAmount) )
        if (!isValid) {
            BetSlipOption.errorMessage = 'Yeterli bakiyeniz bulunmamaktadır.';
        }
        // console.log(isValid, BetSlipOption.errorMessage )
        // console.log("couponAmount / betSlipQuantity", couponAmount , betSlipQuantity , isValid)
    }
    if ((couponAmount / betSlipQuantity) < 30 && isValid) {
        // console.log("min tutar")
        isValid = false;
        BetSlipOption.errorMessage = 'Kupon tutarı en az 30 TL olmak zorundadır.';
        // console.log(isValid, BetSlipOption.errorMessage )
        // console.log("couponAmount / betSlipQuantity", couponAmount , betSlipQuantity )
    }
    if ((couponAmount / betSlipQuantity) > betSlipMaxMultiply && isValid) {
        // console.log("max misli ")
        isValid = false;
        BetSlipOption.errorMessage = "Misli se\u00E7imi en fazla ".concat(betSlipMaxMultiply, " olabilir.");
        // console.log(isValid, BetSlipOption.errorMessage )
    }
    // if ((maxProfit / betSlipQuantity) > 2000000 && isValid) {
    //   // console.log("max 2.000.000 ")
    //   BetSlipOption.errorMessage = 'Bir kupondan kazanabileceğiniz maksimum tutar 1.500.000 TL olabilir.'
    //   // console.log(isValid, BetSlipOption.errorMessage )
    // }
    if (isValid) {
        // console.log("checkCouponMBSValidity")
        isValid = checkCouponMBSValidity();
        if (!isValid) {
            BetSlipOption.errorMessage = 'MBS kuralı nedeniyle kuponunuza daha fazla maç eklemelisiniz.';
        }
        // console.log(isValid, BetSlipOption.errorMessage )
    }
    if (isValid) {
        // console.log("checkSamePlayerValidity")
        isValid = checkSamePlayerValidity();
        if (!isValid) {
            BetSlipOption.errorMessage = "Kuponunuzdaki tahminler birbirleriyle kombine edilemez. Rakip";
        }
        // console.log(isValid, BetSlipOption.errorMessage )
    }
    if (isValid) {
        // console.log("checkSameLeagueValidity")
        isValid = checkSameLeagueValidity();
        if (!isValid) {
            BetSlipOption.errorMessage = "Kuponunuzdaki tahminler birbirleriyle kombine edilemez. Lig";
        }
        // console.log(isValid, BetSlipOption.errorMessage )
    }
    if (!isValid) {
        betSlipActionSubject.next({
            action: "calc",
            betSlip: null,
        });
        dataLayerBetError({
            error_detail: BetSlipOption.errorMessage,
            bet_value: BetSlipOption.systemCombinations.length * BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity,
            total_odds: BetSlipOption.totalRatio,
            max_revenue: BetSlipOption.totalRatio * BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity, // Maksimum kazanç tutarı
        });
    }
    return isValid;
}
export function playCoupon(forSave) {
    if (forSave === void 0) { forSave = false; }
    BetSlipOption.inProgress = true;
    betSlipActionSubject.next({
        action: "calc",
        betSlip: null,
    });
    try {
        var domElement = document.getElementById("betslip-items");
        // @ts-ignore
        html2canvas(domElement, {
            width: domElement.clientWidth,
            height: domElement.clientHeight,
            scale: 0.7,
        }).then(function (canvas) {
            var imgData = canvas.toDataURL("image/jpeg");
            var _message = {
                $type: 'orderCoupon',
                data: {
                    'member_id': member.id,
                    'screen': imgData
                },
            };
            if (AuthWSProvider && AuthWSProvider.readyState === 1) {
                AuthWSProvider.send(JSON.stringify({ type: _message }));
            }
        });
    }
    catch (e) {
        console.log('web-socket service send Message ', e);
    }
    try {
        // @ts-ignore
        fbq('trackCustom', 'KuponYap');
    }
    catch (e) {
    }
    var apiService = new ApiService();
    var isValid = validateCoupon(forSave);
    // console.log("isValid>>>", isValid)
    var couponDataBetslipRows = [];
    for (var _b = 0, BetSlipList_4 = BetSlipList; _b < BetSlipList_4.length; _b++) {
        var betslipItem = BetSlipList_4[_b];
        var homeId = '0';
        var awayId = '0';
        if (betslipItem.sportEvent.eventParticipant.length > 1) {
            homeId = betslipItem.sportEvent.eventParticipant[0].participantId.toString();
            awayId = betslipItem.sportEvent.eventParticipant[1].participantId.toString();
        }
        var item = {
            eventId: betslipItem.sportEvent.eventId.toString(),
            eventName: betslipItem.sportEvent.eventName.toString(),
            eventType: betslipItem.sportEvent.eventType.toString(),
            sportId: betslipItem.sportEvent.sportId.toString(),
            sportName: betslipItem.sportEvent.sportName.toString(),
            eventVersion: betslipItem.sportEvent.eventVersion.toString(),
            eventDate: betslipItem.sportEvent.eventDate.toString(),
            marketId: betslipItem.marketId.toString(),
            marketName: betslipItem.market.marketName.toString(),
            marketVersion: betslipItem.market.marketVersion.toString(),
            minCombiCount: betslipItem.market.minCombiCount.toString(),
            isLiveEvent: betslipItem.sportEvent.isLiveEvent.toString(),
            outcomeNo: betslipItem.outcomeNo.toString(),
            outcomeName: betslipItem.outcomeName.toString(),
            homeId: homeId,
            awayId: awayId,
            outcome: {
                fixedOddsWeb: betslipItem.outcome.fixedOddsWeb.toString(),
                outcomeNo: betslipItem.outcome.outcomeNo.toString(),
                oddsVersion: betslipItem.outcome.oddsVersion.toString()
            },
            isBanker: betslipItem.isBanker
        };
        couponDataBetslipRows.push(item);
    }
    var postableData = JSON.parse(JSON.stringify(BetSlipOption));
    delete postableData['systemCombinations'];
    var couponData = {
        pay_with: BetSlipOption.usePointBalance ? 'mixed' : 'debit',
        betslip_rows: JSON.stringify(couponDataBetslipRows),
        betslip_options: JSON.stringify(postableData),
        play_coupon: Storage.get('play_coupon'),
        save_coupon: forSave ? 'true' : null,
        device_os: isAndroid ? 'Android' : isIOS ? 'iOS' : isMacOs ? 'Macintosh' : isWindows ? 'Windows' : 'Linux',
        browser_type: 'YENI-WEB',
        device_type: isMobile ? 'Mobile' : 'Desktop',
        token: Storage.get('token')
    };
    // console.log(isSafari ? 1 : isChrome ? 2 : isFirefox ? 4 : 0)
    // Sosyoley kupon oynama bilgisi
    // if (this.betslipService.couponId !== '') {
    //   couponData['play_coupon'] = this.betslipService.couponId;
    // }
    //console.log(couponData);
    if (isValid) {
        savePurchaseData(BetSlipList, BetSlipOption);
        (function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, apiService
                                .start('post', env.core_api + '/order/api/order-iddaa/', couponData, true, 60000)
                                .then(function (response) {
                                if (response.status === true) {
                                    if (forSave) {
                                        modalServiceSubject.next({
                                            title: null,
                                            content: '<p class="text-center mt-3 mb-3"><img src="' + Check + '" width="48" /><br />Kuponunuz kaydedilmiştir.</p>',
                                            confirm: {
                                                cancel: { label: 'Tamam' }
                                            },
                                            timeout: 6000
                                        });
                                        dataLayerSaveBet({
                                            bet_value: BetSlipOption.systemCombinations.length * BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity,
                                            total_odds: BetSlipOption.totalRatio,
                                            max_revenue: BetSlipOption.totalRatio * BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity,
                                            bet_system: BetSlipOption.systemCombinations.length > 1 ? 'yes' : 'no',
                                            ticket_count: BetSlipOption.betSlipQuantity,
                                            multiplier: BetSlipOption.betSlipMultiply
                                        });
                                        BetSlipOption.inProgress = false;
                                        betSlipActionSubject.next({
                                            action: "calc",
                                            betSlip: null,
                                        });
                                    }
                                    else {
                                        refreshMemberInfo();
                                        var isLiveCoupon = false;
                                        try {
                                            isLiveCoupon = response.data[0].live_coupon;
                                        }
                                        catch (e) {
                                            isLiveCoupon = false;
                                        }
                                        if (isLiveCoupon) {
                                            liveCouponResults(response);
                                        }
                                        else {
                                            preCouponResults(response);
                                        }
                                    }
                                }
                                else {
                                    if (response && response.data && response.data.hasOwnProperty('extra_errors')) {
                                        var _loop_3 = function (el) {
                                            var errorMessage = response.data.extra_errors.find(function (err) { return Number(err.eventId) === Number(el.marketId); });
                                            if (errorMessage) {
                                                el.errorString = errorMessage.error;
                                            }
                                        };
                                        for (var _b = 0, BetSlipList_5 = BetSlipList; _b < BetSlipList_5.length; _b++) {
                                            var el = BetSlipList_5[_b];
                                            _loop_3(el);
                                        }
                                        BetSlipOption.errorMessage = response.message;
                                    }
                                    else {
                                        BetSlipOption.errorMessage = response.message;
                                    }
                                    dataLayerBetError({
                                        error_detail: response.message,
                                        bet_value: BetSlipOption.systemCombinations.length * BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity,
                                        total_odds: BetSlipOption.totalRatio,
                                        max_revenue: BetSlipOption.totalRatio * BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity, // Maksimum kazanç tutarı
                                    });
                                    BetSlipOption.inProgress = false;
                                    betSlipActionSubject.next({
                                        action: "calc",
                                        betSlip: null,
                                    });
                                }
                            }).catch(function (error) {
                                BetSlipOption.inProgress = false;
                                betSlipActionSubject.next({
                                    action: "calc",
                                    betSlip: null,
                                });
                                dataLayerBetError({
                                    error_detail: "Bağlantı Hatası",
                                    bet_value: BetSlipOption.systemCombinations.length * BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity,
                                    total_odds: BetSlipOption.totalRatio,
                                    max_revenue: BetSlipOption.totalRatio * BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity, // Maksimum kazanç tutarı
                                });
                                modalServiceSubject.next({
                                    show: true,
                                    title: "Bağlantı Hatası",
                                    content: '<p class="text-center mt-2"><img class="mb-2" src="' + AlertTriangle + '" width="50" /><br />' + error.message + ' <br /> Lütfen kuponlarım sayfasından kuponunuzun durumunu kontrol edin.</p>',
                                    confirm: {
                                        cancel: { label: 'Tamam' }
                                    },
                                    timeout: 0
                                });
                            })];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        })();
    }
    else {
        BetSlipOption.inProgress = false;
        betSlipActionSubject.next({
            action: "calc",
            betSlip: null,
        });
    }
}
function liveCouponResults(response) {
    clearInterval(checkTimer);
    clearTimeout(firstDelay);
    var delay = 9500;
    if (response && response.data && response.data.length > 0 && response.data[0].delay) {
        delay = (Number(response.data[0].delay) * 1000) - 1500;
        BetSlipOption.delay = Number(response.data[0].delay);
        if (delay < 0) {
            delay = 50;
        }
    }
    if (response != null) {
        delay += 20000;
    }
    var coupon_ids = [];
    if (response) {
        coupon_ids = response.data.map(function (el) { return el.id; });
    }
    else {
        coupon_ids = BetSlipOption.coupon_ids;
        BetSlipOption.inProgress = true;
    }
    if (response && response.data && response.data.length > 0 && response.data[0].process_id) {
        BetSlipOption.process_id = response.data[0].process_id.toString();
        Storage.set('process_id', response.data[0].process_id.toString());
        Storage.set('coupon_ids', JSON.stringify(coupon_ids));
    }
    BetSlipOption.bettingInfoStatus = 1;
    BetSlipOption.bettingInfoMessage = 'Kuponunuz oynanmak üzere Merkezi Bahis Sistemi\'ne gönderilmiş fakat henüz onay alınmamıştır';
    // BetSlipOption.inProgress = false;
    betSlipActionSubject.next({
        action: "calc",
        betSlip: null,
    });
    firstDelay = setTimeout(function () {
        if (BetSlipOption.bettingInfoStatus === 1) {
            var tries_1 = 10;
            checkTimer = setInterval(function () {
                if (tries_1 > 0) {
                    tries_1 -= 1;
                    checkLiveCoupons(coupon_ids);
                }
                else {
                    BetSlipOption.bettingInfoStatus = 2;
                    BetSlipOption.bettingInfoMessage = 'Önemli Durum! Lütfen kuponlarım sayfasından kuponunuzu takip edin ya da müşteri hizmetleri biriminden kuponunuzun durumunda bilgi talep edin. <br /><br /> Kuponunuzun oynanamaması durumunda, para iadeniz sistem tarafından otomatik gerçekleştirilecektir.';
                    betSlipActionSubject.next({
                        action: "calc",
                        betSlip: null,
                    });
                    setTimeout(function () {
                        BetSlipOption.bettingInfoStatus = null;
                        BetSlipOption.bettingInfoMessage = null;
                        BetSlipOption.inProgress = false;
                        betSlipActionSubject.next({
                            action: "calc",
                            betSlip: null,
                        });
                    }, 5000);
                    refreshMemberInfo();
                    clearInterval(checkTimer);
                }
            }, 2000);
        }
    }, delay);
}
function checkRestoreCoupon(coupon_ids) {
    var apiService = new ApiService();
    var updateData = {
        coupon_id_list: coupon_ids.join(','),
        token: Storage.get('token')
    };
    (function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, apiService
                            .start('post', env.core_api + '/order/api/get_coupon_status/', updateData, true)
                            .then(function (response) {
                            //console.log(response)
                            if (response.status) {
                                var updatedCoupons = response.data;
                                if (updatedCoupons.length > 0) {
                                    for (var _b = 0, updatedCoupons_1 = updatedCoupons; _b < updatedCoupons_1.length; _b++) {
                                        var item = updatedCoupons_1[_b];
                                        if (Number(item.pending_quantity) === 0 && Number(item.status) === 1) {
                                            dataLayerPurchase(item.approve_code);
                                            BetSlipOption.bettingInfoStatus = 3;
                                            BetSlipOption.bettingInfoMessage = '<b>' + item.coupon_quantity + '</b> adet ' + '<br />kuponunuz başarıyla oynanmıştır.';
                                            clearInterval(checkTimer);
                                            clearBetSlip(true);
                                            betSlipActionSubject.next({
                                                action: "calc",
                                                betSlip: null,
                                            });
                                            refreshMemberInfo();
                                            try {
                                                // @ts-ignore
                                                fbq('track', 'Purchase', {
                                                    value: BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity * BetSlipOption.systemCombinations.length,
                                                    currency: 'TRY'
                                                });
                                            }
                                            catch (e) {
                                            }
                                            // try {
                                            //     // @ts-ignore
                                            //     window.gtag('event', 'conversion', {
                                            //         'send_to': 'AW-646617036/f0G3CI2pvasYEMyvqrQC',
                                            //         'transaction_id': member.id + '-' + new Date().getTime(),
                                            //         'value': BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity * BetSlipOption.systemCombinations.length,
                                            //         'currency': 'TRY',
                                            //     });
                                            // } catch (e) {
                                            // }
                                            clearTimer = setTimeout(function () {
                                                clearBetSlip();
                                            }, 2000);
                                        }
                                        else if (Number(item.status) > 1) {
                                            clearInterval(checkTimer);
                                            BetSlipOption.bettingInfoStatus = 2;
                                            BetSlipOption.bettingInfoMessage = 'Kuponunuz oynanamamıştır.';
                                            betSlipActionSubject.next({
                                                action: "calc",
                                                betSlip: null,
                                            });
                                            setTimeout(function () {
                                                BetSlipOption.bettingInfoStatus = null;
                                                BetSlipOption.bettingInfoMessage = null;
                                                BetSlipOption.inProgress = false;
                                                betSlipActionSubject.next({
                                                    action: "calc",
                                                    betSlip: null,
                                                });
                                            }, 3000);
                                            refreshMemberInfo();
                                        }
                                    }
                                }
                                else {
                                    clearInterval(checkTimer);
                                    BetSlipOption.bettingInfoStatus = 2;
                                    BetSlipOption.bettingInfoMessage = 'Kuponunuz oynanamamıştır.';
                                    betSlipActionSubject.next({
                                        action: "calc",
                                        betSlip: null,
                                    });
                                    setTimeout(function () {
                                        BetSlipOption.bettingInfoStatus = null;
                                        BetSlipOption.bettingInfoMessage = null;
                                        BetSlipOption.inProgress = false;
                                        betSlipActionSubject.next({
                                            action: "calc",
                                            betSlip: null,
                                        });
                                    }, 3000);
                                    refreshMemberInfo();
                                }
                            }
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    })();
}
function checkLiveCoupons(coupon_ids) {
    var apiService = new ApiService();
    var updateData = {
        coupon_id_list: coupon_ids.join(','),
        token: Storage.get('token')
    };
    (function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, apiService
                            .start('post', env.core_api + '/order/api/get_coupon_status/', updateData, true)
                            .then(function (response) {
                            //console.log(response)
                            if (response.status) {
                                var updatedCoupons = response.data;
                                if (updatedCoupons.length > 0) {
                                    for (var _b = 0, updatedCoupons_2 = updatedCoupons; _b < updatedCoupons_2.length; _b++) {
                                        var item = updatedCoupons_2[_b];
                                        if (Number(item.pending_quantity) === 0 && Number(item.status) === 1) {
                                            BetSlipOption.bettingInfoStatus = 3;
                                            dataLayerPurchase(item.approve_code);
                                            BetSlipOption.bettingInfoMessage = '<b>' + item.coupon_quantity + '</b> adet ' + '<br />kuponunuz başarıyla oynanmıştır.';
                                            clearInterval(checkTimer);
                                            clearBetSlip(true);
                                            betSlipActionSubject.next({
                                                action: "calc",
                                                betSlip: null,
                                            });
                                            refreshMemberInfo();
                                            try {
                                                // @ts-ignore
                                                fbq('track', 'Purchase', {
                                                    value: BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity * BetSlipOption.systemCombinations.length,
                                                    currency: 'TRY'
                                                });
                                            }
                                            catch (e) {
                                            }
                                            // try {
                                            //     // @ts-ignore
                                            //     window.gtag('event', 'conversion', {
                                            //         'send_to': 'AW-646617036/f0G3CI2pvasYEMyvqrQC',
                                            //         'transaction_id': member.id + '-' + new Date().getTime(),
                                            //         'value': BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity * BetSlipOption.systemCombinations.length,
                                            //         'currency': 'TRY',
                                            //     });
                                            // } catch (e) {
                                            // }
                                            clearTimer = setTimeout(function () {
                                                clearBetSlip();
                                            }, 2000);
                                        }
                                        else if (Number(item.status) > 1) {
                                            clearInterval(checkTimer);
                                            BetSlipOption.bettingInfoStatus = 2;
                                            BetSlipOption.bettingInfoMessage = 'Kuponunuz oynanamamıştır.';
                                            BetSlipOption.process_id = '';
                                            BetSlipOption.coupon_ids = [];
                                            Storage.del('play_coupon');
                                            Storage.del('process_id');
                                            Storage.del('coupon_ids');
                                            betSlipActionSubject.next({
                                                action: "calc",
                                                betSlip: null,
                                            });
                                            setTimeout(function () {
                                                BetSlipOption.bettingInfoStatus = null;
                                                BetSlipOption.bettingInfoMessage = null;
                                                BetSlipOption.inProgress = false;
                                                betSlipActionSubject.next({
                                                    action: "calc",
                                                    betSlip: null,
                                                });
                                            }, 3000);
                                            refreshMemberInfo();
                                        }
                                    }
                                }
                                else {
                                    clearInterval(checkTimer);
                                    BetSlipOption.bettingInfoStatus = 2;
                                    BetSlipOption.bettingInfoMessage = 'Kuponunuz oynanamamıştır.';
                                    BetSlipOption.process_id = '';
                                    BetSlipOption.coupon_ids = [];
                                    Storage.del('play_coupon');
                                    Storage.del('process_id');
                                    Storage.del('coupon_ids');
                                    betSlipActionSubject.next({
                                        action: "calc",
                                        betSlip: null,
                                    });
                                    setTimeout(function () {
                                        BetSlipOption.bettingInfoStatus = null;
                                        BetSlipOption.bettingInfoMessage = null;
                                        BetSlipOption.inProgress = false;
                                        betSlipActionSubject.next({
                                            action: "calc",
                                            betSlip: null,
                                        });
                                    }, 3000);
                                    refreshMemberInfo();
                                }
                            }
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    })();
}
function preCouponResults(response) {
    var playedCoupons = response.data;
    // console.log(response)
    // Paylaş butonu kontrolü
    if (playedCoupons.length > 0 && !member.share_option) {
        // canShare = true;
    }
    if (playedCoupons.length > 0) {
        dataLayerPurchase(response.data[0].approve_code);
        BetSlipOption.bettingInfoStatus = 3;
        if (response.data[0].quantity === 1) {
            BetSlipOption.bettingInfoMessage = '<b>' + response.data[0].approve_code + '</b> ' + ' <br />kuponunuz başarıyla oynanmıştır.';
        }
        else {
            BetSlipOption.bettingInfoMessage = '<b>' + response.data[0].quantity + '</b> adet ' + ' <br />kuponunuz başarıyla oynanmıştır.';
        }
        clearBetSlip(true);
        BetSlipOption.inProgress = false;
        betSlipActionSubject.next({
            action: "calc",
            betSlip: null,
        });
        try {
            // @ts-ignore
            fbq('track', 'Purchase', {
                value: BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity * BetSlipOption.systemCombinations.length,
                currency: 'TRY'
            });
        }
        catch (e) {
        }
        // try {
        //     // @ts-ignore
        //     window.gtag('event', 'conversion', {
        //         'send_to': 'AW-646617036/f0G3CI2pvasYEMyvqrQC',
        //         'transaction_id': member.id + '-' + new Date().getTime(),
        //         'value': BetSlipOption.betSlipMultiply * BetSlipOption.betSlipQuantity * BetSlipOption.systemCombinations.length,
        //         'currency': 'TRY',
        //     });
        // } catch (e) {
        // }
        clearTimer = setTimeout(function () {
            clearBetSlip();
        }, 6000);
    }
    else {
        BetSlipOption.bettingInfoStatus = 2;
        BetSlipOption.bettingInfoMessage = '<span>' + response.message.join('<br>') + '</span>';
        BetSlipOption.inProgress = false;
        betSlipActionSubject.next({
            action: "calc",
            betSlip: null,
        });
    }
    refreshMemberInfo();
}
export default function betslipService() {
}
export function refreshBetSlipRows() {
    var _loop_4 = function (item) {
        addToBetSlip(item.sportEvent, item.market, item.outcome, 'remove');
        var event_2 = sportsbookList.find(function (el) { return Number(el.eventId) === Number(item.sportEvent.eventId); });
        if (event_2) {
            var market = event_2.markets.find(function (el) { return el.marketId === item.marketId; });
            if (market) {
                var outcome = market.marketOutcome.find(function (el) { return el.outcomeNo === item.outcomeNo; });
                if (outcome) {
                    addToBetSlip(event_2, market, outcome);
                }
            }
        }
    };
    for (var _b = 0, BetSlipList_6 = BetSlipList; _b < BetSlipList_6.length; _b++) {
        var item = BetSlipList_6[_b];
        _loop_4(item);
    }
}
