var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Collapsible from "react-collapsible";
import { GameOperationsApi } from "../../subpages/gameOperations/game.operations.api";
import { Storage } from "../../../../lib/localstorege.service";
var Title = function () {
    return (_jsxs("div", __assign({ className: "support-title" }, { children: [_jsx("div", { children: "Sat\u0131n Alma Tarihi \u00A0" }, void 0), _jsx("div", { children: "\u00C7ekili\u015F Tarihi\u00A0" }, void 0), _jsx("div", { children: "Kupon Bedeli\u00A0" }, void 0), _jsx("div", { children: "Durum\u00A0" }, void 0)] }), void 0));
};
var TableWidget = function (props) {
    var _a = useState([]), dizi = _a[0], setDizi = _a[1];
    var token = Storage.get('token');
    function detail(coupon_id) {
        var data = {
            token: token,
            "coupon-id": coupon_id,
        };
        GameOperationsApi.sayisalLotoDetail(data).then(function (result) {
            if (result.status) {
                var data1 = [];
                var response = result.data;
                response.id = coupon_id;
                data1 = dizi;
                setDizi(data1.concat(response));
            }
            else {
                alert(result.message);
            }
        });
    }
    return (_jsxs("div", { children: [_jsx(Title, {}, void 0), props.iddiaData
                .map(function (data, key) {
                return (_jsx(Collapsible, __assign({ trigger: _jsxs("div", __assign({ onClick: function () { return detail(data.id); } }, { children: [_jsx("div", { children: data.create_date }, void 0), _jsx("div", { children: data.draw__draw_date }, void 0), _jsx("div", { children: data.amount }, void 0), _jsx("div", { children: data.status_display }, void 0)] }), "acountSayisalLoto" + key) }, { children: _jsxs("div", { children: [dizi.map(function (x, key) {
                                return ((x.id === data.id) ? (_jsxs("div", { children: [_jsx("div", { children: "\u00C7ekili\u015F Sonucu" }, void 0), _jsx("div", { children: x.winning_numbers.map(function (y, key) {
                                                return (_jsx("div", { children: _jsx("div", { children: y }, void 0) }, "accountTransactionsIddiaCartData" + key));
                                            }) }, void 0), _jsxs("div", { children: ["\u00C7ekili\u015F Tarihi:", data.draw__draw_date] }, void 0), _jsxs("div", { children: ["Kupon ID:", x.id] }, void 0), _jsx("div", { children: "B" }, void 0)] }, "accountTransactionsIddiamap" + key)) : null);
                            }), "\u00A0"] }, dizi) }), "acountSayisalLoto".concat(key)));
            })] }, void 0));
};
export default TableWidget;
