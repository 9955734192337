var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CheckCircle from "../../../../assets/feather/check-circle.svg";
import PlayCircle from "../../../../assets/feather/play-circle.svg";
import Info from "../../../../assets/feather/info.svg";
export default function ContractedBanks(props) {
    function selected(id) {
        var element = document.getElementById(id);
        if (element.style.display == "none") {
            element.style.display = "inline";
            element.scrollIntoView({ behavior: "smooth" });
        }
        else {
            element.style.display = "none";
        }
    }
    return (_jsxs("div", __assign({ className: "contractedBanks" }, { children: ["Bankalar taraf\u0131ndan al\u0131nmakta olan komisyon bilgilerini, tercih etti\u011Finiz banka logosuna t\u0131klayarak \u00F6\u011Frenebilirsiniz.", _jsx("br", {}, void 0), _jsx("br", {}, void 0), _jsx("div", { children: _jsxs("table", __assign({ className: "table" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "BANKA" }), void 0), _jsx("th", __assign({ className: "text-center d-none d-md-table-cell" }, { children: "\u0130NTERNET BANKACILI\u011EI" }), void 0), _jsx("th", __assign({ className: "text-center d-none d-md-table-cell" }, { children: "MOB\u0130L BANKACILIK" }), void 0), _jsx("th", __assign({ className: "text-center d-none d-md-table-cell" }, { children: "ATM" }), void 0), _jsx("th", __assign({ className: "text-center d-none d-md-table-cell" }, { children: "ATM KARTSIZ \u0130\u015ELEM" }), void 0), _jsx("th", __assign({ className: "text-center d-none d-md-table-cell" }, { children: "TELEFON BANKACILI\u011EI" }), void 0), _jsx("th", __assign({ className: "text-center d-table-cell d-md-none" }, { children: "V\u0130DEOLU ANLATIM" }), void 0)] }, void 0) }, void 0), _jsx("tbody", { children: props.depositMoneyData.map(function (moneyDepositOption, key) {
                                return (_jsxs("tr", { children: [_jsx("th", __assign({ scope: "row" }, { children: _jsx("img", { onClick: function () { return selected(moneyDepositOption.id); }, className: "bank_info_logo cursor-pointer", src: "https://eski.oley.com/".concat(moneyDepositOption.logo), alt: "Akbank" }, void 0) }), void 0), _jsxs("td", __assign({ className: " d-none d-md-table-cell text-center " }, { children: ["   ", moneyDepositOption.is_internet_banking ? (_jsx("img", { src: CheckCircle, width: 25 }, void 0)) : null] }), void 0), _jsxs("td", __assign({ className: "d-none d-md-table-cell text-center" }, { children: ["   ", moneyDepositOption.is_mobile_banking ? (_jsx("img", { src: CheckCircle, width: 25 }, void 0)) : null] }), void 0), _jsxs("td", __assign({ className: "d-none d-md-table-cell text-center" }, { children: ["   ", moneyDepositOption.is_atm_banking ? (_jsx("img", { src: CheckCircle, width: 25 }, void 0)) : null] }), void 0), _jsxs("td", __assign({ className: "d-none d-md-table-cell text-center" }, { children: ["   ", moneyDepositOption.is_without_card ? (_jsx("img", { src: CheckCircle, width: 25 }, void 0)) : null] }), void 0), _jsx("td", __assign({ className: "d-none d-md-table-cell text-center" }, { children: moneyDepositOption.phone }), void 0), _jsx("td", __assign({ className: "d-table-cell d-md-none text-center", onClick: function () { return selected(moneyDepositOption.id); } }, { children: moneyDepositOption.video_url ?
                                                _jsx("img", { src: PlayCircle, width: 25, alt: "" }, void 0) : _jsx("img", { src: Info, width: 25, alt: "" }, void 0) }), void 0)] }, "contractedBanks".concat(key)));
                            }) }, void 0)] }), void 0) }, void 0)] }), void 0));
}
