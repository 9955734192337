var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
var KatarMenu = function () {
    var openPage = window.location.href;
    return (_jsx("div", __assign({ className: "card" }, { children: _jsx("div", __assign({ className: "card-body", style: { paddingBottom: (innerWidth < 968 ? '0px' : '1.2rem') } }, { children: _jsxs("ul", __assign({ className: "menu" }, { children: [_jsx("li", __assign({ className: openPage == ("katar-2022") ? 'active' : '' }, { children: _jsx(Link, __assign({ to: "/katar-2022" }, { children: "Katar 2022" }), void 0) }), void 0), _jsx("li", __assign({ className: openPage.includes("fikstur-sonuclar") ? 'active' : '' }, { children: _jsx(Link, __assign({ to: "/katar-2022/fikstur-sonuclar" }, { children: "Fikst\u00FCr & Sonu\u00E7lar" }), void 0) }), void 0), _jsx("li", __assign({ className: openPage.includes("puan-durumu") ? 'active' : '' }, { children: _jsx(Link, __assign({ to: "/katar-2022/puan-durumu" }, { children: "Puan Durumu" }), void 0) }), void 0), _jsx("li", __assign({ className: openPage.includes("kadrolar") ? 'active' : '' }, { children: _jsx(Link, __assign({ to: "/katar-2022/kadrolar" }, { children: "Kadrolar" }), void 0) }), void 0), _jsx("li", __assign({ className: openPage.includes("iddaa-oranlari") ? 'active' : '' }, { children: _jsx(Link, __assign({ to: "/iddaa/futbol/tumu/1382,1383,1384,1385,1386,1387,1388,1389" }, { children: "Iddaa Oranlar\u0131" }), void 0) }), void 0), _jsx("li", __assign({ className: openPage.includes("tarihce") ? 'active' : '' }, { children: _jsx(Link, __assign({ to: "/katar-2022/tarihce" }, { children: "Tarih\u00E7e" }), void 0) }), void 0), _jsx("li", __assign({ className: openPage.includes("tahmin") ? 'active' : '' }, { children: _jsx(Link, __assign({ to: "/katar-2022/tahmin-yarismasi" }, { children: "Tahmin Yar\u0131\u015Fmas\u0131" }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default KatarMenu;
