import { ApiService } from '../lib/http.service';
import { env } from "../definitions/global.vars";
export var Api = new ApiService();
export var LoginApi = (function () {
    return {
        login: function (request) {
            var data = {
                username: request.username,
                password: request.password
            };
            if (request.option && request.option.is_checked) {
                data['is_checked'] = request.option.is_checked;
            }
            if (request.option && request.option.re_activate) {
                data['re_activate'] = request.option.re_activate;
            }
            if (request.option && request.option.mobile_validation_code) {
                data['mobile_validation_code'] = request.option.mobile_validation_code;
            }
            if (request.option && request.option.approved_agreement) {
                data['approved_agreement'] = request.option.approved_agreement;
            }
            return Api.start('post', env.auth_api + '/member/api/login/', data, true, 10000);
        },
        checkAouth: function (request) {
            return Api.start('post', env.auth_api + '/member/api/check-auth/', request, true);
        },
        forgetPassword: function (request) {
            return Api.start('post', env.misc_api + '/member/api/forget-password/', request, true);
        },
        passwordReminder: function (request) {
            return Api.start('post', env.misc_api + '/member/api/password-reminder/', request, true);
        },
    };
})();
