var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var IddiaOranlari = function () {
    return (_jsxs("div", __assign({ className: 'card' }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "\u0130ddia Oranlar\u0131" }, void 0) }), void 0), _jsx("div", { className: "card-body" }, void 0)] }), void 0));
};
export default IddiaOranlari;
