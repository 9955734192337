var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import { AccountTransactionApi } from "../../subpages/accountTransactions/account.transactions.api";
import { Storage } from "../../../../lib/localstorege.service";
import { customStyles } from "../../../../definitions/constants/modal.constant";
import { modalServiceSubject } from '../../../../components/modal/modal';
function AvatarUpdate() {
    var _a = React.useState(false), modalIsOpenAvatar = _a[0], setIsOpenAvatar = _a[1];
    var token = Storage.get('token');
    function closeModal() {
        setIsOpenAvatar(false);
    }
    function openModalAvatar() {
        setIsOpenAvatar(true);
    }
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    var onSubmit = function (data) {
        updateEmailApi(data);
    };
    var updateEmailApi = function (data) {
        data.token = token;
        data.file = data.file[0];
        AccountTransactionApi.updateAvatar(data).then(function (result) {
            if (result.status) {
                setIsOpenAvatar(false);
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "table row mt-3" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("label", { children: "Avatar" }, void 0), _jsx("div", __assign({ className: "form-control" }, { children: _jsx("a", __assign({ href: "#", className: "btn btn-small btn-primary float-right", onClick: function () { return openModalAvatar(); } }, { children: "De\u011Fi\u015Ftir" }), void 0) }), void 0)] }), void 0) }), void 0), _jsx(Modal, __assign({ isOpen: modalIsOpenAvatar, onRequestClose: closeModal, style: customStyles, contentLabel: "" }, { children: _jsxs("div", __assign({ className: "page card " }, { children: [_jsx("div", __assign({ className: "card-title d-flex justify-content-center" }, { children: _jsx("h3", { children: "Avatar Yenile" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body d-flex justify-content-center" }, { children: _jsx("div", __assign({ className: "table row " }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", { children: [_jsx("input", __assign({ className: "form-control", type: "file" }, register('file', { required: true }), { accept: "image/png, image/jpeg" }), void 0), errors.file && errors.file.type === "required" &&
                                                        _jsx("span", __assign({ className: "text-validation" }, { children: "L\u00FCtfen dosya se\u00E7iniz." }), void 0)] }, void 0), _jsx("div", __assign({ className: "d-flex justify-content-center" }, { children: _jsx("input", { className: "btn btn-primary btn-large mt-3", type: "submit" }, void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0)] }, void 0));
}
export default AvatarUpdate;
