var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Storage } from "../../../../lib/localstorege.service";
import { Api } from "./game.operations.api";
import { dateTimeFormat, initialLeftMenu, sportsMenu, } from "../../../../lib/misc.functions";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { useNavigate } from "react-router-dom";
import Clock from "../../../../assets/feather/clock.svg";
import CheckCircle from "../../../../assets/feather/check-circle.svg";
import MinusCircle from "../../../../assets/feather/minus-circle.svg";
import { env } from "../../../../definitions/global.vars";
import Modal from 'react-modal';
import ExitIcon from "../../../../assets/feather/x-circle.svg";
import _ from "lodash";
import { isMobile } from "react-device-detect";
var paramStates = {
    "devam-eden-cekilisler": 0,
    "biten-cekilisler": 1,
};
var states = {
    0: 'Onaysız',
    1: 'Devam eden',
    2: 'Kazanan',
    3: 'Kaybeden',
    5: 'Hatalı',
};
var couponStatus = {
    0: _jsx("img", { src: Clock, className: "invert-color", width: 16 }, void 0),
    1: _jsx("img", { src: Clock, className: "invert-color", width: 16 }, void 0),
    2: _jsx("img", { src: CheckCircle, width: 16 }, void 0),
    3: _jsx("img", { src: MinusCircle, width: 16 }, void 0),
    5: _jsx("img", { src: ExitIcon, width: 16 }, void 0),
};
var refetchTimer;
export function Lottery(prop) {
    var navigate = useNavigate();
    var _a = useState([]), drawList = _a[0], setDrawList = _a[1];
    var _b = useState(null), shownDraw = _b[0], setShownDraw = _b[1];
    // @ts-ignore
    var ticketStatus = paramStates[prop.params.couponId];
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        fetchDraws();
    }, [ticketStatus]);
    var fetchDraws = function () {
        // @ts-ignore
        document.getElementById('loadingAnimation').style.display = 'block';
        var postParams = {
            token: Storage.get('token'),
            resulted: ticketStatus,
        };
        Api.start('post', "".concat(env.list_api, "/order/api/esya-piyangosu/kuponlarim/"), postParams, true).then(function (res) {
            // @ts-ignore
            document.getElementById('loadingAnimation').style.display = 'none';
            if (res.status === true) {
                var pending_1 = res.data.filter(function (el) { return el.pending_count > 0; });
                if (pending_1.length > 0) {
                    refetchTimer = setTimeout(function () {
                        fetchDraws();
                    }, 5000);
                }
                else {
                    clearTimeout(refetchTimer);
                }
                setDrawList(res.data);
            }
            else {
                modalServiceSubject.next({
                    title: 'Beklenmedik bir sorun oluştu',
                    content: res.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 3000,
                });
            }
        });
    };
    var showDrawTickets = function (draw) {
        var postParams = {
            token: Storage.get('token'),
            type: 'get_draw',
            draw_id: draw.draw_id
        };
        Api.start('post', "".concat(env.list_api, "/order/api/esya-piyangosu/kuponlarim/"), postParams, true).then(function (res) {
            console.log(res);
            if (res.status === true) {
                draw['tickets'] = res.data;
                setShownDraw(draw);
            }
            else {
                setShownDraw(null);
                modalServiceSubject.next({
                    title: 'Beklenmedik bir sorun oluştu',
                    content: res.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 3000,
                });
            }
        });
    };
    var closeModal = function () {
        setShownDraw(null);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "widget card" }, { children: [_jsxs("div", __assign({ className: "header" }, { children: [_jsx("h1", { children: "E\u015Fya Piyangosu Biletlerim" }, void 0), _jsxs("ul", __assign({ className: "tabs clearfix" }, { children: [_jsx("li", __assign({ className: "btn btn-outline-warning tab-border   " + (ticketStatus === 0 ? ' active' : ''), onClick: function () { return navigate('/hesabim/esya-piyangosu/devam-eden-cekilisler'); } }, { children: "Devam Eden \u00C7ekili\u015Fler" }), void 0), _jsx("li", __assign({ className: "btn btn-outline-warning tab-border " + (ticketStatus === 1 ? ' active' : ''), onClick: function () { return navigate('/hesabim/esya-piyangosu/biten-cekilisler'); } }, { children: "Biten \u00C7ekili\u015Fler" }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ id: "InfiniteScroll", className: "body p-2 p-sm-0" }, { children: drawList && drawList.length > 0 ? _jsx(_Fragment, { children: _jsxs("table", __assign({ className: "table" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-left" }, { children: "\u00C7ekili\u015F" }), void 0), _jsx("th", { children: "\u00C7ekili\u015F Tarihi" }, void 0), ticketStatus === 0 ? _jsx(_Fragment, { children: _jsx("th", { children: "Ald\u0131\u011F\u0131m Bilet" }, void 0) }, void 0) : _jsx(_Fragment, { children: isMobile ?
                                                        _jsx("th", { children: "Kazanan / Kaybeden" }, void 0) :
                                                        _jsxs(_Fragment, { children: [_jsx("th", { children: "Kay\u0131p Bilet" }, void 0), _jsx("th", { children: "Kazanan Bilet" }, void 0)] }, void 0) }, void 0), _jsx("th", { className: "hidden-sm" }, void 0)] }, void 0) }, void 0), _jsx("tbody", { children: drawList.map(function (order, key) {
                                            return (_jsxs("tr", __assign({ onClick: function () {
                                                    showDrawTickets(order);
                                                } }, { children: [_jsxs("td", __assign({ className: "text-left" }, { children: [_jsx("span", __assign({ className: "d-block fw-bold" }, { children: order.draw ? order.draw.name : null }), void 0), order.draw && order.draw.prizes && order.draw.prizes.length > 0 ? order.draw.prizes.map(function (prize, pkey) {
                                                                return (_jsxs("span", __assign({ className: "d-block color-medium" }, { children: [prize.prizeCount, " adet ", prize.name] }), "prizeList_".concat(key, "_").concat(pkey)));
                                                            }) : _jsx("span", {}, "prizeList_".concat(key, "_"))] }), void 0), _jsx("td", __assign({ className: "vertical-top" }, { children: order.draw ? dateTimeFormat(order.draw.drawDate) : null }), void 0), ticketStatus === 0 ? _jsx(_Fragment, { children: _jsxs("td", __assign({ className: "vertical-top" }, { children: [order.played_count, " adet"] }), void 0) }, void 0) : _jsx(_Fragment, { children: isMobile ?
                                                            _jsxs("td", __assign({ className: "vertical-top" }, { children: [order.won_count, " adet / ", order.lost_count, " adet"] }), void 0) :
                                                            _jsxs(_Fragment, { children: [_jsxs("td", __assign({ className: "vertical-top" }, { children: [order.lost_count, " adet"] }), void 0), _jsxs("td", __assign({ className: "vertical-top" }, { children: [order.won_count, " adet"] }), void 0)] }, void 0) }, void 0), _jsx("td", __assign({ className: "vertical-top hidden-sm text-right" }, { children: _jsx("button", __assign({ onClick: function () {
                                                                showDrawTickets(order);
                                                            }, className: "btn btn-small btn-primary" }, { children: "Biletlerimi G\u00F6ster" }), void 0) }), void 0)] }), "drawList_".concat(key)));
                                        }) }, void 0)] }), void 0) }, void 0) : _jsx(_Fragment, { children: _jsx("p", __assign({ className: "p-5 text-center m-3" }, { children: "G\u00F6sterilecek bir kay\u0131t yok!" }), void 0) }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ id: "loadingAnimation", className: "text-center py-5" }, { children: _jsxs("div", __assign({ className: "lds-roller", style: { position: 'unset' } }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }), void 0), _jsx(Modal, __assign({ isOpen: shownDraw !== null ? true : false, onRequestClose: closeModal, style: {
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        maxWidth: 'calc(100vw - 40px)',
                        width: '920px',
                        padding: '0px',
                        maxHeight: 'calc(100vh - 100px)',
                        transform: 'translate(-50%, -50%)',
                        background: 'var(--color-bg-white)',
                    },
                }, contentLabel: "" }, { children: shownDraw ? _jsxs("div", __assign({ className: "card coupon lotteryTickets" }, { children: [_jsxs("div", __assign({ className: "card-title p-2" }, { children: [_jsx("h3", __assign({ className: "m-0" }, { children: "Biletlerim" }), void 0), _jsx("div", __assign({ className: "float-right" }, { children: _jsx("img", { src: ExitIcon, width: 24, className: "float-right cursor-p invert-color", onClick: function () { return closeModal(); } }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "card-body p-2 p-sm-0" }, { children: [shownDraw.draw ? _jsx("h4", __assign({ className: "px-sm-2" }, { children: shownDraw.draw.name }), void 0) : null, _jsx("p", __assign({ className: "px-sm-2" }, { children: "\u00C7ekili\u015Fe ait sat\u0131n ald\u0131\u011F\u0131n\u0131z biletleriniz a\u015Fa\u011F\u0131da listelenmi\u015Ftir." }), void 0), _jsxs("table", __assign({ className: "table scroll-able-tbody" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Bilet No" }, void 0), _jsx("th", __assign({ className: "hidden-sm" }, { children: "Sat\u0131nalma Tarihi" }), void 0), _jsx("th", { children: "Bilet Fiyat\u0131" }, void 0), _jsx("th", { children: "Durumu" }, void 0)] }, void 0) }, void 0), _jsx("tbody", { children: _.orderBy(shownDraw.tickets, ['status']).map(function (ticket, key) {
                                                return (_jsxs("tr", __assign({ className: ticket.status === 2 ? 'won' : (ticket.status === 3 ? 'lose' : '') }, { children: [_jsxs("td", __assign({ className: "text-left" }, { children: [_jsx("b", { children: ticket.approve_code }, void 0), _jsx("span", __assign({ className: "fs-small visible-sm" }, { children: dateTimeFormat(ticket.approve_date) }), void 0)] }), void 0), _jsx("td", __assign({ className: "hidden-sm" }, { children: dateTimeFormat(ticket.approve_date) }), void 0), _jsxs("td", { children: [Number(ticket.amount).toFixed(2), "\u20BA"] }, void 0), _jsxs("td", __assign({ className: "visible-sm fs-small" }, { children: [couponStatus[ticket.status], _jsx("span", __assign({ className: "d-block", style: { marginLeft: 5 } }, { children: states[ticket.status] }), void 0)] }), void 0), _jsxs("td", __assign({ className: "hidden-sm" }, { children: [couponStatus[ticket.status], _jsx("span", __assign({ style: { marginLeft: 5 } }, { children: states[ticket.status] }), void 0)] }), void 0)] }), "ticketList_".concat(key)));
                                            }) }, void 0)] }), void 0)] }), void 0)] }), void 0) : _jsx(_Fragment, {}, void 0) }), void 0)] }, void 0));
}
